


















































import Vue from 'vue';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { isMobile, processResponseError } from '@/common/misc';
import { FeedbackRequest } from '@/common/types/support.type';
import { mapGetters } from 'vuex';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { phoneNumberRegex } from '@/common/misc'

const MODAL_WIDTH = 475


export default Vue.extend({
    name: 'feedbackModal',
    mixins: [MiscMixin],
    data(){
        const defaultFeedbackForm = {
            name: '',
            phoneNumber: '',
            message: '',
        } as FeedbackRequest;
        return {
            defaultFeedbackForm,
            feedbackForm: Object.assign({}, defaultFeedbackForm),
            modalWidth: MODAL_WIDTH,
            isFetching: false,
            isFetchComplete: false,
            windowHeight: window.innerHeight,
        };
    },
    watch: {
        isAuthChecked(checked:boolean){
            if(checked) this.fillFormUsername();
        },
    },
    mounted(){
        this.fillFormUsername();
    },
    created() {
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth - 20 : MODAL_WIDTH
        window.addEventListener('resize', this.updateWindowHeight);
    },
    destroyed(){
        window.removeEventListener('resize', this.updateWindowHeight);
    },
    methods: {
        updateWindowHeight():void{
            this.windowHeight = window.innerHeight;
        },
        fillFormUsername(){
            const name = this.isAuthenticated ? this.user.username : '';
            this.$set( this.feedbackForm, 'name', name );
        },
        /**
         * Отправляет на сервер данные из формы обращения в поддержку. 
         * Уведомляет пользователя при появлении ошибки во время обработки запроса.
         */
        async submitFeedbackForm(){
            this.isFetching = true;
            let isComplete = false;
            try {
                await ApiService.post(endpoints.postFeedbackRequest,this.feedbackForm);
                isComplete = true;
            } catch (e) {
                alert('При обработке запроса произошла ошибка '+processResponseError(e));
            } finally { this.isFetching = false; this.resetFormData(); }
            this.isFetchComplete = isComplete;
        },
        /**
         * Сбрасывает данные, указанные при заполнении формы обращения в поддержку.
         */
        resetFormData(){
            this.feedbackForm = Object.assign({},this.defaultFeedbackForm);
        },
        beforeOpen(data:{params:Record<string,any>,[x:string]:any}){
            if(!data.params||!data.params.reason) return;
            this.feedbackForm.reason = data.params.reason;
        }
    },
    computed: {
        centerDescription(this:any):boolean{
            return window.innerHeight < 500;
        },
        modalHeight():number{
            return isMobile() ? 528 : Math.min( 700, window.innerHeight - 20 )
        },
        phoneNumberRegex: () => phoneNumberRegex,
        ...mapGetters(['isAuthenticated','user','isAuthChecked']),
    }
})
