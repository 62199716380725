














































































import Vue from 'vue';
// import ProductTab from '@/components/constructor-product-tab/ProductTab.vue';
// import ChannelTab from '@/components/constructor-channel-tab/ChannelTab.vue';
// import BudgetTab from '@/components/constructor-budget-tab/BudgetTab.vue';
// import ProductInfo from '@/components/constructor-product-tab/ProductInfo.vue';
// import ProjectInfoFields from '@/components/constructor-product-tab/ProjectInfoFields.vue';
// import ModalEditProjectInfo from '@/components/ModalEditProjectInfo.vue';
import ActiveProjectBar from '@/components/constructor-product-tab/ActiveProjectBar.vue';
import NavHeader from '@/components/elements/NavHeader.vue';
import TabViewMixin from '@/components/mixins/TabViewMixin.vue';
import NewUserConstructorMessage, { showedNewUserConstructorMessage } from '@/components/elements/storage-messages/NewUserConstructorMessage.vue';
import { NAV_DESTINATION_CLICK, SET_LOADING_PROJECT } from '@/store/mutations';
import { ChannelModel, LoadProjectDTO, ProductModel, ProjectDataModel, ProjectModel } from '@/common/classes/models';
import { processResponseError, putToClipboard, getLinkToProject, createClickAnchor, jsonCycler, isMobile, } from '@/common/misc';
import { mapGetters } from 'vuex';
import '@/assets/css/constructor.less';
import NavigationDestination from '@/common/classes/nav.destination';
import StorageService from '@/common/storage.service';
import UploadImageMixin from '@/components/mixins/UploadImageMixin.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ProjectSwitches from '@/components/elements/ProjectSwitches.vue';
import { LOAD_PROJECT } from '@/store/actions';
import ProjectImageEditorModal from '@/components/constructor-product-tab/ProjectImageEditorModal.vue';
import BookLandingBanner from '@/components/elements/BookLandingBanner.vue';
import { DISPLAY_BOOK_LANDING_BANNERS } from '@/common/config';
// import DropdownMenu from '@/components/elements/DropdownMenu.vue';

// Not a great solution, check out for alternatives (?update in interval/track height) https://stackoverflow.com/a/61284888
// lib, supported on most browsers - http://marcj.github.io/css-element-queries/
// or figure out a way to set as constructor-tab child
const constructorHeightMixin = Vue.extend({
  name: 'constructorHeightMixin',
  data(){
    return{
      constructorContainerHeight: 0,
    }
  },
  mounted(this:any){ 
    if(!this.productAndChannels) console.warn('productAndChannels object is undefined, save bar height might be broken');
    this.updateCCHeight(); 
    setTimeout(this.updateCCHeight, 250);
  },
  methods: {
    async updateCCHeight(){
      await Vue.nextTick();
      this.constructorContainerHeight = this.compConstructorContainerHeight;
    },
  },
  watch: {
    currentTab(){ 
      //TODO: Disable showInYears in budget on switch
      // if(tab == this.tabs.)
      this.updateCCHeight(); 
    },
    productAndChannels:{ handler(){ this.updateCCHeight(); }, deep: true },
  },
  computed: {
    constructorContainerEl():HTMLElement{
      return this.$refs['constructor-container'] as HTMLElement;
    },
    compConstructorContainerHeight: {
      get():number{ const el = this.constructorContainerEl; return el != undefined ? el.offsetHeight : 0; },
      cache: false,
    },
  }
});

// const SAVE_LOCAL_PROJECT_SEC = 5;

const component = Vue.extend({
  name: 'unitEconomyConstructor',
  mixins: [
    TabViewMixin,
    constructorHeightMixin,
    UploadImageMixin,
    MiscMixin,
  ],
  components: {
    ProductTab: () => import('@/components/constructor-product-tab/ProductTab.vue'),
    ChannelTab: () => import('@/components/constructor-channel-tab/ChannelTab.vue'),
    BudgetTab: () => import('@/components/constructor-budget-tab/BudgetTab.vue'),
    NavHeader,
    ModalEditProjectInfo: () => import('@/components/ModalEditProjectInfo.vue'),
    ProductInfo: () => import('@/components/constructor-product-tab/ProductInfo.vue'),
    ProjectInfoFields: () => import('@/components/constructor-product-tab/ProjectInfoFields.vue'),
    ActiveProjectBar,
    NewUserConstructorMessage,
    ProjectSwitches,
    ProjectImageEditorModal,
    BookLandingBanner,
  },
  data(){
    const tabs = Object.freeze({product:0,channel:1,budget:2});
    return {
      tabs,
      currentTab: tabs.product,
      unsavedChanges: false,
      debugUseNavHeader: true,
      showSharedMessage: false,
      //#region Mobile-only orientation related variables
      displayDeviceOrientationMessage: false,
      isLandscapeOrientation: false,
      checkOrientationInterval: undefined as undefined | number,
      //#endregion
      unauthWarnState: 'init' as ('init'|'waitChange'|'showed'),
    };
  },
  async mounted(){
    this.$store.commit(NAV_DESTINATION_CLICK,this.navDestinations.find(({route}:{route:string})=>route=='/create'));
    await this.processUrlQuery();
    const showWizard = this.$route.query.wizard == '1';

    if(showWizard) (this as any).$refs.activeProjectBar.showWizardOverlay = true;
  },
  beforeMount(){
    document.documentElement.classList.add('force-landscape');
    window.addEventListener('orientationchange', this.checkDeviceOrientation)
    window.addEventListener('resize', this.checkDeviceOrientation)
    this.checkDeviceOrientation();
  },
  beforeDestroy(){
    document.documentElement.classList.remove('force-landscape');
    window.removeEventListener('orientationchange', this.checkDeviceOrientation)
    window.removeEventListener('resize', this.checkDeviceOrientation)
  },
  watch: {
    projectData:{
      handler():void{ 
        // this.setUnsavedChangesAfterProjectLoad();
        this.unsavedChanges = this.isAuthenticated;
      },
      deep: true
    },
    //TODO: Figure out why new proj has unsavedchanges
    productAndChannels: {
      handler(newVal):void{ 
        this.unsavedChanges = this.isAuthenticated;

        if(!this.isAuthenticated && !showedNewUserConstructorMessage())
          switch (this.unauthWarnState){
            case 'init': this.unauthWarnState = 'waitChange'; break;
            case 'waitChange':
              if(this.$modal) 
                this.$modal.show('dialog',{
                  title: 'Пользователь не авторизован',
                  text: 'Для сохранения необходимо войти в учетную запись, <b>этот проект будет потерян</b>',
                  buttons: [{title:'ОК',handler:()=>this.$modal.hide('dialog')}]
                });
              this.unauthWarnState = 'showed';
              break;
            case 'showed': break;
          }
      },
      deep: true
    },
    isAuthenticated(isAuthenticated){ this.unsavedChanges = isAuthenticated; },
    unsavedChanges(newVal){
      window.onbeforeunload = newVal ? (e:any) =>{ 
        const msg = "У вас есть несохраненные изменения. Вы уверены, что хотите продолжить?";
        e.returnValue = msg;
        return msg;
      }: null;
    },
    activeSubDestination(newVal:NavigationDestination):void{
      const activeDest:NavigationDestination = this.activeNavDestination;
      const subDestIndex = activeDest.subDestinations.indexOf(newVal);
      this.currentTab = Object.values( this.tabs )[subDestIndex];
    },
  },
  methods: {
    reloadProject():void {
      (this as any).$router.push('/create_redirect');
    },
    async checkDeviceOrientation(){
      let displayPortraitMsg = this.displayDeviceOrientationMessage;
      if (window.matchMedia("(orientation: portrait)").matches) {
        displayPortraitMsg = true;
        this.isLandscapeOrientation = false;
        if ((this as any).isIOS) this.checkOrientationInterval = setInterval(this.checkDeviceOrientation,500) as any
      }
      if (window.matchMedia("(orientation: landscape)").matches) {
        displayPortraitMsg = false;
        this.isLandscapeOrientation = true;
        if (this.checkOrientationInterval) clearInterval(this.checkOrientationInterval)
      }

      if(!isMobile() || window.innerWidth > 560) displayPortraitMsg = false;
      if(displayPortraitMsg || !(this as any).isIOS)
        this.displayDeviceOrientationMessage = displayPortraitMsg;
    },
    async loadProject(projectDTO:LoadProjectDTO){
      if(projectDTO.isEmpty()) throw new Error('Unable to load the project, both id and invitation code was not specified.');
      this.$store.commit(SET_LOADING_PROJECT,true);
      await this.$store.dispatch(LOAD_PROJECT,projectDTO);
      await Vue.nextTick();
      this.$store.commit(SET_LOADING_PROJECT,false);
      await Vue.nextTick();
      const {tab} = projectDTO;
      if(tab == 'channels') this.currentTab = this.tabs.channel;
      if(tab == 'budget') this.currentTab = this.tabs.budget;

      //Probably should move this to a generic method or find a better solution. Waits for a component to load
      const produtctInfoPromise:Promise<any> = new Promise((resolve)=>{
        const getProductInfo = () => this.$refs.productInfo;
        const checkProductInfo = () => {
          setTimeout(() => { 
            const prodInfo = getProductInfo();
            if(prodInfo) 
              resolve(prodInfo);
            else 
              checkProductInfo(); 
          }, 50);
        };
        checkProductInfo();
      });
      
      produtctInfoPromise.then((productInfo) => productInfo.afterLoad(projectDTO) )
    },
    async processUrlQuery(){
      const {load, load_protected, create_new, tab: queryTab, chart: queryChart} = this.$route.query;
      const tab = queryTab as 'product'|'channels'|'budget'|undefined;
      const chart = parseInt( queryChart+'' );
      const pathSplit = this.$route.path.split("?");
      if(pathSplit.length > 0) history.pushState({},'',pathSplit[0]);
      const isCreateNew = create_new && typeof create_new == 'string' && create_new.length > 0;
      const isIdValid = load && typeof load == 'string' && load.length > 0;
      const isInvitationCodeValid = load_protected && typeof load_protected == 'string' && load_protected.length > 0;
      if(isIdValid || isInvitationCodeValid){
        try{
          let loadProjectDto:LoadProjectDTO|undefined;
          if(isIdValid) loadProjectDto = new LoadProjectDTO({id:load+'', tab, chart});
          else if(isInvitationCodeValid) loadProjectDto = new LoadProjectDTO({invitationCode:load_protected+'', tab, chart});
          if(loadProjectDto) await this.loadProject(loadProjectDto);
        }
        catch(e){
          console.error(e);
          this.loadDefaultProject(false);
          this.$modal.show('dialog',{
            title: 'При загрузке проекта произошла ошибка',
            width: '450px',
            text: processResponseError(e),
            buttons: [{title: 'OK', handler: () => this.$modal.hide('dialog')}]
          });
        }
      }
      else{ 
        const lastLocalProjectLiteral = this.restoreLastLocalProject ? StorageService.getStorageItems().LAST_LOCAL_PROJECT.getData() : undefined;
        if(lastLocalProjectLiteral && !isCreateNew){
          lastLocalProjectLiteral.isLastLocal = true;
          const {tab:localTab, chart:localChart} = lastLocalProjectLiteral.loadQuery || {};
          this.loadProject(new LoadProjectDTO({project:lastLocalProjectLiteral, tab: localTab, chart: localChart}));
        }else 
          this.loadDefaultProject();
      }
      setTimeout(this.setUnsavedChangesAfterProjectLoad,250);
    },
    async promptProjectReset(){
      try{
        await this.getBeforeQuitPromise();
        this.$modal.hide('dialog');
      }catch(e){ return; }
      // this.loadDefaultProject();
    },
    async copyProject(){ 
      try{
        // await this.getBeforeQuitPromise();
        const warningModalPromise = new Promise((resolve,reject)=>{
          const hideDialog = () => this.$modal.hide('dialog');
          const warningModalDialogParams = {
            title: 'Вы уверены, что хотите создать копию проекта?',
            text: 'Все несохраненные изменения будут перенесены в копию проекта',
            buttons: [
              {title: 'Продолжить',handler(){ hideDialog(); resolve(); }},
              {title: 'Отмена',handler(){ hideDialog(); reject(); }}],
          };
          this.$modal.show('dialog',warningModalDialogParams);
        }) as Promise<void>;
        await warningModalPromise;
      }catch(e){ return; }

      ProjectModel.deleteIds(this.projectData,this.channels);
      //TODO: UNIQUE NAME, GET LIST OF OTHER PROJECTS
      //TODO: Add 'project without name' default name (2)
      //   if (!this.projectData.name) this.projectData.name = "";
      this.projectData.name += " (Копия)"; 

      this.showProjectDialog();
    },
    async loadDefaultProject(showProjectModal=true){
      ProjectModel.loadDefaultProject();
      
      if(this.isAuthenticated && showProjectModal){ 
        await Vue.nextTick();
        this.showProjectDialog();
      }
      this.unsavedChanges = this.isAuthenticated;
    },
    async shareProject(){ 
      const url = getLinkToProject(this.projectData);
      const resetFocus = () => (this.$refs.unitEconomyContainer as HTMLElement).focus();
      if(!url) {
        if(!this.projectData.id) {
          this.$modal.show('dialog', {
            title: 'Невозможно поделиться проектом',
            text: 'Вы не можете поделиться проектом, так как он не был сохранён',
            buttons: [
              { title: 'ОК', handler: () => { this.$modal.hide('dialog'); } },
            ],
          });
          resetFocus(); 
          return;
        }
        if(!this.projectData.isPublished && !this.projectData.invitationCode) {
          this.$modal.show('dialog', {
            title: 'Невозможно поделиться проектом',
            text: 'Вы не можете поделиться проектом с видимостью "Приватный"',
            buttons: [
              { title: 'Изменить видимость', handler: () => { this.$modal.hide('dialog'); this.showProjectDialog(); } },
              { title: 'ОК', handler: () => { this.$modal.hide('dialog'); } },
            ],
          })
          return;
        }
        alert('При создании ссылки на проект произошла ошибка');
        resetFocus(); 
        return;
      }
      try {
        await putToClipboard(url);
        if(this._isMobile) return;
        this.showSharedMessage = true;
        await new Promise((r)=>setTimeout(r,2500));
        this.showSharedMessage = false;
      } catch (error) {
        resetFocus();
        console.error(error+'');
        console.error(error);
        alert('При добавлении значения '+url+' в буфер обмена произошла ошибка.');
      }
    },
    saveProject(data:any){ 
      (this.$refs.productTab as any).saveProject(data); 
    },
    loadLastSavedProject(){
      const projectData = StorageService.loadLastProjectData();
      if(projectData){
        const linkToProject = getLinkToProject(projectData);
        if(linkToProject) createClickAnchor( linkToProject );
      }
    },
    showProjectDialog(){
      // if((this as any).isMobile) return;
      this.$modal.show('edit-project-info');
      this.$modal.hide('edit-project-info');
    },
    //TODO: Refactor
    setUnsavedChangesAfterProjectLoad(){
      if(!this.isAuthenticated) this.unsavedChanges = false;
      else if(this.projectData && ProjectDataModel.projectSaveTypeFor( this.projectData ) == 'loaded-project') this.unsavedChanges = true;
      else if(this.projectData && this.projectData.isLastLocal){ this.unsavedChanges = true; this.projectData.isLastLocal = undefined; }
      else this.unsavedChanges = false;
      // else if(this.projectData && this.projectData.orig && this.projectData.orig.author) this.unsavedChanges = this.projectData.orig.author.id != this.user.id;
      // else this.unsavedChanges = false;
    },
    getBeforeQuitPromise():Promise<void>{
      return new Promise((resolve,reject)=>{
        resolve();
      })
    }
  },
  computed: {
    _isMobile():boolean{ return (this as any).isMobile },
    showProductTab():boolean{ return this.currentTab == this.tabs.product; },
    showChannelTab():boolean{ return this.currentTab == this.tabs.channel; },
    showBudgetTab():boolean{ return this.currentTab == this.tabs.budget; },
    showBookLandingBanner():boolean{ return DISPLAY_BOOK_LANDING_BANNERS; },
    productAndChannels: {
      get():{product:ProductModel,channels:ChannelModel[]} {
        const product = Object.assign({},this.product);
        const channels = (this.channels as ChannelModel[]).map((chan,i)=>{
          const c = Object.assign({},chan);
          if(i == this.channels.length - 1){ 
            delete (c as any).targetFraction;
            delete (c as any).targetMonthlySales;
          }
          return c;
        });
        return { product, channels };
      },
      cache: false
    },
    ...mapGetters(['product','channels','projectData','isAuthenticated','user','activeNavDestination','activeSubDestination','navDestinations','restoreLastLocalProject'])
  }
});

//TODO: Catch render errors, prevent invalid proj crashing UI
export default component;
