import Vue from 'vue'
import Vuex from 'vuex';

import VueTippy, {tippy} from "vue-tippy";
import VModal from 'vue-js-modal';
import VueDragscroll from 'vue-dragscroll';

import router from '@/router';

import store from '@/store';
import {AFTER_ROUTE, BEFORE_ROUTE} from '@/store/mutations';

import {productionTip} from '@/common/config';
import ApiService from '@/common/api.service';

import App from '@/App.vue'
// import NavHeader from '@/components/elements/NavHeader.vue';
import SmartNumberInput from '@/components/elements/SmartNumberInput.vue';
import SmartInput from '@/components/elements/SmartNumberInput.vue';
import UserProfilePicture from '@/components/elements/UserProfilePicture.vue';

import { beforeEachApplyMeta } from '@/common/misc';
import equal from 'deep-equal';
import { VNode } from 'vue/types/umd';

Vue.use(Vuex);
Vue.component('nav-header', () => import('@/components/elements/NavHeader.vue'));
Vue.component('smart-number-input', SmartNumberInput);
Vue.component('smart-input', SmartInput);
Vue.component('user-profile-picture', UserProfilePicture);
Vue.component('with-root', {
  functional: true,
	props: {
		showIf: Boolean,
	},
	render(h, context) {
		const { children, props } = context

		if (props.showIf) {
			return children
		} else {
      const vnodes = children
        .map((child) => {
          if (child.children) return child.children
          return child.componentOptions != null ? child.componentOptions.children : null
        })
        .filter((list) => !!list) // remove any null and void
			return (vnodes as any) as VNode[];
		}
	},
})

//#region vue yandex metrika
//Does not make any difference, redirects are still not tracked
// import VueYandexMetrika from 'vue-yandex-metrika';

// Vue.use(VueYandexMetrika, {
//   id: 66965077,
//   router,
//   options: {
//     clickmap:true,
//     trackLinks:true,
//     accurateTrackBounce:true,
//     webvisor:true,
//     ecommerce:"dataLayer"
//   }
// });
//#endregion

// import VTooltip from 'v-tooltip';
// Vue.use(VTooltip,{defaultDelay: 200,disposeTimeout: 0,autoHide:false, defaultHtml: false});

Vue.config.productionTip = productionTip

Vue.use(VueDragscroll);
Vue.use(VModal,{dialog: true});

const defaultTippyOptions = Object.freeze( {
  directive: "tippy",
  flipDuration: 0,
  allowHtml: false,
  duration: [0, 400],
  delay: [200, null],
  theme: 'light-border',
  interactive: true,
  onShown(instance:any) {
    const tooltipDisplayedEvent = new CustomEvent('tooltip-displayed', {detail: instance});
    window.dispatchEvent(tooltipDisplayedEvent);
  },
  popperOptions: {
    modifiers: {
      hide: { enabled: false },
      preventOverflow: {
        enabled: true
      }
    }
  }
} );

function getTippyPlacement(modifiers:{[key: string]: boolean;}):string|undefined{
  for (const key in modifiers) 
    if (Object.prototype.hasOwnProperty.call(modifiers, key) && modifiers[key]) return key;
}

let objTooltips:any[] = [];

Vue.directive('tooltip', {
  bind(el, binding){
    const {value, modifiers} = binding;
    
    const content = typeof value == 'object' ? value.content : value;
    // console.log({content, value})
    if(typeof value == 'object'){ 
      //TODO: Check performance
      objTooltips = objTooltips.filter((o:any) => !equal( o, value ) );
      value.el = el;
      objTooltips.push(value); 
      // console.log([...objTooltips])
    }

    if(!value || !content || (typeof value != 'string' && typeof value != 'object')) return;

    const options = {
      content,
      ...defaultTippyOptions,
    };
    
    const placement = getTippyPlacement(modifiers);
    if(placement) (options as Record<string,any>).placement = placement;

    tippy(el, options);
  },
})

Vue.use(VueTippy, {...defaultTippyOptions});

router.afterEach((to,from) => store.commit(AFTER_ROUTE,{to,from}) );
router.beforeEach((to,from,guard) => {
  store.commit(BEFORE_ROUTE,{to,from}); 
  beforeEachApplyMeta(to, from)
  guard(); 
} );

ApiService.init();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
