
import NavigationDestination from '@/common/classes/nav.destination';
import { destinationByComponent } from '@/common/misc';
import { NAV_DESTINATION_CLICK } from '@/store/mutations';
import Vue from 'vue'
import { ExtendedVue } from 'vue/types/vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'tabViewMixin',
    methods: {
        onViewMount(component?:ExtendedVue<Vue,any,any,any,any>,navDestination?:NavigationDestination):void{
            let destination = component ? destinationByComponent(component,this.navDestinations) : navDestination;
            if(!destination){ 
                console.warn({
                    msg:'Cannot find destination for component or it was not specified',
                    component,
                    argDestination:navDestination,
                    navDestinations:this.navDestinations,
                });
                destination = this.navDestinations[0];
            }
            // console.log({msg:'click on destination',destination});
            this.$store.commit(NAV_DESTINATION_CLICK,destination);
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated','navDestinations','activeNavDestination'])
    }
})
