













































import Vue from 'vue';
import {endpoints} from '@/common/config';
import { mapGetters } from 'vuex';
import { CHECK_AUTH } from '@/store/actions';
import ApiService from '@/common/api.service';
import {userHasRole,enumNameByIndex, processResponseError} from '@/common/misc';

// import MainTab from '@/components/admin/MainTab.vue';

// import AdminResourcesTab from '@/components/admin/AdminResourcesTab.vue';

// import AdminProjectsTab from '@/components/admin/AdminProjectsTab.vue';
import FeedbackRequestTab from '@/components/admin/FeedbackRequestTab.vue';
import AdminResourceSuggestionTab from '@/components/admin/AdminResourceSuggestionTab.vue';
import ReportsTab from '@/components/admin/ReportsTab.vue';
import AdminTooltipsTab from '@/components/admin/AdminTooltipsTab.vue';
import AdminPromocodeTab from '@/components/admin/AdminPromocodeTab.vue';
import AdminDocsTab from '@/components/admin/AdminDocsTab.vue';

const states = Object.freeze({
    CHECKING_AUTH: 0,
    MAIN_PAGE: 1,
    RESOURCES: 2,
    PROJECTS: 3,
    TOOLTIPS: 4,
    RESOURCES_SUGGESTIONS: 5,
    REPORTS: 6,
    FEEDBACK_REQUEST: 7,
    PROMOCODES: 8,
    DOCS: 9,
    SURVEY: 10,
})
const hideStates = [states.CHECKING_AUTH];

export default Vue.extend({
    name: 'adminPanel',
    components: {
        AdminResourcesTab: () => import('@/components/admin/AdminResourcesTab.vue'),
        AdminProjectsTab: () => import('@/components/admin/AdminProjectsTab.vue'),
        MainTab: () => import('@/components/admin/MainTab.vue'),
        ReportsTab,
        FeedbackRequestTab,
        AdminResourceSuggestionTab,
        AdminTooltipsTab,
        AdminPromocodeTab,
        AdminDocsTab,
        AdminSurveyTab: () => import('@/components/admin/AdminSurveyTab.vue'),
    },
    data(){
        return{
            states,
            state: states.CHECKING_AUTH,
            panelData: undefined,
        }
    },
    async mounted(){
        try{
            const {CHECKING_AUTH,MAIN_PAGE} = this.states;
            this.switchStateTo(CHECKING_AUTH);
            await this.$store.dispatch(CHECK_AUTH);

            const url = new URL(location.href);
            const urlState = parseInt(url.searchParams.get("state")+'');
            let switchState = MAIN_PAGE;
            if(urlState&&Object.values(states).includes(urlState)&&!hideStates.includes(urlState))
                switchState = urlState;
            this.switchStateTo(switchState);
            
            const {data} = await ApiService.get(endpoints.adminPanel);
            this.panelData = data;
        }catch(e){
            this.$el.innerHTML = `<h3>Произошла ошибка ${processResponseError(e)}</h3>`;
        }
    },
    methods: {
        enumNameByIndex,
        switchStateTo(state:number){
            const rawPath = '/admin?state='+state;
            const curPath = this.$route.fullPath;
            if(!hideStates.includes(state)&&curPath!=rawPath)
                this.$router.replace(rawPath);
            this.state=state;
        }
    },
    computed: {
        isAdmin():boolean{
            return this.isAuthenticated && userHasRole(this.user,'ROLE_ADMIN');
        },
        //#region state properties
        currentStateName():string{
            return enumNameByIndex(this.state,this.states)+'';
        },
        isCheckingAuthState():boolean{ return this.state == this.states.CHECKING_AUTH },
        isMainPageState():boolean{ return this.state == this.states.MAIN_PAGE },
        isResourceState():boolean{ return this.state == this.states.RESOURCES },
        isProjectState():boolean{ return this.state == this.states.PROJECTS },
        isTooltipsState():boolean{ return this.state == this.states.TOOLTIPS },
        isResourceSuggestionState():boolean{ return this.state == this.states.RESOURCES_SUGGESTIONS },
        isReportState():boolean{ return this.state == this.states.REPORTS },
        isFeedbackRequestState():boolean{ return this.state == this.states.FEEDBACK_REQUEST },
        isPromocodeState():boolean{ return this.state == this.states.PROMOCODES; },
        isDocsState():boolean{ return this.state == this.states.DOCS },
        isSurveyState():boolean{ return this.state == this.states.SURVEY },
        //#endregion
        ...mapGetters(['isAuthenticated','user'])
    }
})
