




import Vue from 'vue'
export default Vue.extend({
    name: 'hidePasswordBtn',
    props: {
        modelData:{}
    },
})
