import { ProjectDataModel, ProjectModel } from '@/common/classes/models';
import { StorageTooltipData } from '@/common/tooltips';
import { StorageBooleanItem, BasicStorageItem, StorageProjectItem, GenericObjectStorage, StorageStringItem } from '@/common/storage.items';


const StorageService = {
    getStorageItems(){
        const dataItems = {
            LAST_LOAD_PROJECT_DATA: new BasicStorageItem(),
            LAST_LOCAL_PROJECT: new StorageProjectItem(),
            REACHED_EMAIL_VERIFICATION_GOAL: new StorageBooleanItem(),
            TOOLTIPS_DATA: new GenericObjectStorage<StorageTooltipData>(),
            SETTINGS_DISABLE_TOOLTIPS: new StorageBooleanItem(),
            REGISTRATION_PROMO_CODE: new StorageStringItem(),
            SURVEY_JS_API_KEY: new StorageStringItem(),
            HIDE_OUTDATED_DEVICE_MESSAGE: new StorageBooleanItem(),
        };
        Object.keys(dataItems).forEach((k)=>{
            const v:BasicStorageItem = (dataItems as any)[k];
            v.key = k.split('_').join('-').toLowerCase();
        });
        return dataItems;
    },
    isAvalible():boolean{
        return typeof Storage !== 'undefined' && !!localStorage;
    },
    setItem(item:BasicStorageItem,value:string):void{
        localStorage.setItem(item.key,value);
    },
    getItem(item:BasicStorageItem):string|null{
        return localStorage.getItem(item.key);
    },
    saveLastLoadProjectData(project:ProjectModel):void{
        const item = this.getStorageItems().LAST_LOAD_PROJECT_DATA;
        const cloneData = Object.assign({},project.data);
        try{ 
            const value = JSON.stringify(cloneData);
            this.setItem(item,value) 
        }catch(ignored){/*Ignored*/}
    },
    loadLastProjectData():ProjectDataModel|null{
        const data = this.getItem(this.getStorageItems().LAST_LOAD_PROJECT_DATA);
        if(data === null) return null;
        try{ return JSON.parse( data ) as ProjectDataModel; }
        catch(ignored){ return null; }
    },
}

export default StorageService;