/*SOURCE: https://stackoverflow.com/a/29848855*/

export async function fileToImg(file:File):Promise<HTMLImageElement>{
    const img = new Image();
    img.src = URL.createObjectURL(file);
    await new Promise((res)=> img.onload = res );
    return img;
}

//https://stackoverflow.com/a/43358515
export function dataURLtoFile(dataurl:string, filename:string, mime:string) {
    const arr = dataurl.split(','), bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

export function rescaleImgCanvas(img:HTMLImageElement, maxWidth:number, maxHeight:number) {
    const imgWidth = img.width,
        imgHeight = img.height;

    let ratio = 1;

    // Use the smallest ratio that the image best fit into the maxWidth x maxHeight box.
    ratio = Math.min(maxWidth / imgWidth, maxHeight / imgHeight)
    const canvas = document.createElement("canvas");
    const canvasContext = canvas.getContext("2d") as CanvasRenderingContext2D;
    const canvasCopy = document.createElement("canvas");
    const copyContext = canvasCopy.getContext("2d") as CanvasRenderingContext2D;
    const canvasCopy2 = document.createElement("canvas");
    const copyContext2 = canvasCopy2.getContext("2d");
    canvasCopy.width = imgWidth;
    canvasCopy.height = imgHeight;
    copyContext.drawImage(img, 0, 0);

    // init
    canvasCopy2.width = imgWidth;
    canvasCopy2.height = imgHeight;
    (copyContext2 as any).drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvasCopy2.width, canvasCopy2.height);


    const rounds = 1;
    const roundRatio = ratio * rounds;
    for (let i = 1; i <= rounds; i++) {

        // tmp
        canvasCopy.width = imgWidth * roundRatio / i;
        canvasCopy.height = imgHeight * roundRatio / i;

        copyContext.drawImage(canvasCopy2, 0, 0, canvasCopy2.width, canvasCopy2.height, 0, 0, canvasCopy.width, canvasCopy.height);

        // copy back
        canvasCopy2.width = imgWidth * roundRatio / i;
        canvasCopy2.height = imgHeight * roundRatio / i;
        (copyContext2 as any).drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvasCopy2.width, canvasCopy2.height);

    } // end for

    canvas.width = imgWidth * roundRatio / rounds;
    canvas.height = imgHeight * roundRatio / rounds;
    canvasContext.drawImage(canvasCopy2, 0, 0, canvasCopy2.width, canvasCopy2.height, 0, 0, canvas.width, canvas.height);

    canvas.width = canvasCopy2.width;
    canvas.height = canvasCopy2.height;

    canvasContext.clearRect(0, 0, canvas.width, canvas.height);

    canvasContext.translate(canvasCopy2.width / 2, canvasCopy2.height / 2);
    canvasContext.drawImage(canvasCopy2, -canvasCopy2.width / 2, -canvasCopy2.height / 2);

    return canvas;
}