
















import Vue from 'vue';
import ApiService from '@/common/api.service'
import { endpoints } from '@/common/config';
import { createClickAnchor, processResponseError } from '@/common/misc';
import { AxiosResponse } from 'axios';

export default Vue.extend({
    name: 'promoCodeModal',
    data(){
        return {
            strMessage: '',
            promoCodeInput: '',
        }
    },
    methods: {
        //Not used
        async beforeOpen({params}:any):Promise<void>{
            if(!params) return;
            const {promocode} = params;
            if(promocode){
                this.strMessage = 'Похоже, что вы указали промокод при регистрации. Идёт активация..';
                this.promoCodeInput = promocode;
                await new Promise((r)=>setTimeout(r,4000));
                this.activatePromoCode();
            }
        },
        hideModal(){
            this.$modal.hide('promo-code-modal');
        },
        async activatePromoCode():Promise<void>{
            const promocode = this.promoCodeInput;
            if(!promocode) {
                this.strMessage = "Поле 'промокод' не заполнено"
                return;
            }
            let activatePromoCodeFetch:AxiosResponse<any>;
            try {
                activatePromoCodeFetch = await ApiService.post(endpoints.postActivatePromoCode(promocode));
                // console.log(activatePromoCodeFetch.data);
                this.strMessage = "Промокод успешно активирован, идёт перенаправление..";
                await new Promise((r)=>setTimeout(r,4000));
                createClickAnchor('/settings?tab=3');
                // alert('Промокод активирован');
            } catch (error) {
                this.strMessage = processResponseError(error);
                await this.$nextTick();
                this.$forceUpdate();
            }
        },
    },
})
