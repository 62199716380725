
























import Vue from 'vue';
export default Vue.extend({
    name: 'dropdownMenu',
    props: {
        leftAlign: {type:Boolean,default:true},
        isWhite: {type:Boolean,default:true},
        disabled: {type:Boolean,default:false},
    },
    data(){
        return{
            showContent: false,
            debugIgnoreBlur: false,
        }
    },
    methods: {
        switchMenu():void{
            this.showContent = !this.showContent;
        },
        async hideMenuDelay(ms:number):Promise<void>{
            await new Promise((res)=>setTimeout(res,ms));
            if(!this.showContent) return;
            this.switchMenu();
        }
    },
    computed: {
        contentClass():string{
            return this.leftAlign ? 'left-align' : 'right-align';
        },
    }
})
