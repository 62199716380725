
import { checkIfElementsOverlap, isMobile } from '@/common/misc';
import Vue from 'vue'
export default Vue.extend({
    name: 'globalAppMixin',
    created() {
        if(isMobile()) window.addEventListener('tooltip-displayed', (e:any)=>{ 
            this.hideTooltipIfBlockingInput(e.detail.popper);
        });
    },
    methods: {
        hideTooltipIfBlockingInput(tooltipElement:HTMLElement):void {
            if(!tooltipElement || typeof tooltipElement.scrollHeight != 'number') {
                console.warn('hideTooltipIfBlockingInput is called, but provided tooltipElement is invalid');
                return;
            }
            // const tooltipHeight = tooltipElement.scrollHeight;
            const activeInput = (document.activeElement && ['INPUT','TEXTAREA'].includes(document.activeElement.tagName) ? 
                                    document.activeElement : undefined) as HTMLElement|undefined;
            console.log({activeInput, active: document.activeElement})
            //?Detect tooltip overlap by checking document.activeElement
            if(activeInput && checkIfElementsOverlap(tooltipElement, activeInput, {top: 10, bottom: 10, left: 20, right: 20})) {
                console.log('possible input overlap, hiding tooltip.');
                tooltipElement.style.visibility = 'hidden';
            } else if(!activeInput) console.log('Input or textarea is not active');
            else console.log('input and tooltip are not overlapping');
        },
    },
    computed: {
        tooltipElement: {
            get():HTMLElement|null {
                return document.getElementsByClassName('tippy-popper').item(0) as HTMLElement|null;
            },
            cache: false,
        },
    },
})
