








import Vue from 'vue';
import { getIndustries } from '@/common/config';
import SelectElMixin from '@/components/mixins/SelectElMixin.vue';
export default Vue.extend({
    name: 'industrySelect',
    mixins: [SelectElMixin],
    props: {
        flags: { type: Array||undefined },
        modelData: {},
    },
    data(){
        return {
            industries: getIndustries()
        }
    },
})
