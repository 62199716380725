





































import Vue from 'vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue'
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import { RouteConfig } from 'vue-router';
import { Route } from 'vue-router/types/router';
import { mapGetters } from 'vuex';
import { PURGE_AUTH } from '@/store/mutations';
import { socialMediaLinks } from '@/common/config';

export default Vue.extend({
    name: 'verticalNavbar',
    props: {
        currentTab:{type:Number},
        tabs:{type:Object},
        getBeforeQuitPromise:{type:Function}
    },
    mixins: [MiscMixin,ResponsiveDataMixin],
    methods: {
        showLogin():void{
            this.$router.push('/login')
        },
        logout():void{
            //TODO: Replace with redirect to /logout
            this.$store.commit(PURGE_AUTH);
        },
        showProfile():void{
            // if((this as any).isMobile) return;
            this.$router.push('/settings')
        },
        routeClick(route:RouteConfig):void{
            const proceed = () => this.$router.push(route.path);
            const path = route.meta.ignoreParams ? this.$route.path : this.$route.fullPath;
            
            if( path == route.path && path != '/create' ){
                return;
            }
            if( path == route.path && path == '/create' ) {
                const reset = () => location.reload();
                if((this.$parent as any).unsavedChanges) this.getBeforeQuitPromise().then(reset);
                else reset();
                return;
            }
            if(this.getBeforeQuitPromise == undefined) {
                proceed();
                return;
            }
            this.getBeforeQuitPromise().then(proceed);
        }
    },
    computed: {
        socialMediaLinks():Record<string,string>{
            return socialMediaLinks;
        },
        homeRoute():RouteConfig|undefined{
            return this.$router.options.routes?.find(({path})=>path=='/');
        },
        routes():RouteConfig[]{ 
            return this.$router.options.routes || []; 
        },
        ...mapGetters(['user','isAuthenticated'])
    }
})
