










import { createClickAnchor } from '@/common/misc';
import Vue from 'vue'
export default Vue.extend({
    name: 'bookLandingBanner',
    methods: {
        openBookLandingNewTab():void{
            createClickAnchor('/book', (a)=>a.target = '_blank');
        },
    },
})
