










































































































import Vue from 'vue';
// import ProjectBlock from '@/components/elements/ProjectBlock.vue';
import { mapGetters } from 'vuex';
import NavigationDestination from '@/common/classes/nav.destination';
import StorageService from '@/common/storage.service';
import YandexMetrikaSerivice from '@/common/yandex.metrika.service';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import { createClickAnchor, isMobile, whenAvailable } from '@/common/misc';
import { DISPLAY_BOOK_LANDING_BANNERS } from '@/common/config';
import { RenderData } from '@/store';

const component = Vue.extend({
    name: 'home',
    mounted(){
        this.showDialogFromParameters();
    },
    mixins: [ResponsiveDataMixin],
    data(){
        return {
            hideVideoPlaceholder: false,
        }
    },
    created(){
        this.setDisableMobileStickyHeaderOnOffset();
    },
    destroyed(){
        this.setDisableMobileStickyHeaderOnOffset();
    },
    methods: {
        setDisableMobileStickyHeaderOnOffset():void{
            const isMobile = (this as any).isMobileWidth;
            this.$set((this.renderData as RenderData), 'disableMobileStickyHeaderOnOffset', isMobile && DISPLAY_BOOK_LANDING_BANNERS);
        },
        async showVideo(){
            this.hideVideoPlaceholder = true;
            await Vue.nextTick();
            // const videoEl = this.$refs['home-video'] as HTMLVideoElement;
            // videoEl.volume = 0.35;
            // videoEl.play();
        },
        showDialogFromParameters():void{
            const {status} = this.$route.query;
            if(status!=null && typeof status == 'string' && status.length > 0){
                    console.log('checking status '+status)
                if (status === 'vk-login-redirect') {
                    console.log('status vk login redirect')
                    if (!isMobile()) window.close();
                }
                let title='';
                let text='';
                let loginBtn = false;
                const tryReachVerificationGoal = ()=> {
                    if(!StorageService.isAvalible()) return;
                    const item = StorageService.getStorageItems().REACHED_EMAIL_VERIFICATION_GOAL;
                    if(item.isTrue()) return;
                    whenAvailable('ym',()=>{
                        const goal = YandexMetrikaSerivice.getGoals().USER_MAIL_VERIFICATION;
                        YandexMetrikaSerivice.reachGoal(goal);
                        item.setTrue();
                    });
                    
                };
                const hideDialog = () => this.$modal.hide('dialog');
                const loginBtnHandler = () =>{hideDialog(); this.$router.push({path:'/login'});};
                switch(status){
                    case "success-verified":
                        title = '<h3>Добро пожаловать!</h3>';
                        text = 'Ваша почта была успешно подтверждена.<br><b>Теперь вы можете авторизоваться</b>';
                        loginBtn = true;
                        tryReachVerificationGoal();
                        break;
                    case "fail-already-verified":
                        title = 'Произошла ошибка!';
                        text = 'Почта уже подтверждена.';
                        break;
                    case "processing-payment":
                        title = 'Платёж обрабатывается';
                        //TODO: Add link to all payments
                        text = 'Вы можете узнать его статус в личном кабинете<br><div style="font-size: small;">список платежей обновляется каждые 15 секунд.</div>';
                        break;
                    default: return;
                }
                //!!Do not include any user-generated content to prevent XSS attacks
                this.$modal.show('dialog',{
                    title,
                    text,
                    buttons: [
                        loginBtn ? { title: 'Войти', handler: loginBtnHandler } : { title: 'ОК', handler: hideDialog }
                    ]
                })
                history.pushState({},'',this.$route.path.split("?")[0]);
            }
        },
        openBookLandingNewTab():void{
            createClickAnchor('/book', (a)=>a.target = '_blank');
        },
    },
    computed: {
        homeDestination():NavigationDestination{
            return this.navDestinations.find((dest:NavigationDestination)=>dest.route=='/');
        },
        showBookLandingBanner():boolean{ return DISPLAY_BOOK_LANDING_BANNERS; },
        ...mapGetters(['isAuthenticated','navDestinations','activeNavDestination','activeSubDestination','renderData'])
    }
});
export default component;
