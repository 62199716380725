

























































































import Vue from 'vue';
import { endpoints, fetchProjectPresetImages, isImageEditorInEditProjectModal } from '@/common/config';
import { mapGetters } from 'vuex';
import StorageService from '@/common/storage.service';
import { ProjectDataModel } from '@/common/classes/models';
import { mapObjectToUrlParams, parseDate } from '@/common/misc';
import ApiService from '@/common/api.service';
import TariffService from '@/common/tariff.service';
import WizardOverlay from '@/components/constructor-product-tab/WizardOverlay.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import DropdownMenu from '@/components/elements/DropdownMenu.vue';

export default Vue.extend({
    name: 'activeProjectBar',
    async mounted(){
        // this.checkProjectTariffLimit();  
        // TODO: Add 'project without name' default name
        // console.log(this.projectData.name)
        // if (!this.projectName) this.projectName = 'Проект без названия';
        // console.log(this.projectData.name)
        // this.$nextTick(()=>this.$forceUpdate());
        this.defaultProjectImage = (await fetchProjectPresetImages()).defaultProjectImage;
    },
    props: {
        unsavedChanges: {type:Boolean},
        constructorContainerHeight: {type:Number},
        currentTab: {type:Number},
        showSharedMessage: {type:Boolean,default: false},
    },
    mixins: [MiscMixin],
    components: {
        WizardOverlay,
        DropdownMenu,
    },
    data(){
        return{
            render: { mouseOverSave: false },
            defaultProjectImage: undefined,
            StorageService,
            isExceedingProjectTariffLimit: false,
            showWizardOverlay: false,
        }
    },
    watch: {
        userTariffTraits(){
            this.checkProjectTariffLimit();  
        },
        projectData: {
            handler(newData){
                if(newData?.id == undefined){
                    this.checkProjectTariffLimit();
                }
            },
            deep: true,
        },
    },
    methods: {
        async checkProjectTariffLimit(){
            if(this.userTariffTraits){
                const maxProjects = TariffService.getMaxProjectsForUser(this.user);
                const params = mapObjectToUrlParams( { onlyCurrentUser: 1, amount: 26, sort: 'descUpdated', onlyTotal: 1 } );
                try {
                    const {data} = (await ApiService.get(endpoints.getProjects+'?'+params));
                    const isExceedingLimit = data.total >= maxProjects;
                    const projectSaveType = ProjectDataModel.projectSaveTypeFor(this.projectData);
                    if(projectSaveType != 'user-project')
                        this.isExceedingProjectTariffLimit = isExceedingLimit;
                } catch (e) { console.error(e) }
            }
        },
        mobileUploadImage():void{
            throw new Error("Not implemented");
        },
        showProjectDialog(){
            this.$emit('show-project-dialog')
            // alert('showProjDialog')
        },
        copyProject(){
            this.$emit('copy-project')
            // alert('copy project')
        },
        shareProject(){
            this.$emit('share-project')
            // alert('share project')
        },
        saveProject(){
            this.$emit('save-project');
            // alert('save project')
        },
        loadLastSavedProject():void{
            this.$emit('load-last-saved-project');
        },
    },
    computed: {
        projectName: {
            get():string{
                // TODO: Add 'project without name' default name (2)
                // return this.projectData.name || '';
                return this.projectData.name || 'Проект без названия';
            },
            set(name:string):void{
                this.$set(this.projectData, 'name', name);
            },
            cache: false,
        },
        isImageEditorInEditProjectModal():boolean{
            return isImageEditorInEditProjectModal();
        },
        disableShareProjectBtn():boolean{
            return !this.projectData.id||(!this.projectData.isPublished && !this.projectData.invitationCode)
        },
        showOrigAuthor():boolean{
            if(!this.projectData || !this.projectData.orig || !this.projectData.orig.author) return false;
            if(this.isAuthenticated && this.projectData.orig.author.id != this.user.id) return !this.projectData.id;
            return false;
        },
        userTariffTraits():any[]|undefined{
            const hasTraits = this.isAuthenticated && 
                                this.user  && 
                                Array.isArray( this.user.tariffTraits ) && 
                                this.user.tariffTraits.length > 0;
            return hasTraits ? this.user.tariffTraits : undefined;
        },
        lastProjData():ProjectDataModel|null{
            return StorageService.loadLastProjectData();
        },
        shareBtnElement: {
            get():HTMLButtonElement{
                return this.$refs.shareBtn as HTMLButtonElement;
            },
            cache: false
        },
        formattedSaveDate: {
            get():string|undefined{ 
                if(this.projectData.updated||this.projectData.created)
                return parseDate(this.projectData.updated||this.projectData.created).toLocaleDateString("ru",{month:'numeric',day:'numeric',hour: 'numeric',minute:'numeric'}); 
                return undefined;
            },
            cache: false
        },
        saveBtnText():string{ 
            const baseText = this.isAuthenticated ? 'Сохранить' : 'НЕ АВТОРИЗОВАН'
            if(!this.render.mouseOverSave) return baseText;
            return this.formattedSaveDate||((this.$refs.productTab as any)?.isSavingProject?'Сохранение..':undefined)||baseText;
        },
        ...mapGetters(['isAuthenticated','user','projectData'])
    }
})
