
import Vue from 'vue'
export default Vue.extend({
    name: 'selectElMixin',
    props: {
        flags: { type: Array||undefined },
        modelData: {},
    },
    methods: {
        updateVal():void{
            const {modelData,selectedVal} = this;
            const {b,p} = modelData as any;
            this.$set(b,p,selectedVal);
        }
    },
    computed: {
        showAnyOption():boolean{
            return this.flags?.includes('any');
        },
        bindVal():string{
            const {b,p} = this.modelData as any;
            return b[p];
        },
        selectedVal: {
            get():(string|number){
                const {options,selectedIndex} = this.$el as HTMLSelectElement;
                const value = options[selectedIndex].value;
                const parsed = parseInt(value);
                return isNaN(parsed) ? value : parsed;
            },
            cache: false
        }
    }
})
