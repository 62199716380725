





























import Vue from 'vue';
import SelectElMixin from '@/components/mixins/SelectElMixin.vue';

export default Vue.extend({
    name: 'resourceFieldSelect',
    mixins: [SelectElMixin],
    props: {
        value: {},
        isNumber: {type:Boolean,default:false},
        field: {default: '' as ('type'|'cost'|'complexity'|'language')}
    },
    computed: {
        isFieldNameFeminine():boolean{
            return ['cost'].includes( this.field );
        }
    },
})
