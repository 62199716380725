
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import StorageService from '@/common/storage.service';
import { isMobile } from '@/common/misc';
export default Vue.extend({
    name: 'registrationPromoCodeMixin',
    mounted(){ this.checkPromoCode(); },
    watch: {
        $route():void{ this.checkPromoCode(); }
    },
    methods: {
        async checkPromoCode(){
            if(!this.isAuthenticated) return;

            const {REGISTRATION_PROMO_CODE} = StorageService.getStorageItems();
            const promoCode = REGISTRATION_PROMO_CODE.getValue();
            if(promoCode){
                // try { await ApiService.post(endpoints.postActivatePromoCode(promoCode)); } catch (error) {
                //     console.error(error);
                // }
                await new Promise((r)=>setTimeout(r,800));
                REGISTRATION_PROMO_CODE.setValue('');
                if(this.$route.path != '/settings')
                    this.$router.push(`/settings?action=activate-promocode&promocode=${promoCode}`);
            }
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated']),
    },
})
