














import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'socialResource',
    props: {
        totalLikes:{type:Number},
        totalViews:{type:Number},
        showFavorite:{type:Boolean,default:false},
        disableFavorite:{type:Boolean,default:false},
        isFavorite:{type:Boolean,default:false},
    },
    computed: {
        ...mapGetters(['isAuthenticated']),
    },
})
