






import Vue from 'vue';
import ResourceCollectionContents from '@/components/resources-tab/ResourceCollectionContents.vue';

const MODAL_WIDTH = 1250;
const MODAL_HEIGHT = 900;
// ResourceCollectionContents
export default Vue.extend({
    name: 'resourcesModal',
    components: {ResourceCollectionContents},
    data(){
        return {
            modalWidth: MODAL_WIDTH,
            modalHeight: MODAL_HEIGHT,
        }
    },
    mounted() {
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth - 100 : MODAL_WIDTH;
        this.modalHeight = window.innerHeight < MODAL_HEIGHT ? window.innerHeight - 100 : MODAL_HEIGHT;
    }
})
