















import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { onDestinationClick } from '@/store/nav-store';
import NavigationDestination from '@/common/classes/nav.destination';
import Vue from 'vue';
import VerticalNavbar from '@/components/VerticalNavbar.vue';
import { mapGetters } from 'vuex';
import { RenderData } from '@/store';

export default Vue.extend({
    name: 'Header',
    components: { VerticalNavbar },
    mixins: [MiscMixin],
    props: {
        getBeforeQuitPromise:{type:Function},
        label:{type:String},
        switchTabs:{type:Boolean,default: false},
        destination:{type:NavigationDestination},
    },
    data(){
        return {
            overrideDisableMobileStickyHeader: false,
            mountedRootHeaderOffset: 0,
        }
    },
    created(){
        window.addEventListener('scroll', this.updateStoreRootHeaderTopOffset);
    },
    destroyed(){
        window.removeEventListener('scroll', this.updateStoreRootHeaderTopOffset);
    },
    mounted(){
        setTimeout(()=>{ this.mountedRootHeaderOffset = (this.$refs.rootHeader as HTMLElement).offsetTop; }, 100)
        this.updateStoreRootHeaderTopOffset();
    },
    methods: {
        updateStoreRootHeaderTopOffset():void{
            const renderData = this.renderData as RenderData;
            const {disableMobileStickyHeaderOnOffset} = renderData;
            if(!disableMobileStickyHeaderOnOffset) return;
            
            let addDisableStickyHeaderClass = true;
            try {
                const headerOffset = this.mountedRootHeaderOffset;
                if(window.pageYOffset > headerOffset) {
                    addDisableStickyHeaderClass = false;
                }
            } catch (error) {
                console.error(error);
            }
            this.overrideDisableMobileStickyHeader = addDisableStickyHeaderClass;
            this.$set( renderData, 'addMobileHeaderPaddingToContent', !addDisableStickyHeaderClass);
        },
        onDestinationClick,
    },
    computed: {
        showLowerContainer():boolean{
            return Object.values( this.$slots ).length > 1;
        },
        ...mapGetters(['renderData'])
    }
})
