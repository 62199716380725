












import Vue from 'vue';
import ProjectImageEditor from '@/components/constructor-product-tab/ProjectImageEditor.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'projectImageEditorModal',
    components: {
        ProjectImageEditor,
    },
    methods: {
        saveProject(){
            if(!this.projectData.name) this.$set( this.projectData, 'name', `Новый проект (${new Date().toLocaleDateString()})`);
            const uploadUserImagePromise = (this as any).$refs.imageEditor.uploadUserImagePromise;
            this.$emit('save-project', {uploadUserImagePromise});
            this.$modal.hide('project-image-editor-modal');
        },
    },
    computed: {
        modalWidth():number{
            return Math.min( window.innerWidth - 50, 510 );
        },
        modalHeight():number{
            return Math.min( window.innerHeight - 50, 390 );
        },
        ...mapGetters(['projectData']),
    },
})
