
























































import Vue from 'vue';
import DateInput from '@/components/elements/DateInput.vue';
import Collapsible from '@/components/elements/Collapsible.vue';
import { BasicPromocode, DiscountPromocode, PromocodeDiscountType, ServerTariff, TariffPromocode } from '@/common/types/tariff.types';
export default Vue.extend({
    name: 'adminPromocodeElement',
    props: {
        promocode:{type: BasicPromocode},
        tariffs:{default: [] as ServerTariff[]},
        index:{type:Number},
    },
    components: {Collapsible, DateInput},
    methods: {
        changeAllowDiscountForAllTariffs(allow:boolean){
            const discount = this.promocode as DiscountPromocode;
            const type:PromocodeDiscountType = allow ? 'UPGRADABLE_TARIFFS' : 'ONE_TARIFF';
            this.$set( discount, 'discountType', type );
        },
        replacePromocodeWith(promocode:BasicPromocode):void{
            this.$emit('replace-promocode',{promocode, index:this.index});
        },
        deletePromocode():void{
            this.$emit('delete-promocode',this.index);
        },
        changeToTariffType(promocode:BasicPromocode):void{
            if(promocode instanceof TariffPromocode) return;
            const changedType = new TariffPromocode(promocode.id, promocode.code, promocode.tariff, new Date(), 0);
            changedType.collapse = false;
            this.replacePromocodeWith(changedType);
        },
        changeToDiscountType(promocode:BasicPromocode):void{
            if(promocode instanceof DiscountPromocode) return;
            const changedType = new DiscountPromocode(promocode.id, promocode.code, promocode.tariff, new Date(), 1, 'ONE_TARIFF');
            changedType.collapse = false;
            this.replacePromocodeWith(changedType);
        },
    },
    computed: {
        collapsibleLabel():string{
            let type = '';
            if(this.isDiscountType) type = '[СКИДКА]';
            if(this.isTariffType) type = '[ТАРИФ]';
            let id = (this.promocode.id||'');
            if(id) id = `ID: ${id}`
            id = ` ${id} `;
            return ` ${type}${id}КОД: ${this.promocode.code} `;
        },
        isDiscountType: {
            get():boolean{
                return this.promocode.isDiscountType();
            },
            cache: false,
        },
        isTariffType: {
            get():boolean{
                return this.promocode.isTariffType();
            },
            cache: false
        },
        allowAllTariffs():boolean{
            return this.isDiscountType && (this.promocode as DiscountPromocode).discountType == 'UPGRADABLE_TARIFFS';
        },
        isSaved():boolean{
            return !!this.promocode.id;
        },
    },
})
