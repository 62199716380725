import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import {API_URL} from '@/common/config';
import {getToken} from '@/common/jwt.service'

const prefixError = (err:any)=>{ 
    throw err;
}

//TODO: Make sure JWT header is not sent to external websites
const ApiService = {
    init(){
        Vue.use(VueAxios,axios);
        Vue.axios.defaults.baseURL = API_URL;
    },
    setHeader(){
        if(Vue.axios.defaults.headers == undefined) Vue.axios.defaults.headers = {};
        if(Vue.axios.defaults.headers.common == undefined) Vue.axios.defaults.headers.common = {};
        const token = getToken();
        Vue.axios.defaults.headers.common["Authorization"] = token;
    },
    get(path:string,params:any={}){
        if(params.headers == undefined) params.headers = {};
        params.headers["Content-type"] = "application/json; charset=utf-8";
        return Vue.axios.get(`${path}`,params).catch(prefixError);
    },
    post(path:string,params:any={}){
        return Vue.axios.post(`${path}`,params,{headers: {"Content-Type":"application/json; charset=utf-8"}}).catch(prefixError);
    },
    put(path:string,params:any={}){
        return Vue.axios.put(`${path}`,params,{headers: {"Content-Type":"application/json; charset=utf-8"}}).catch(prefixError);
    },
    delete(path:string,params:any={}){
        if(params.headers == undefined) params.headers = {};
        params.headers["Content-type"] = "application/json; charset=utf-8";
        return Vue.axios.delete(`${path}`,params).catch(prefixError);
    }
}

export default ApiService;