






























































































import Vue from 'vue';
import TabViewMixin from '@/components/mixins/TabViewMixin.vue';
import { mapGetters } from 'vuex';
import NavigationDestination from '@/common/classes/nav.destination';
import ProjectCard from '@/components/elements/ProjectCard.vue';
import { ProjectModel } from '@/common/classes/models';
import ProjResSearch from '@/components/elements/search/ProjResSearch.vue';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { mapObjectToUrlParams } from '@/common/misc';
import { redirToProjectCreation } from '@/router';
import { getDefaultProjectFilters, getDefaultProjectSearchData,isAnySpecifiedFields,projectSearchPredicate,projectSearchSortModes, sortFunForMode } from '@/common/search.utils';
import { ProjectSearchData } from '@/common/types/project.types';
import TariffService from '@/common/tariff.service';
import TariffLimitationModal from '@/components/elements/modals/TariffLimitationModal.vue';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';

//#region Search


const searchMixin = Vue.extend({
    data(){
        return{
            searchData: getDefaultProjectSearchData(),
            sortMode: projectSearchSortModes[0],
        }
    },
    methods: {
        resetSearchDataOptions(){
            const filters:Record<string,string|number> = getDefaultProjectFilters();
            Object.assign(this.searchData,filters);
        },
    },
    computed: {
        showSearchProjectList():boolean{
            return isAnySpecifiedFields(this.searchData);
        },
        sortFun():(a:ProjectModel,b:ProjectModel)=>number{
            return sortFunForMode(this.sortMode);
        }
    },
});
//#endregion

const component = Vue.extend({
    name: 'projectsView',
    mixins: [
        MiscMixin,
        TabViewMixin,
        searchMixin,
        ResponsiveDataMixin,
    ],
    components: {ProjectCard,ProjResSearch,TariffLimitationModal},
    props: {destination:{type:NavigationDestination}},
    data(){
        return{
            myProjects: [] as ProjectModel[],
            recommendedProjects: [] as ProjectModel[],
            exemplaryProjects: [] as ProjectModel[],
            fetchingProjects: true,
            myProjSortMode: projectSearchSortModes[1],
            recommendProjSortMode: projectSearchSortModes[0],
            exemplaryProjSortMode: projectSearchSortModes[0],
        }  
    },
    mounted(this:any){
        this.onViewMount(component);
        this.updateProjects();
    },
    methods: {
        redirToProjectCreation,
        disabledProjectClick():void{
            this.$modal.show('tariff-limitation')
        },
        updateProjects(){
            const projectRequests = [
                { amount: 256, sort: 'descViews', excludeEmpty: 1 },
                ...(this.isAuthenticated ?
                    [
                        { onlyCurrentUser: 1, amount: 127, sort: 'descUpdated' },
                        // { onlyCurrentUser: 1, amount: 8 },
                    ] : []),
            ].map((paramsObj)=>mapObjectToUrlParams(paramsObj))
            .map((params)=>{
                return {
                    params,
                    promise: ApiService.get(endpoints.getProjects+'?'+params),
                }
            });
            projectRequests.unshift({
                params: '',
                promise: ApiService.get(endpoints.getExemplaryProjects),
            });
            projectRequests.forEach((obj,index)=>{
                obj.promise.then(({data})=>{
                    let {projects} = data;
                    projects = projects.map((proj:any)=>new ProjectModel(proj.product,proj.channels,proj));
                    switch(index){
                        case 0: this.exemplaryProjects = projects; break;
                        case 1: this.recommendedProjects = projects; break;
                        case 2: this.myProjects = projects; break;
                    }
                });
            });
            Promise.all(projectRequests.map((req)=>req.promise)).finally(()=>this.fetchingProjects=false);
        },
    },
    computed: {
        mixedInIsMobileWidth():boolean{
            return (this as any).isMobileWidth;
        },
        isAbleToViewCommunityProjects():boolean{
            return this.isAuthenticated ? 
                TariffService.checkUserHasTrait(TariffService.getTraitTypes().VIEW_COMMUNITY_PROJECTS,this.user) : 
                    false;
        },
        //TODO: (?) fetch
        compFindAllProjects():ProjectModel[]{
            return this.recommendedProjects;
        },
        compFindMatchingProjects():ProjectModel[]{
            const searchData:ProjectSearchData = (this as any).searchData;
            const sortFun:(a:ProjectModel,b:ProjectModel)=>number = (this as any).sortFun;
            const filtered = this.recommendedProjects.filter((proj)=>projectSearchPredicate(proj,searchData));
            return filtered.sort(sortFun);
        },
        compFindMatchingProjectRows():ProjectModel[][]{
            const step = 4;
            const rows:ProjectModel[][] = [];
            const projects = this.compFindMatchingProjects;
            for (let i = 0; i < projects.length; i+=step) rows.push(projects.slice(i,i+step));
            return rows;
        },
        defaultProjData(){
            return {
                name:'Название',
                author:{
                    username:'Пользователь',
                    id:1
                },
                likedIds:[],
                watchedIds:[],
                favoriteIds:[],
            };
        },
        compMyProjects():ProjectModel[]{
            return [...this.myProjects].sort(sortFunForMode(this.myProjSortMode));
        },
        compMyProjectRows():ProjectModel[][]{
            const step = 4;
            //TODO: Make sure all projects are displayed (v-if ri == 0)
            const projects = [this.compMyProjects.slice(0,3)];
            for (let index = 3; index < this.compMyProjects.length; index += step) 
                projects.push(this.compMyProjects.slice(index, index+step));
            return projects;
        },
        compRecommendedProjects():ProjectModel[]{
            return [...this.recommendedProjects].sort(sortFunForMode(this.recommendProjSortMode));
        },
        compRecommendedProjectRows():ProjectModel[][]{
            const step = 4;
            const projects = [];
            for (let index = 0; index < this.compRecommendedProjects.length; index += step)
                projects.push(this.compRecommendedProjects.slice(index, index+step));
            return projects;
        },
        compExemplaryProjects():ProjectModel[]{
            return [...this.exemplaryProjects].sort(sortFunForMode(this.exemplaryProjSortMode));
        },
        compExemplaryProjectRows():ProjectModel[][]{
            const step = 4;
            const projects = [];
            for (let index = 0; index < this.compExemplaryProjects.length; index += step)
                projects.push(this.compExemplaryProjects.slice(index, index+step));
            return projects;
        },
        ...mapGetters(['isAuthenticated','user','navDestinations','activeNavDestination','activeSubDestination'])
    }
});
export default component;
