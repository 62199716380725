





































import Vue from 'vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import { createClickAnchor } from '@/common/misc';
import { footerData, FooterLink, socialMediaLinks} from '@/common/config';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'vueFooter',
    data(){
        return{
            footerData,
        }
    },
    mixins: [MiscMixin,ResponsiveDataMixin],
    methods: {
        onLinkClick(link:FooterLink){
            // if(link.route.length && this.$route.fullPath != link.route) 
            try {
                if(typeof link.redirectOnClick == 'function'){
                    const redirect = link.redirectOnClick.apply(this);
                    if(!redirect) return;
                }
            } catch (e) { 
                console.error('Error occured when executing footerLink.onClick()'); 
                console.error(e); 
            }
            try {
                createClickAnchor(link.route,(a)=>{
                    if(link.newTab) a.target = '_blank';
                });
            } catch (e) { console.error(e) }
        },
        isLinkDisabled(link:FooterLink):boolean{
            const disableLinkConditions:boolean[] = [
                !link.route.length,
                !!link.authOnly && !this.isAuthenticated,
            ];
            for (let i = 0; i < disableLinkConditions.length; i++) if(disableLinkConditions[i]) return true;
            return false;
        },
    },
    computed: {
        socialMediaLinks():Record<string,string>{
            return socialMediaLinks;
        },
        ...mapGetters(['isAuthenticated'])
    },
})
