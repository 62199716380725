import { plainToClass } from "class-transformer";

type SimpleTrait = {
    name:string;
};

type ServerTariff = {
    id:number;
    name:string;
    rubCost:number;
    tariffType:string;
    upgradable:boolean;
    active?:boolean;
    expiration?:string;
    strType?:'paid'|'promo_code'|'educational';
};

export class BasicPromocode {
    id?:number;
    code:string;
    expiration:Date;
    collapse?:boolean;
    tariff:ServerTariff;
    constructor(id:number|undefined, code:string, tariff:ServerTariff, expiration:Date){
        this.id = id;
        this.code = code;
        this.tariff = tariff;
        this.expiration = expiration;
    }
    isDiscountType():boolean{
        // || typeof ((this as any) as DiscountPromocode).priceDiscountPercentage == 'number'
        return this instanceof DiscountPromocode;
    }
    isTariffType():boolean{
        // || typeof ((this as any) as TariffPromocode).userMinExpiration == 'number'
        return this instanceof TariffPromocode;
    }
    _afterFromPlain():void{
        if(typeof this.expiration == 'string') this.expiration = new Date(this.expiration);
        this.collapse = true;
    }
}

type PromocodeDiscountType = 'UPGRADABLE_TARIFFS'|'ONE_TARIFF';

export class DiscountPromocode extends BasicPromocode {
    //A percentage, ranges from 1 to 99
    priceDiscountPercentage:number;
    discountType:PromocodeDiscountType;
    constructor(id:number|undefined, code:string, tariff:ServerTariff, expiration:Date, priceDiscountPercentage:number, discountType:PromocodeDiscountType){
        super(id, code, tariff, expiration);
        this.priceDiscountPercentage = priceDiscountPercentage;
        this.discountType = discountType;
    }
    static fromPlain(obj:Record<string,any>):DiscountPromocode{
        const converted = plainToClass(DiscountPromocode, obj);
        converted._afterFromPlain();
        return converted;
    }
}

export class TariffPromocode extends BasicPromocode {
    userMinExpiration:number;
    constructor(id:number|undefined, code:string, tariff:ServerTariff, expiration:Date, minDuration:number){
        super(id, code, tariff, expiration);
        this.userMinExpiration = minDuration;
    }
    static fromPlain(obj:Record<string,any>):TariffPromocode{
        const converted = plainToClass(TariffPromocode, obj);
        converted._afterFromPlain();
        return converted;
    }
}

// https://yookassa.ru/developers/payments/declined-payments#cancellation-details-reason
type PaymentCancellationReason = 
    '3d_secure_failed'|
    'call_issuer'|
    'canceled_by_merchant'|
    'card_expired'|
    'country_forbidden'|
    'expired_on_capture'|
    'expired_on_confirmation'|
    'fraud_suspected'|
    'general_decline'|
    'identification_required'|
    'insufficient_funds'|
    'internal_timeout'|
    'invalid_card_number'|
    'invalid_csc'|
    'issuer_unavailable'|
    'payment_method_limit_exceeded'|
    'payment_method_restricted'|
    'permission_revoked'|
    'unsupported_mobile_operator';

type PaymentStatus = 'pending'|'succeeded'|'canceled';

type KassaPayment = {
    description:string;
    date:string;
    id:string;
    rubAmount:number;
    cardLast4:string;
    status:PaymentStatus;
    // https://yookassa.ru/developers/payments/declined-payments#cancellation-details-party
    cancellationParty:'yoo_money'|'payment_network'|'merchant';
    cancellationReason:PaymentCancellationReason;
    rnn:string;
};

export {KassaPayment,PaymentCancellationReason,PaymentStatus,SimpleTrait,ServerTariff,PromocodeDiscountType};