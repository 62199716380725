




import Vue from 'vue'
export default Vue.extend({
    name: '',
    props: {
        value: {type:Date},
    },
    methods: {
        onChange(e:{target:{value:string}}){
            this.$emit('change', new Date( e.target.value ));
        },
    },
    computed: {
        dateValue():string{
            const value = (this.value as any) as Date;
            var mm = value.getMonth() + 1; // getMonth() is zero-based
            var dd = value.getDate();

            return [value.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                    ].join('-');
        },
    },
})
