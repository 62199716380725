
import { isMobile } from '@/common/misc';
import Vue from 'vue'
export default Vue.extend({
    name: 'responsiveDataMixin',
    created(){
        window.addEventListener('resize',this.windowResizeListener);
    },
    destroyed(){
        window.removeEventListener('resize',this.windowResizeListener);
    },
    data(){
        return{
            winWidth: window.innerWidth,
        }
    },
    methods: {
        windowResizeListener(e:UIEvent){ this.winWidth = window.innerWidth; },
        getOrientationChangePromise(isVertical:boolean):Promise<void>{
            return new Promise((resolve)=> {
                const isOrientationCorrect = () => (isVertical && this.isVerticalOrientation) || (!isVertical && this.isHorizontalOrientation);
                if(isOrientationCorrect()) {
                    resolve();
                    return;
                }
                const resolveOnOrientationChange = () => {
                    if(isOrientationCorrect()) {
                        window.removeEventListener('resize', resolveOnOrientationChange);
                        resolve();
                    }
                }
                window.addEventListener('resize', resolveOnOrientationChange);
            });
        },
    },
    computed: {
        isMobileWidth():boolean {
            return window.innerWidth <= 1280 && isMobile();
        },
        isVerticalOrientation: {
            get():boolean {
                return window.innerWidth < window.innerHeight;
            },
            cache: false,
        },
        isHorizontalOrientation: {
            get():boolean {
                return !this.isVerticalOrientation;
            },
            cache: false
        },
    }
})
