type Resource = {
    id:number;
    name:string;
    description:string;
    image:string;
    link:string;
    type:string;
    language:string;
    cost:string;
    complexity:string;
    watchedIds:string[];
    likedIds:string[];
    favoriteIds:string[];
}
type ResourceCollection = {
    id:number;
    rid:number;
    name:string;
    image:string;
    category:ResourceCategory;
    resourceList:Resource[];
    watchedIds:string[];
    likedIds:string[];
}
type ResourceCategory = {
    id?: number;
    text: string;
    priority: number;
}

type ResourceSearchData = {
    categories: ResourceCategory[];
    searchQuery: string;
    type: string;
    language: string;
    cost: string;
    complexity: string;
    socialType: string;
};

export const utils = {
    countResourceCollectionLikes(collection:ResourceCollection){
        return collection.resourceList.reduce((acc,{likedIds})=>likedIds.length+acc,0);
    },
    countResourceCollectionWatched(collection:ResourceCollection){
        return collection.resourceList.reduce((acc,{watchedIds})=>watchedIds.length+acc,0);
    },
    json: {
        parseJSON(data:string|Array<any>):ResourceCollection[]{
            if(typeof data == 'string')
                data = JSON.parse(data);
            return data as ResourceCollection[];
        }
    }
}
export type {Resource,ResourceCollection,ResourceCategory,ResourceSearchData};