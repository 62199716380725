import NavDestination from '@/common/classes/nav.destination';
import store from '.';
import { AFTER_ROUTE, NAV_DESTINATION_CLICK, SET_ACTIVE_NAV_DESTINATION } from './mutations';
import SettingsView from '@/views/SettingsView.vue';
import AboutView from '@/views/AboutView.vue';
import SupportView from '@/views/SupportView.vue';
import ProjectsView from '@/views/ProjectsView.vue';
import UnitEconomyConstructor from '@/views/UnitEconomyConstructor.vue';
import Resources from '@/views/Resources.vue';
import Home from '@/views/Home.vue';
import router from '@/router';

//TODO: ?Move to router meta
const navDestinations = [
    new NavDestination({text:"Главная",route:'/',component:Home,subDestinations: [
        new NavDestination({text:"Ваша активность"}),
        // new NavDestination({text:"Новые проекты"}),
        // new NavDestination({text:"Рекомендации"})
    ]}),
    new NavDestination({text:"Юнит-экономика",route:'/create',component:UnitEconomyConstructor,subDestinations:[
        new NavDestination({text:"Продукт"}),
        new NavDestination({text:"Канал"}),
        new NavDestination({text:"Бюджет"})
    ]}),
    new NavDestination({text:"Проекты",route:'/projects',component:ProjectsView,subDestinations:[
        new NavDestination({text:"Новые проекты"}),
        new NavDestination({text:"Мои проекты"}),
        new NavDestination({text:"Рекомендации"})
    ]}),
    new NavDestination({text:"Ресурсы",route:'/resources',component:Resources,subDestinations:[
        new NavDestination({text:"Мои ресурсы"}),
    ]}),
    new NavDestination({text:"О проекте",route:'/about',component:AboutView,subDestinations:[
        new NavDestination({text:"Информация"})
    ]}),
    new NavDestination({text:"Настройки",route:'/settings',type:1,component:SettingsView,userType:'onlyAuth',subDestinations:[
        new NavDestination({text:"Учетная запись"}),
        new NavDestination({text:"Тарифы"}),
        new NavDestination({text:"Биллинг"})
    ]}),
    new NavDestination({text:"Поддержка",route:'/support',type:1,component:SupportView,subDestinations:[
        new NavDestination({text:"Ответы на вопросы"})
    ]}),
    new NavDestination({text:"Выход",route:'/logout',type:1,userType:'onlyAuth'}),
    new NavDestination({text:"Вход",route:'/login',type:1,userType:'onlyUnauth'})
]

export async function onDestinationClick(destination:NavDestination,getBeforeQuitPromise:()=>Promise<unknown>,unsavedChanges:boolean){

    if(destination.route) {
        if(destination.route == router.currentRoute.fullPath) return;
        const {activeNavDestination} = store.getters;
        if(destination.route != '/create' && activeNavDestination.route == '/create' && unsavedChanges){
            try{ await getBeforeQuitPromise(); }
            catch(e){return;}
        }

        router.push(destination.route);
        return;
    }
    store.commit(NAV_DESTINATION_CLICK,destination);
}

export default {
    state: {
        navDestinations
    },
    mutations: {
        [SET_ACTIVE_NAV_DESTINATION](state:any,navDestination:NavDestination){
            const destinations:NavDestination[] = state.navDestinations;
            destinations.forEach((destination)=>destination.setIsActive(false));
            navDestination.setIsActive(true);
            destinations
                .filter((destination)=>destination.allActiveDestinations().length>0)
                .forEach((destiantion)=>destiantion.setIsActive(true));
        },
        [NAV_DESTINATION_CLICK](state:any,navDestination:NavDestination):void{
            if(navDestination.subDestinations.length) return store.commit(NAV_DESTINATION_CLICK,navDestination.subDestinations[0]);
            store.commit(SET_ACTIVE_NAV_DESTINATION,navDestination);
        }
    },
    getters: {
        navDestinations: (state:any):NavDestination[]=>state.navDestinations,
        activeNavDestination: (state:any):NavDestination|undefined=>{
            const activeDestinations = (state.navDestinations as NavDestination[])
                .filter((destination:NavDestination)=>destination.isActive);
            if(activeDestinations.length > 1) 
                console.warn({activeDestinations,all:state.navDestinations,msg:'Root active destinations.length is '+activeDestinations.length});
            return activeDestinations[0];
        },
        activeSubDestination: (state:any):NavDestination|undefined=>{
            const activeDestination = (store.getters.activeNavDestination as NavDestination);
            if(!activeDestination) return undefined;
            return activeDestination
                .allActiveDestinations()
                .find((destination)=>destination!=activeDestination);
        }
    },
    actions: {
    }
}