// import store from '@/store';
import { ProjectPrivacyState } from '@/common/types/project.types';
import { SimpleTrait } from '@/common/types/tariff.types';

type DisplayTariffData = {
    name:string,
    displayPrice:string,
    priceDescription?:string,
    description:string,
    buttonText?:string,
    feedbackReason?:string
};

//TODO: Specify reason when sending support message, make sure it works.
//TODO: Add email notification for all new feedback requests
function getDisplayTariffData():readonly DisplayTariffData[]{ 
    return Object.freeze( 
        [
            // {
            //     name:'Первые шаги',
            //     displayPrice:'Бесплатно',
            //     description:'3 Проекта\nДоступ к типовым проектам\nВозможность поделиться проектом по ссылке\nРекомендациия по структуре затрат\nВозможность закрыть проект от просмотра'
            // },
            {
                name:'Практика',
                displayPrice:'Бесплатно',
                /* removed Всё из тарифа "Первые шаги" & Доступ к типовым проектам */
                description:'Возможность поделиться проектом по ссылке\nРекомендациия по структуре затрат\nВозможность закрыть проект от просмотра\nСоздание 10 проектов\nДоступ к коллекции проектов из разных отраслей\nСохранение графиков'
            },
            {
                name:'Реальная сделка',
                // displayPrice:'1590₽/месяц',//Сравнение сценариев
                displayPrice:'по запросу',//Сравнение сценариев
                description:'Всё из тарифа "Практика"\nНеограниченное количество проектов\nСохранение в Excel и Power Point (в разработке)',
                buttonText: 'Свяжитесь с нами',
                feedbackReason: 'tariff-real-deal'
            },
            // TODO: Create support ticket with specified reason (group-request/consultation-request)
            // {
            //     name:'Подключить группу',
            //     displayPrice:'от 500₽',
            //     priceDescription:'за 1 пользователя в год',
            //     description:'Набор возможностей по запросу',
            //     buttonText: 'Cвяжитесь с нами',
            //     feedbackReason: 'tariff-for-group-request',
            // },
            {
                name:'Консультация и обучение',
                // displayPrice:'от 3000₽',
                displayPrice:'по запросу',
                // priceDescription:'за 1 пользователя в год',
                description:'Совместный расчёт экономики проекта\nПодготовка питч-презентации',
                buttonText: 'Cвяжитесь с нами',
                feedbackReason: 'tariff-request-for-consultation',
            },
            //Should be moved to another section (WIP, figma)
            // {
            //     name:'Получить консультацию',
            //     displayPrice:'от 5000₽',
            //     description:'Всё из тарифа "Реальный бизнес"\nСовместный расчёт экономики проекта\nМодель в Excel и готовый слайд для питч-презентации',
            //     buttonText: 'Cвяжитесь с нами',
            // }
            // {
            //     name:'Для преподователей',
            //     displayPrice:'Договорная',
            //     description:'Заполните форму и мы с вами свяжемся',
            //     buttonText:'Заполните форму'
            // }
        ]
    )
}
//TODO:? Figure out a way to match trait types & etc. between server & browser
// also think of a way traits are stored (no custom trait combinations - declare types or retrieve traits from the server)
// ?integration tests
const TariffService = {
    getTraitTypes(){ 
        const traitTypes = Object.freeze({
            PROJECT_LIMIT_3: {} as SimpleTrait,
            PROJECT_LIMIT_10: {} as SimpleTrait,
            PROJECT_LIMIT_NONE: {} as SimpleTrait,
            PROJECT_VISIBILITY_PRIVATE: {} as SimpleTrait,
            PROJECT_VISIBILITY_PROTECTED: {} as SimpleTrait,
            VIEW_COMMUNITY_PROJECTS: {} as SimpleTrait,
            PROJECT_PDF_EXPORT: {} as SimpleTrait,
            PROJECT_POWER_POINT_EXPORT: {} as SimpleTrait,
            PROJECT_EXCEL_EXPORT: {} as SimpleTrait,
            PROJECT_COMPARE_SCENARIOS: {} as SimpleTrait,
        });
        Object.keys( traitTypes ).forEach((k)=>{
            const val:SimpleTrait = (traitTypes as any)[k];
            val.name = k;
        });
        return traitTypes;
    },
    getMaxProjectsForUser(user:any):number{
        if(user == undefined || !user.tariffTraits) return 0;
        const {PROJECT_LIMIT_NONE,PROJECT_LIMIT_10,PROJECT_LIMIT_3} = this.getTraitTypes();
        if(this.checkUserHasTrait(PROJECT_LIMIT_NONE,user)) return Infinity;
        else if(this.checkUserHasTrait(PROJECT_LIMIT_10,user)) return 10;
        else if(this.checkUserHasTrait(PROJECT_LIMIT_3,user)) return 3;
        else return 3;
    },
    checkUserHasTrait(trait:SimpleTrait,user:any):boolean{
        if(user == undefined || !Array.isArray(user.tariffTraits)) return false;
        const traits:string[] = user.tariffTraits;
        return traits.includes(trait.name);
    },
    getEnabledProjectPrivacyStates(user:any,allPrivacyStates:ProjectPrivacyState[]):ProjectPrivacyState[]{
        return allPrivacyStates.filter((s)=>{
            const matchingTrait = s.requiredTrait;
            return !matchingTrait || this.checkUserHasTrait( matchingTrait, user );
        });
    },
    getDisplayTariffData,
};

export {DisplayTariffData};

export default TariffService;