






import Vue from 'vue'
export default Vue.extend({
    name: 'adjustableTextarea',
    props: {
        value: {type:String},
    },
    mounted(){
        this.adjustTextarea(this.value);
    },
    watch: {
        value(newValue:string){
            this.adjustTextarea(newValue);
        },
    },
    methods: {
        onChange(e:any):void{ this.$emit('change', e.target.value); },
        adjustTextarea(text:string){
            this.textArea.value = text;
            this.textArea.style.height = '1px';
            this.textArea.style.height = (0+this.textArea.scrollHeight)+"px";
        },
    },
    computed: {
        textArea():HTMLTextAreaElement{
            return this.$el as HTMLTextAreaElement;
        },
    },
})
