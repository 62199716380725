




















































































































import Vue from 'vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ApiService from '@/common/api.service';
import { API_URL, endpoints, isRemote } from '@/common/config';
import { createClickAnchor, formatAsRub, processResponseError, smartToFixed } from '@/common/misc';
import { KassaPayment, PaymentStatus, ServerTariff } from '@/common/types/tariff.types';
import OnlyAuthenticatedMixin from '@/components/mixins/OnlyAuthenticatedMixin.vue';
import { loadExternalScript, parseDate } from '@/common/misc';

export default Vue.extend({
    name: 'paymentsView',
    async mounted(){
        const {action,tariffId} = this.$route.query;
        switch (action) {
            case 'create':
                //Create new payment
                this.state = this.states.CREATE;
                await this.startCreatingPayment(tariffId+'');
                break;
            case 'complete':
                this.state = this.states.COMPLETE;
                for (let i = 10; i > 0; i--){
                    this.postPaymentCounter = i; 
                    await new Promise((r)=>setTimeout(r,1000));
                }
                createClickAnchor('/payments');
                break;
            default:
                this.state = this.states.LIST;
                await this.fetchListPayments();
                break;
        }
    },
    mixins: [OnlyAuthenticatedMixin, MiscMixin],
    data(){
        const states = Object.freeze({LIST:0,CREATE:1,COMPLETE:2})
        return{
            isYooKassaLoaded: false,
            states,
            state: states.LIST,
            payments: [] as KassaPayment[],
            isFetchingPaymentsList: false,
            disableFetchPaymentsListButton: false,
            isFetchingCreatePayment: false,
            showPaymentForm: false,
            prePaymentData: undefined as {tariffs:ServerTariff[],activeTariffs:ServerTariff[],tariffId:string}|undefined,
            postPaymentCounter: 10,
            createTariff: undefined as ServerTariff|undefined,
            promoCodeInput: '',
            promoCodeIsCheckedAndValid: false,
            promoCodeDiscount: 0,
            validPromoCodeMessage: '',
            promoCodeMessages: [] as string[],
            emailMessage: '',
            inputEmail: '',
            serverEmail: null,
            serverNotificationEmail: null,
        }
    },
    methods: {
        //#region create payment methods
        async startCreatingPayment(tariffId:string):Promise<void>{
            const {tariff} = (await ApiService.get(endpoints.getTariffPreview(tariffId))).data;
            const {tariffs,activeTariffs} = (await ApiService.get(endpoints.getTariffs)).data;
            this.prePaymentData = {tariffs,activeTariffs,tariffId};
            const activatedTariff = this.prePaymentData.activeTariffs.find(({id})=>id+'' == tariffId);
            if(activatedTariff) {
                alert(`Тариф ${activatedTariff.name} уже активирован`);
                createClickAnchor('/');
                return;
            }
            this.createTariff = this.prePaymentData.tariffs.find(({id})=>id+'' == tariffId);
            if(!this.createTariff) {
                alert('Не найден тариф с id '+tariffId);
                return;
            }
            this.updateServerEmails();
        },
        async createPayment():Promise<void>{
            try {
                await this.setNotificationEmail();
            } catch (error) {
                this.emailMessage = processResponseError(error);
                return;
            }

            const tariffId = this.prePaymentData?.tariffId;
            if(!this.prePaymentData) {
                alert('Прозиошла ошибка при создании платежа, не найдены данные для создания тарифа.');
                return;
            }
            this.showPaymentForm = true;
            let paymentConfirmation;
            try {
                this.isFetchingCreatePayment = true;

                if(typeof tariffId != 'string' || Number.isNaN(parseInt(tariffId+''))) {
                    alert(`Произошла ошибка. Неверное значение параметра tariffId (${tariffId})`); 
                    return;
                }
                const buyTariffData = {
                    tariffId,
                    promoCode: '',
                };
                if(this.promoCodeIsCheckedAndValid) buyTariffData.promoCode = this.promoCodeInput;
                const postPaymentPromise = ApiService.post(endpoints.postBuyTariff, buyTariffData);
                const promises = [postPaymentPromise] as Promise<any>[];
                if(!this.isYooKassaLoaded) promises.push(this.loadYooKassaWidget());
                await Promise.all(promises);
                //Should be immediatly resolved
                const processPaymentData = (await postPaymentPromise).data;
                paymentConfirmation = processPaymentData.createPaymentDto.paymentConfirmation;

            } catch (error) {
                console.error(error);
                alert(processResponseError(error));
            } finally {
                this.isFetchingCreatePayment = false;
            }

            if(paymentConfirmation!=undefined){
                await Vue.nextTick();                

                const checkout = new (window as any).YooMoneyCheckoutWidget({
                    confirmation_token: paymentConfirmation.confirmation_token, 
                    return_url: (isRemote ? API_URL : 'https://izibiz.club')+'/payments?action=complete',
                    error_callback(e:any) {
                        console.error(e);
                        alert(e);
                    }
                });
                await checkout.render('payment-form');

                //TODO: Programmatically click on bank card element/search other way to enforce bank card payment (tech support didn't help)
            }
        },
        loadYooKassaWidget():Promise<void>{
            return loadExternalScript('https://yookassa.ru/checkout-widget/v1/checkout-widget.js')
                .then(()=>{this.isYooKassaLoaded = true});
        },
        //#endregion
        refreshListPayments():void{
            location.reload();
        },
        //#region notification email
        async updateServerEmails():Promise<void>{
            let emailData
            try {
                emailData = (await ApiService.get(endpoints.getCurrentEmail)).data;
            } catch (error) {
                alert(processResponseError(error));
            }
            const { email, notificationEmail } = emailData;
            this.serverEmail = email;
            this.serverNotificationEmail = notificationEmail;
            this.inputEmail = ' ';
            await Vue.nextTick();
            this.inputEmail = '';
            if(email) this.inputEmail = email;
            if(notificationEmail) this.inputEmail = notificationEmail;
        },
        //Called during a payment creation
        //Throws exception if email is invalid
        async setNotificationEmail():Promise<void>{
            (await ApiService.put(endpoints.putUpdateNotificationEmail(this.inputEmail))).data;
        },
        //#endregion
        //#region promoCodes during payment creation
        addPromoCodeMessage(str:string){
            this.promoCodeMessages.push(str);
            setTimeout(()=>{
                const index = this.promoCodeMessages.indexOf(str);
                const cloned = [...this.promoCodeMessages];
                cloned.splice(index, 1);
                this.promoCodeMessages = cloned;
            }, 10000);
        },
        async promoCodeFormSubmit(){
            if(this.promoCodeIsCheckedAndValid) {
                this.promoCodeIsCheckedAndValid = false;
                this.validPromoCodeMessage = '';
                this.promoCodeMessages = [];
            }else{
                const promoCode = this.promoCodeInput;
                try {
                    const {message, isValid, calcPercentage}:{message:string, isValid:boolean, calcPercentage:number} = (await ApiService.post(endpoints.postTryDiscountPromoCode(this.createTariff?.id as number, promoCode))).data;
                    this.validPromoCodeMessage = message;
                    if(!isValid)
                        this.addPromoCodeMessage( message );
                    this.promoCodeDiscount = calcPercentage;
                    this.promoCodeIsCheckedAndValid = isValid;
                } catch (error) {
                    this.addPromoCodeMessage(processResponseError(error));
                }
            }
        },
        //#endregion
        //#region list payments
        async fetchListPayments():Promise<void>{
            try {
                this.isFetchingPaymentsList = true;
                const {data} = await ApiService.get(endpoints.getUserOperations);
                this.payments = data.operations;
                this.disableFetchPaymentsListButton = true;
                setTimeout(()=>this.disableFetchPaymentsListButton = false,3000);
            } catch (error) {
                alert(processResponseError(error));
            } finally {
                this.isFetchingPaymentsList = false;
            }
        },
        getDisplayDescriptionForPayment(payment:KassaPayment):string{
            if(payment.status != 'canceled')
                return payment.description;
            const cancellationParty = (function(){
                switch (payment.cancellationParty) {
                    case 'yoo_money': return 'ЮKassa';
                    case 'payment_network': return 'Прочий участник платежного процесса';
                    case 'merchant': return 'Izibiz';
                    default: return `Не определён (${payment.cancellationParty})`;
                }
            })();
            //TODO: Translate cancellation reason
            const cancellationReason = payment.cancellationReason;
            return `Сторона, отменившая платеж: ${cancellationParty}\nПричина: ${cancellationReason}`;
        },
        translateStatus(status:PaymentStatus):string{
            switch (status) {
                case 'succeeded': return 'Оплачен';
                case 'pending': return 'В ожидании';
                case 'canceled': return 'Отменен';
                default: return `Не определён (${status})`;
            }
        },
        //#endregion
        parseDate,
    },
    computed: {
        hasDeclinedPayments():boolean{
            return !!( this.payments && this.payments.find(({status})=>status == 'canceled') );
        },
        createTariffPrice():string{
            if(!this.createTariff) return '---';
            return formatAsRub( this.createTariff.rubCost * (1 - this.promoCodeDiscount) );
        },
        isUpgradingTariff():boolean{
            if(!this.prePaymentData) return false;
            return !!this.prePaymentData.activeTariffs.find(({upgradable})=>upgradable);
        },
    },
})
