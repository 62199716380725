































import Vue from 'vue';
import { endpoints, tooltipsText } from '@/common/config';
import { Tooltip } from '@/common/tooltips.ts'
import ApiService from '@/common/api.service';
import { processResponseError } from '@/common/misc';
import AdjustableTextarea from '@/components/elements/AdjustableTextarea.vue';
/*
Список подсказок из приложения, при поиске добавить галку с корректировкой

запрашивать и сохранять в лх корректировки каждый день, после 0:00
!!TODO: PREVENT XSS
*/
export default Vue.extend({
    name: 'adminTooltipsTab',
    data(){
        return {
            overrideTooltipsMap: {} as Record<string,Tooltip>,
            defaultTooltips: [] as Tooltip[],
            searchQuery: '',
            isLoading: true,
            isSaving: false,
            searchOnlyChanged: false,
            searchOnlyUnsaved: false,
        };
    },
    components: {
        AdjustableTextarea,
    },
    async mounted(){
        for (const name in tooltipsText) this.defaultTooltips.push({name, value: (tooltipsText as Record<string,any>)[name]});
        this.isLoading = true;
        try {
            const tooltips = (await ApiService.get(endpoints.adminGetOverrideTooltips)).data.tooltips;
            this.updateOverrideTooltips(tooltips);
        } catch (error) { alert(processResponseError(error)); }
        finally { this.isLoading = false; }
    },
    methods: {
        onTooltipChange(tooltip:Tooltip, text:string){
            const overrideTooltip = this.overrideTooltipsMap[tooltip.name];
            this.$set(overrideTooltip, 'value', text);
            this.$set(overrideTooltip, 'isChanged', true);
        },
        overrideTooltip(tooltip:Tooltip):void{
            const tooltipClone = Object.assign({}, tooltip);
            tooltipClone.isChanged = true;
            this.$set(this.overrideTooltipsMap, tooltip.name, tooltipClone );
        },
        updateOverrideTooltips(fetched:Tooltip[]){
            const map:Record<string,Tooltip> = {};
            fetched.forEach((t)=>map[t.name] = t);
            this.overrideTooltipsMap = map;
        },
        async saveTooltips(){
            this.isSaving = true;
            try {
                const tooltips = (await ApiService.put( endpoints.adminPutOverrideTooltips, Object.values( this.overrideTooltipsMap ) )).data.tooltips;
                this.updateOverrideTooltips(tooltips);
            } catch (error) { alert(processResponseError(error)); }
            finally { this.isSaving = false; }
        },
    },
    computed: {
        tooltipList():Tooltip[]{
            const containsQuery = (s:string) => s != undefined ? s.toLowerCase().includes( this.searchQuery.toLowerCase() ) : false;
            return this.defaultTooltips.filter(({name, value}) => {
                const overrideTooltip = this.overrideTooltipsMap[name];
                const passesChangeCheck = !this.searchOnlyChanged || !!overrideTooltip;
                const passesUnsavedCheck = !this.searchOnlyUnsaved || overrideTooltip?.isChanged;
                const queryMatch = this.searchQuery.length == 0 || 
                    containsQuery( name ) || 
                    containsQuery( value ) ||
                    containsQuery( overrideTooltip?.name ) ||
                    containsQuery( overrideTooltip?.value );
                return queryMatch &&
                    passesChangeCheck &&
                    passesUnsavedCheck;
            });
        },
    },
})
