













import Vue from 'vue';
import VueCropper from 'vue-cropperjs';
import UploadImageMixin from '@/components/mixins/UploadImageMixin.vue';
import { dataURLtoFile } from '@/common/rescale.service';
import 'cropperjs/dist/cropper.css';

export default Vue.extend({
    name: 'imageUploader',
    mounted(){
        this.showDefaultImg = this.hasDefaultImg;
        if(this.selectFileOnMounted) this.selectImageForCropper();
    },
    mixins: [UploadImageMixin],
    components: { VueCropper },
    watch: {
        defaultImg(){//Show defaultImg if it is updated
            if(this.hasDefaultImg) {
                this.showDefaultImg = true;
                this.cropImgUrl = '';
                this.cropImgFile = undefined;
            }
        },
    },
    props: {
        defaultImg: {type:String,default:''},
        aspectRatio: {type:Number,default:16/9},
        selectFileOnMounted: {type:Boolean, default: false},
    },
    data(){
        return{
            showDefaultImg: false,
            cropImgUrl: '',
            cropImgFile: undefined as (undefined|File),
        }
    },
    methods: {
        async selectImageForCropper(){
            const {file,url} = await (this as any).selectImagePromise();
            this.cropImgUrl = url;
            this.cropImgFile = file;
            this.showDefaultImg = true;
            await Vue.nextTick();
            this.showDefaultImg = false;
            this.$emit('crop-img-change', {url,file, getPromise: this.uploadUserImagePromise});
        },
        async uploadUserImagePromise():Promise<string>{
            if(!this.croppedURL) return '';
            const croppedFile = dataURLtoFile(this.croppedURL,this.cropImgFile?.name+'','image/png');
            return await (this as any).uploadImg(croppedFile);
        },
    },
    computed: {
        croppedURL: {
            get():string{
                const cropperComponent = (this.$refs.cropper as any);
                return cropperComponent ? cropperComponent.getCroppedCanvas().toDataURL() : '';
            },
            cache: false,
        },
        hasDefaultImg():boolean{
            return !!this.defaultImg;
        },
        hasCropImgUrl():boolean{
            return !!this.cropImgUrl;
        },
    },
})
