




































































import Vue from 'vue';
import NavHeader from '@/components/elements/NavHeader.vue';
import NavigationDestination from '@/common/classes/nav.destination';
import TabViewMixin from '@/components/mixins/TabViewMixin.vue';
import { mapGetters } from 'vuex';
const component = Vue.extend({
    name: 'aboutView',
    components: {NavHeader},
    mixins: [TabViewMixin],
    mounted(this:any){
        this.onViewMount(component);
    },
    computed: {
        ...mapGetters(['navDestinations','activeSubDestination','activeNavDestination'])
    }
});
export default component;
