




















import Vue from 'vue'
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'tariffLimitationModal',
    props: {
        type: {default:('' as ''|'communityProjects')}
    },
    computed: {
        modalWidth(){
            return Math.min( document.documentElement.offsetWidth, 450 );
        },
        isCommunityProjectsLimitation():boolean{
            return this.type == 'communityProjects';
        },
        ...mapGetters(['isAuthenticated']),
    },
})
