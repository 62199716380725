/* eslint-disable @typescript-eslint/no-use-before-define */

import jp from 'jsonpath';
import Vue from 'vue';
import store from '@/store/index';
import {ExpenseLiteral} from '../interfaces';
import {ExpenseReference,NumberReference, Reference, Referencer} from './referencer';
import { plainToClass } from 'class-transformer';
import { v4 as uuidv4 } from 'uuid';
import { getUniqueName, isMobile, jsonCycler } from '../misc';
import channelManager from './channel.manager';
import productManager from './product.manager';

/**
 * Затрата на какой-либо товар или услугу.
 * @description Используется при расчёте юнит-экономики проекта.
 * Различаются по типам (unit/const/onetime) и методу расчёта стоимости (cost/unit/ref)
 * Также может иметь подзатраты. В таком случае стоимость родительской затраты равна сумме всех подзатрат.
 * 
 * @see {CostExpense} Тип затраты, цена которой рассчитывается по стоимости в рублях (вводится пользователем). 
 * В коде указывается, как "costExpense"
 * @see {UnitExpense} Тип затраты, цена которой рассчитывается по количеству и цене за единицу.
 * В коде указывается, как "unitExpense"
 * @see {RefExpense}  Тип затраты, цена которой рассчитывается пропорционально другому показателю или затрате модели.
 * В коде указывается, как "refExpense"
 */
export class Expense{
    /** Название затраты */
    name: string
    /** 
     * Тип затраты 
     * -1: Не определён
     * 0: Переменный, в коде указывается, как "unitExpense"
     * 1: Постоянный, в коде указывается, как "constExpense"
     * 2: Разовый, в коде указывается, как "onetimeExpense"
     * */
    type: number
    /** Свернут ли список подзатрат */
    folded: boolean|undefined = true
    /** Подзатраты */
    subExpenses: Expense[] | undefined
    /** 
     * Родительская затрата. 
     * Значение устанавливается при добавлении этой затраты в список подзатрат 
     * */
    parentExpense: Expense | undefined
    /** Перемещается ли затрата в данный момент */
    isDragged = !isMobile()
    /** 
     * Уникальный идентификатор затраты. 
     * Генерируется при создании затраты (до сохранения проекта). 
     * */
    uuid = uuidv4()
    /** Месяц активации затраты (используется при расчёте стоимости в бюджете) */
    activationMonth?:number;
    /**
     * @param name 
     * @param type Тип затраты.
     * @param subExpenses Подзатраты.
     */
    constructor(name:string,type=0,subExpenses?:Expense[]){
        this.name = name
        this.type = type
        this.subExpenses = subExpenses
        if(subExpenses) 
            subExpenses.forEach((s:Expense)=>{
                s.setParentExpense(this)
                s.setType(type)
            });
    }
    /**
     * Добавляет новую подзатрату.
     * 
     * @param expense Затрата, которую нужно добавить в список подзатрат.
     * @throws {Error} Добавление подзатрат не поддерживается (тип затраты - cost/unit/ref).
     */
    addSubExpense(expense:Expense):void{
        if(this.subExpenses == undefined) throw new Error("Unable to add a new SubExpense, subExpense array is undefined");
        expense.setParentExpense(this)
        expense.setType(this.type)
        this.subExpenses.push( expense )
    }
    isDetailExpense():boolean{
        return this instanceof CostExpense || this instanceof UnitExpense || this instanceof RefExpense;
    }
    setType(t:number):void{
        if(this.parentExpense != undefined && this.type != t){ this.parentExpense.setType(t); return;}
        this.type = t;
        if(this.subExpenses != undefined) this.subExpenses.forEach((e)=>e.type=t)
    }
    setParentExpense(p:Expense):void{
        this.parentExpense = p
    }
    duplicate():Expense{
        const cb = [] as (()=>void)[];
        const duplicate = Expense.from( JSON.parse( JSON.stringify( this, jsonCycler() ) ), cb );

        duplicate.uuid = uuidv4();
        if( this.parentExpense == undefined){ 
            const siblings = store.state.product.expenses as Expense[]
            duplicate.name = getUniqueName(duplicate.name,siblings.map((e)=>e.name));
            siblings.splice(siblings.findIndex((e)=>e.uuid==this.uuid)+1, 0, duplicate);
            cb.forEach((f)=>f());
            if(duplicate.subExpenses){
                const subExpenses = [...duplicate.subExpenses];
                duplicate.subExpenses = [];
                subExpenses.forEach((e)=>{
                    e.parentExpense = duplicate;
                    return e.duplicate();
                });
            }
            if(this.withSubExpenses().find((e)=>e instanceof RefExpense)){
                const expenses:RefExpense[] = store.getters.product.expenses.filter((e:Expense)=>e instanceof RefExpense);
                const referencer:Referencer = store.getters.referencer;
                for (let i = 0; i < expenses.length; i++) {
                    const expense = expenses[i];
                    const {reference} = expense;
                    if(reference instanceof NumberReference) continue;
                    const defaultReference = referencer.allSafeReferences(expense)[0];
                    const safeReferences = referencer.safeExpenseReferences(expense);
                    const matchingRef = safeReferences.find((ref)=>ref.to.equalsTo(reference.to));
                    if(!matchingRef) expense.reference = defaultReference;
                }
            }
        }
        else{ 
            duplicate.name = getUniqueName(duplicate.name,(this.parentExpense.subExpenses as Expense[]).map((e)=>e.name));
            this.parentExpense.addSubExpense(duplicate);
            cb.forEach((f)=>f());
        }
        return duplicate;
    }
    //#region delete
    deleteExpense():void{
        const siblings:Expense[] = store.state.product.expenses
        if(this.parentExpense != undefined){ 
            this.parentExpense._deleteSubExpense(this.uuid);
            return;
        }
        Vue.set(store.state.product,'expenses',siblings.filter(e=>e.uuid!=this.uuid));
    }
    _deleteSubExpense(uuid:string):void{
        if(this.subExpenses == undefined) {
            console.error({expense: this, message: 'deleteSubExpense was called on method with no subExpenses'});
            return;
        }
        const siblings:Expense[] = this.subExpenses
        this.subExpenses = siblings.filter(e=>e.uuid != uuid)

        if(this.subExpenses.length == 0)
            this.changeToCostExpense();
    }
    //#endregion
    equalsTo = (e?:Expense):boolean => {
        if(e == undefined) return false
        return e.uuid == this.uuid;
    }
    getActivationMonth():number{
        if(this.activationMonth != undefined ) return this.activationMonth;
        return this.type == 2 ? 0 : 1;
    }
    toJSON = ():Expense => {
        const match = [{t:RefExpense,s:'ref'},{t:UnitExpense,s:'unit'},{t:CostExpense,s:'cost'}].filter(({t})=>this instanceof t)
        const jType = match.length == 0 ? 'Expense' : match[0].s;
        if(this.activationMonth == undefined) this.activationMonth = this.type == 2 ? 0 : 1;
        return {...this,jType};
    }
    static from(literal:ExpenseLiteral,setCb:(()=>void)[]):Expense{
        let exp:any,subExp:any;
        switch(literal.jType){
            case "cost": return plainToClass(CostExpense,literal);
            case "unit": return plainToClass(UnitExpense,literal);
            case "ref": 
                exp = plainToClass(RefExpense,literal);
                setCb.push(()=>{
                    if(literal.reference==undefined) throw new Error(`literal reference is undefined`);
                    exp.reference = Reference.from(literal.reference);
                });
                return exp;
            case "Expense": 
                exp = new Expense('',0,);
                subExp = literal.subExpenses?.map((s)=> this.from(s,setCb) );
                exp = plainToClass(Expense,literal);
                exp.subExpenses = [];
                subExp?.forEach((s:Expense) => exp.addSubExpense(s));
                return exp
            default: throw new Error(`Unrecognized jsonType: ${literal.jType}\nJSON: ${JSON.stringify(literal,null,2)}`)
        }
    }
    countCost = (totalSales = (channelManager.getTotalSales() | 0) ):number => {
        try{
            if(this instanceof CostExpense) return this.cost;
            else if(this instanceof UnitExpense) return this.amount * this.pricePerUnit;
            else if(this instanceof RefExpense){
                if(!this.reference||!this.reference.to||!this.amount) return 0;
                const ref: number|Expense = this.reference.to;
                if(typeof ref == 'number') return ref/100*this.amount;
                else{ 
                    if([1,2].includes(this.type)){
                        if(ref.type == 0){
                            return ref.countCost(totalSales) * totalSales * (this.amount / 100);
                        }
                        else if ([1,2].includes(ref.type)) {
                            return ref.countCost(totalSales) * (this.amount / 100);
                        }
                        else return 0;
                    }
                    else if(this.unit == '%'){
                        return ref.countCost(totalSales) / 100 * this.amount;
                    }
                    else {
                        console.warn({name:this.name,unit:this.unit,msg:'Unrecognized unit'}); 
                        return 0;
                    }
                }
            }else if(this instanceof Expense && this.subExpenses != undefined){
                if(this.subExpenses.length == 0) return 0;
                const subExpenseCosts = this.subExpenses.reduce((acc:number[],e:Expense)=>[...acc,e.countCost(totalSales)],[])
                return subExpenseCosts.reduce((acc,cur)=>acc+cur);
            }
            else throw new Error("Expense "+this.name+" has insufficient data for counting cost")
        }catch(error){
            const costErrors = store.getters.costErrors;
            costErrors.push({error,expense:this});
            return -1;
        }
    }
    _replaceThisExpense(expense:Expense):void{
        const siblings = productManager.getSiblingsFor(productManager.expenseFromUUID(this.uuid));
        if(this.parentExpense) expense.setParentExpense(this.parentExpense);
        const index = siblings.findIndex((e)=>e.uuid==this.uuid);
        Vue.set(siblings,index,expense);
        expense.uuid = this.uuid;
        expense.setType(this.type);
        expense.isDragged=this.isDragged;
    }
    //#region changeToType methods
    changeToExpense():void{
        const expense = new Expense(this.name,this.type,undefined);
        this._replaceThisExpense(expense);
    }
    changeToCostExpense():void{
        if(this instanceof CostExpense) return;
        const expense = new CostExpense(this.name, this.countCost());
        this._replaceThisExpense(expense);
    }
    changeToUnitExpense():void{
        if(this instanceof UnitExpense) return;
        const expense = new UnitExpense(this.name,1,
            //TODO:? Change to ||
            (this instanceof RefExpense && this.unit) ? this.unit :'', 0);
        this._replaceThisExpense(expense)
    }
    changeToRefExpense():void{
        if(this instanceof RefExpense) return
        const amount = this instanceof UnitExpense ? this.amount:0;
        const unit = '%';
        const reference = store.getters.referencer.getReferences()[0];
        const expense = new RefExpense(this.name, amount, unit, reference);
        this._replaceThisExpense(expense)
    }
    //#endregion
    changeSubExpenses(s:boolean):void{
        if(this.subExpenses==undefined&&!s) return
        if(this.subExpenses!=undefined&&s) return
        const newExpense = s ? new Expense(this.name,this.type,[]) : new CostExpense(this.name,0)
        if(s) newExpense.setType(this.type)
        newExpense.folded = false;
        this._replaceThisExpense(newExpense)
    }
    //#region util
    withSubExpenses = ():Expense[]=>[this,...(this.subExpenses?this.subExpenses:[])]
    globalFlags = ():{useStructure:boolean,useCost:boolean,useUnit:boolean}=>{
        const useStructure = this.type!=-1;
        const useCost = this instanceof CostExpense && this.cost > 0;
        return {useUnit: useCost, useStructure, useCost}
    }
    //#endregion
}
/**
 * Затрата на какой-либо товар или услугу.
 * Стоимость приравнивается к введнной пользователем сумме рублей.
 * В коде указывается, как "costExpense".
 *
 * @see Expense Для подробного описания сущности затраты.
 */
export class CostExpense extends Expense{
    /** Стоимость затраты в рублях.  */
    cost: number
    constructor(name:string,cost:number){
        super(name)
        this.cost = !cost/*cost is undefined/NaN*/ ? 0 : cost
    }
    setCost(cost:number):void{
        //if(typeof cost =='string')cost = parseFloat(cost)
        this.cost = cost
    }
}
/**
 * Затрата на какой-либо товар или услугу.
 * Стоимость рассчитывается по количеству и цене за единицу измерения.
 * В коде указывается, как "unitExpense".
 *
 * @see Expense Для подробного описания сущности затраты.
 */
export class UnitExpense extends Expense{
    /** Количество единиц измерения. */
    amount: number
    /** Название единицы измерения (кг, час, шт, ...). */
    unit: string
    /** Стоимость одной единицы измерения. */
    pricePerUnit: number
    constructor(name:string,amount:number,unit:string,pricePerUnit:number){
        super(name)
        this.amount = amount
        this.unit = unit
        this.pricePerUnit = pricePerUnit
    }
}
/**
 * Затрата на какой-либо товар или услугу.
 * Стоимость данной затраты рассчитывается пропорционально другому показателю или затрате модели.
 * В коде указывается, как "refExpense".
 *
 * @see Expense Для подробного описания сущности затраты.
 */
export class RefExpense extends Expense{
    /** Количество процентов от стоимости указанного показателя или затраты. */
    amount:number
    /** Название единицы измерения (используется в коде, всегда равен "%"). */
    unit:string
    /**
     * Ссылка на другой показатель или затрату.
     *
     * @see NumberReference Ссылка на показатель.
     * @see ExpenseReference Ссылка на затрату.
     * */
    reference:ExpenseReference|NumberReference
    constructor(name:string,amount:number,unit:string,reference:ExpenseReference|NumberReference){
        super(name)
        this.amount = amount
        this.unit = unit
        this.reference = reference
    }
    queryReference():any[]{
        let ret = []
        try{ ret = jp.query(this.reference.data,this.reference.path) }
        catch(e){console.error(e)}
        if(ret.length > 1) console.warn("Query ",this.reference," resulted in ",ret,"(>1 obj) data: ",this.reference.data)
        return ret
    }
}