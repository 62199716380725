

















import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { processResponseError } from '@/common/misc';
import Vue from 'vue'
export default Vue.extend({
    name: 'adminDocsTab',
    data(){
        return {
            docs: [

            ] as {id:number, link:string}[],
        }
    },
    mounted():void{
        this.getDocs();
    },
    methods: {
        async getDocs():Promise<void>{
            try {
                const {docs} = (await ApiService.get(endpoints.adminGetDocs)).data;
                this.docs = docs;
            } catch (error) { alert(processResponseError(error)); }
        },
        async postDocs():Promise<void>{
            try {
                const {docs} = (await ApiService.post(endpoints.adminPostDocs, this.docs)).data;
                this.docs = docs;
            } catch (error) { alert(processResponseError(error)); }
        },
    },
})
