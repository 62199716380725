































































































































import Vue from 'vue';
import TabViewMixin from '@/components/mixins/TabViewMixin.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import NavigationDestination from '@/common/classes/nav.destination';
import HidePasswordBtn from '@/components/elements/HidePasswordBtn.vue';
import FeedbackModal from '@/components/elements/modals/FeedbackModal.vue';
import ImageUploaderModal from '@/components/elements/modals/ImageUploaderModal.vue';
import PromoCodeModal from '@/components/elements/modals/PromoCodeModal.vue';
import { mapGetters } from 'vuex';
import { createClickAnchor, processResponseError } from '@/common/misc';
import TariffList from '@/components/tariffs/TariffList.vue';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import StorageService from '@/common/storage.service';
import { SET_AUTH } from '@/store/mutations';
import { CHECK_AUTH } from '@/store/actions';

const component = Vue.extend({
    name: 'settingsView',
    props: {destination:{type:NavigationDestination}},
    mixins: [MiscMixin,TabViewMixin,ResponsiveDataMixin],
    components: {
        HidePasswordBtn,
        FeedbackModal,
        TariffList,
        ImageUploaderModal,
        PromoCodeModal,
    },
    async mounted(){
        (this as any).onViewMount(component);
        this.switchToTab(this.urlTab);
        if(!this.isAuthenticated && this.activeTab != this.tabs.tariff) this.$router.push('/login');
        this.setInputUsername();
        this.checkUrlPromocode();
    },
    watch: {
        urlTab(newTab){
            this.switchToTab(newTab);
        },
        isAuthenticated(authenticated){
            if(!authenticated){
                this.$router.push('/login');
            }
        },
        isAuthChecked(){ this.setInputUsername(); }
    },
    data(){
        //NOTE: Don't change indexes to settings tabs, because links like /settings?tab=3 are stored in emails & can't be changed
        const tabs = Object.freeze({account:1,password:2,tariff:3,payments:4});
        return{
            tabs,
            activeTab: tabs.account,
            changePass: {
                hidePass: true,
                hideConfirmPass: true,
                password: '',
                passwordConfirm: ''
            },
            inputUsername: '',
        }
    },
    methods: {
        checkUrlPromocode(){
            const {action, promocode} = this.$route.query;
            if(action == 'activate-promocode' && promocode){
                history.pushState({},'',this.$route.path.split("?")[0]);
                this.$modal.show('promo-code-modal', {promocode});
            }
        },
        setInputUsername(){
            if(this.user) this.inputUsername = this.user.username;
            else this.inputUsername = '';
        },
        async submitChangeUsername():Promise<void>{
            try {
                const tokenDto = (await ApiService.put(endpoints.putUpdateUsername, this.inputUsername)).data;
                this.$store.commit(SET_AUTH, tokenDto);
                ApiService.setHeader();
                // location.reload();
            } catch (error) {
                alert(processResponseError(error));
            }
        },
        async onProfilePictureUpload(imageUrl:string):Promise<void>{
            this.$modal.hide('image-uploader-modal');
            if(!imageUrl) return;
            let setPictureResponse;
            try {
                setPictureResponse = await ApiService.post(endpoints.postUserProfilePicture, imageUrl);
            } catch (error) {
                alert( processResponseError(error) ); 
                throw error; 
            }
            this.$set(this.user, 'picture', setPictureResponse.data.picture);
        },
        async changeProfilePicture():Promise<void>{
            this.$modal.show('image-uploader-modal');
        },
        showPromoCodeModal():void{
            this.$modal.show('promo-code-modal');
        },
        tabClick(tab:number):void{
            if(tab == this.tabs.payments) {this.$router.push('/payments'); return;}
            this.$router.push(`${this.$route.path}?tab=${tab}`);
        },
        switchToTab(tab:number){
            if(Object.values(this.tabs).includes(tab)){ this.activeTab = tab;}
        },
        changePasswordSubmit(){
            const {password,passwordConfirm} = this.changePass;
            if(password != passwordConfirm){ alert('Пароли не совпадают'); return; }
            this.changePass.password = '';
            this.changePass.passwordConfirm = '';
            //TODO: Implement
            alert('Not implemented');
        },
    },
    computed: {
        urlTab: {
            get():number{ return parseInt( this.$route.query.tab as any ) },
            cache: false
        },
        disableTooltips: {
            get():boolean{
                return StorageService.getStorageItems().SETTINGS_DISABLE_TOOLTIPS.isTrue();
            },
            set(disableTooltips:boolean):void{
                StorageService.getStorageItems().SETTINGS_DISABLE_TOOLTIPS.setValue(disableTooltips);
            },
            cache: false,
        },
        ...mapGetters(['user','navDestinations','activeNavDestination','activeSubDestination','isAuthenticated','isAuthChecked'])
    }
});
export default component;
