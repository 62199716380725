









import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'tosView',
    computed: {
        rootHeight():number{
            return document.documentElement.clientHeight;
        },
        ...mapGetters(['activeNavDestination']),
    },
})
