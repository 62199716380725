import store from '@/store';
import { ProductTax } from '@/common/types/product.types';
import Vue from 'vue';

const AVG_CHECK_TAX_DEFAULT_RATE = 6,
        CONST_TAX_DEFAULT_RATE = 15;

export const getProductTaxesMap = () => {
    const taxes = {
        noneTax: new ProductTax(
            'Отсутствует',
            '',
            0,
            true,
        ),
        avgCheckTax: new ProductTax(
            'Доходы',
            '',
            AVG_CHECK_TAX_DEFAULT_RATE,
        ),
        constTax: new ProductTax(
            'Доходы - расходы',
            '',
            CONST_TAX_DEFAULT_RATE,
        ),
        fullTax: new ProductTax(
            'ОСНО',
            '',
            0,
            true,
        ),
    }
    for (const key in taxes) (taxes as Record<string,ProductTax>)[key].type = key;
    return Object.freeze(taxes);
}
export const getProductTaxesArr: () => readonly ProductTax[] = () => {
    return Object.values( getProductTaxesMap() );
}

class TaxUtils {
    afterProductIsSet() {
        if(store.state.product.tax == undefined || this.isTaxActive(getProductTaxesMap().noneTax))
            this.setCurrentTax(getProductTaxesMap().avgCheckTax)
    }
    getCurrentTax():ProductTax{
        return store.state.product.tax || getProductTaxesMap().noneTax;
    }
    isTaxActive(tax:ProductTax){
        return this.getCurrentTax().type.toLowerCase() == tax.type.toLowerCase();
    }
    isExpenseTypeRelatedToCurrentTax(expenseType:number):boolean{
        const {avgCheckTax, constTax} = getProductTaxesMap();
        if(this.isTaxActive(avgCheckTax) && expenseType == 0) return true;
        if(this.isTaxActive(constTax) && expenseType == 1) return true;
        return false;
    }
    countTaxCostForExpenseType(expenseType:number|'any', totalEarnings:number, totalProfit:number):number{
        if(expenseType != 'any'){
            if(!this.isExpenseTypeRelatedToCurrentTax(expenseType)) return 0;
            if(expenseType == 0) return this.getUnitTaxCost(totalEarnings);
            if(expenseType == 1) return this.getConstTaxCost(totalProfit);
            console.warn({expenseType, currentTax: this.getCurrentTax(), msg: 'Expense type is realated to current tax, but unit calc is not implemented'});
            return 0;
        }
        return this.getUnitTaxCost(totalEarnings) + this.getConstTaxCost(totalProfit);
    }
    getUnitTaxCost(totalEarnings:number){
        return countUnitConstCost(totalEarnings, AVG_CHECK_TAX_DEFAULT_RATE, getProductTaxesMap().avgCheckTax);
    }
    getConstTaxCost(totalProfit:number){
        return countUnitConstCost(totalProfit, CONST_TAX_DEFAULT_RATE, getProductTaxesMap().constTax);
    }
    setCurrentTax(tax:ProductTax|undefined){
        if(tax == undefined) { 
            console.warn('Unable to set undefined tax as current'); 
            return; 
        }
        if(tax.type == 'noneTax') tax = undefined;
        else {
            const matchTypeTax = getProductTaxesArr().find((t)=> t.type == tax?.type );
            if(matchTypeTax){
                matchTypeTax.rate = tax.rate;
                tax = matchTypeTax;
            }
        }
        Vue.set(store.state.product,'tax',tax);
    }
}

const taxUtils = new TaxUtils();

const countUnitConstCost = (earnings:number, defaultRate:number, tax:ProductTax) => {
    let taxRate = 0;
    if(taxUtils.isTaxActive( tax )) {
        const curRate = taxUtils.getCurrentTax().rate;
        taxRate = curRate != undefined ? curRate : defaultRate;
    }
    const calcTaxRate = (taxRate / 100);
    return Math.max(0, earnings * calcTaxRate);
};

export default taxUtils;