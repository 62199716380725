

























import Vue from 'vue';
import { ResourceSearchData } from '@/common/types/resource.types';
import { ProjectSearchData } from '@/common/types/project.types';
import SearchBottom from '@/components/elements/search/SearchBottom.vue';
import SearchOptions from '@/components/elements/search/SearchOptions.vue';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';

export default Vue.extend({
    name: 'projResSearch',
    data(){
        return{
            showSearchSettings: false,
        }
    },
    mixins: [ResponsiveDataMixin],
    components: {
        SearchOptions,
        SearchBottom,
    },
    props: {
        searchData: {default:()=>({} as (ResourceSearchData|ProjectSearchData))},
        isResource: {type:Boolean},
        sortMode: {type:String},
        showSort: {type:Boolean,default:true},
        categoryCollections: {},
        showSearchBar: {type:Boolean,default:true},
    },
})
