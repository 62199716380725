import store from '@/store';
import { ChannelModel, ProductModel } from './models';


class ChannelManager{
    _getProduct():ProductModel{
        return store.getters.product;
    }
    getChannels():ChannelModel[]{
        return store.getters.channels;
    }
    //get all channels from store, multiply vars & ret
    getAvgSingleSaleCost(){
        //chan1salecost*chan1sales + chan2salecost*chan2sales / 2 / getTotalSales()
        const channels = this.getChannels();
        const totalTargetSales = this.getTotalTargetMonthlySales();
        const avgSingleSaleCost = channels
            .reduce((acc,cur)=>{
                // console.log({acc,name:cur.name,ssCost:cur.singleSaleCost(),target:cur.targetMonthlySales}); 
                return acc+cur.targetMonthlySales * cur.singleSaleCost()
            },0)  / totalTargetSales;
        // console.log({avgSingleSaleCost})
        return avgSingleSaleCost * this._getProduct().clientsPerSalesUnit;
    }
    getAvgCac(){
        const channels = this.getChannels();
        const totalTargetSales = this.getTotalTargetMonthlySales();
        const avgCac = channels
            .reduce((acc,cur)=>acc+cur.targetMonthlySales * cur.cac(),0) / totalTargetSales
        return avgCac;
    }
    getTotalTargetMonthlySales(){
        const channels = this.getChannels();
        return channels
            .reduce((acc,cur)=>acc+cur.targetMonthlySales,0);
    }
    getTotalSales():number{ 
        const channels = this.getChannels();
        const totalSales = channels.reduce((acc,cur)=> acc+cur.totalSales() ,0);
        return totalSales;
    }
    updateTotalTargetMonthlySales(totalTargetMonthlySales:number){
        const channels = this.getChannels()
        channels.forEach((channel) => {
            const targetSales = totalTargetMonthlySales * channel.targetFraction;
            // console.log({targetSales})
            channel.targetMonthlySales = targetSales;
        } );
    }
}
export default new ChannelManager();