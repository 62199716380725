import store from '@/store';
import { ProductModel, ProjectModel } from './classes/models';
import { parseDate } from './misc';
import { ProjectSearchData } from './types/project.types';
import { Resource, ResourceCollection, ResourceSearchData } from './types/resource.types';

//#region searchData and sortModes

export const getDefaultResourceSearchData = () => Object.assign({}, {
    categories: [],
    searchQuery: '',
    type: '-1',
    language: '-1',
    cost: '-1',
    complexity: '-1',
    socialType: '-1',
} as ResourceSearchData);
//these properties are reset to default when options 'reset' button is clicked
export const getDefaultResourceFilters = () => {
    const {type,language,cost,complexity, socialType, searchQuery } = getDefaultResourceSearchData();
    return { type, language, cost, complexity, socialType, searchQuery }
}

export const resourceSearchSortModesMap = {
    views: 'Просмотрам',
    resourceCount: 'Количеству ресурсов',
};
export const resourceSearchSortModes = Object.values( resourceSearchSortModesMap );

export const getDefaultProjectSearchData = () => Object.assign({}, {
    searchQuery: '',
    industry: '-1',
    bizModel: -1,
    channelType: -1,
    monthlyEarnings: -1,
    socialType: '-1',
} as ProjectSearchData);
//these properties are reset to default when options 'reset' button is clicked
export const getDefaultProjectFilters = () => {
    const {bizModel,industry,channelType, monthlyEarnings, socialType, searchQuery} = getDefaultProjectSearchData();
    return { bizModel, industry, channelType, monthlyEarnings, socialType, searchQuery };
}

export const projectSearchSortModesMap = {
    views: 'Просмотрам',
    descUpdated: 'Дате изменений',
};
export const projectSearchSortModes = Object.values( projectSearchSortModesMap );

//#endregion

//#region searchPredicates

const isParamSpecified = (s:string):boolean=>{
    if(s == undefined) return false;
    if(typeof s != 'string') s = s+'';
    return s.trim().length > 0 && s != 'any' && s != '-1';
};

const loopCheckIfFieldsMatch = (checkFields:Record<string,number|string>,obj:any):boolean=>{
    const fieldNames = Object.keys( checkFields );

    for (let i = 0; i < fieldNames.length; i++) {
        const key = fieldNames[i];
        const val = (checkFields as any)[key];
        const curVal = obj[key];
        if(!isParamSpecified(val)) continue;
        const typesMatch = typeof curVal == typeof val;
        if(typesMatch && obj[key] != val) return false;
        else if(typeof val == 'string' && typeof curVal == 'number' && parseFloat(val) != curVal) return false;
        else if(typeof val == 'number' && typeof curVal == 'string' && val+'' != curVal) return false;
    }
    return true;
}

//TODO: (?) use stringSimilarity/other string search lib
const checkIfNameMatches = (objName:string,searchQuery:string):boolean=>{
    if(!isParamSpecified(searchQuery)) return true;
    return objName.toLowerCase().includes(searchQuery.toLowerCase());
};

const checkSocialType = (obj:{favoriteIds:string[],likedIds:string[],watchedIds:string[]},socialType:string)=>{
    if(!isParamSpecified(socialType)) return true;
    const curUser:any = store.state.user;
    if(!curUser) return false;
    const userId = curUser.id;
    if(socialType == '0' && !obj.favoriteIds.includes(userId)) return false;
    if(socialType == '1' && obj.watchedIds.includes(userId)) return false;
    if(socialType == '2' && !obj.watchedIds.includes(userId)) return false;
    return true;
}

export function resourceSearchPredicate(resource:Resource,searchData:ResourceSearchData):boolean{
    const {complexity,cost,language,type,socialType,searchQuery} = searchData;

    const checkFields = {complexity,cost,language,type};

    if(!checkSocialType(resource,socialType)) return false;
    if(!loopCheckIfFieldsMatch(checkFields,resource)) return false;

    return checkIfNameMatches(resource.description+' '+resource.name,searchQuery);
}
export function resourceCollectionSearchPredicate(resourceCollection:ResourceCollection,searchData:ResourceSearchData):boolean{
    if(!searchData.categories.find((c)=>c.id == resourceCollection.category.id)) 
        return false;
    const matchingResources = resourceCollection.resourceList.filter((res)=>resourceSearchPredicate(res,searchData));
    const anyMatchingResources = matchingResources.length > 0;

    return anyMatchingResources;
}

//FIXME: [LOW PRIOR] Not DRY calculation of a project earnings. Probably need to add optional params in ChartMethods
const checkProjectEarnings = (project:ProjectModel, searchEarnings:number) => {
    try {
        if(searchEarnings < 0) return true;
        const {product, channels} = project;
        const {avgCheck, clientsPerSalesUnit} = product;
        const apc = ProductModel.calcApcFromRetention(product.retention);
        const totalTargetMonthlySales = channels.reduce((acc,cur)=>acc+cur.targetMonthlySales, 0);
        const earnings = Math.round( avgCheck * clientsPerSalesUnit * apc * totalTargetMonthlySales );
        return earnings <= searchEarnings;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function projectSearchPredicate(project:ProjectModel,searchData:ProjectSearchData):boolean{
    const {bizModel,industry,channelType,monthlyEarnings,searchQuery,socialType} = searchData;

    const earningsCheck = checkProjectEarnings(project, monthlyEarnings);
    const loopFieldCheck = loopCheckIfFieldsMatch({ bizModel, industry },project.product);
    const matchesTypes = !isParamSpecified(channelType+'') || project.channels.map(({channelType})=>channelType).includes(searchData.channelType);
    const passedSocialTypeCheck = checkSocialType(project.data,socialType);

    const projectChecks = [earningsCheck, loopFieldCheck, matchesTypes, passedSocialTypeCheck];
    if(projectChecks.includes(false)) return false;

    const isNameMatching = checkIfNameMatches(project.data.description+' '+project.data.name,searchQuery);
    return isNameMatching;
}

export function isAnySpecifiedFields(obj:Record<string,any>):boolean{
    if(typeof obj != 'object') return false;
    return Object.values( obj ).find((v)=>isParamSpecified(v+'')) != undefined;
}

export function sortFunForMode(mode:string):(a:ProjectModel,b:ProjectModel)=>number{
    switch(mode){
        case projectSearchSortModesMap.views: return (a,b)=>b.data.watchedIds.length - a.data.watchedIds.length;
        case projectSearchSortModesMap.descUpdated: return (a,b)=>{
            const parseGetTime = (s:ProjectModel)=>parseDate( s.data.updated ).getTime();
            return parseGetTime(b) - parseGetTime(a);
        };
        default: {
            console.warn({msg:'sortMode is not implemented',mode}); 
            return ()=>0;
        }
    }
}
//#endregion