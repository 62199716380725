//THIS MUST MATCH THE ID FROM index.html
const APP_ID = 66965077;

class YMGoal {
    id='';
}

const YandexMetrikaSerivice = {
    getGoals(){
        const goals = {
            CHANGE_TARIFF_BTN_CLICK: new YMGoal(),
            USER_MAIL_VERIFICATION: new YMGoal(),
        };
        Object.keys( goals ).forEach((k)=>{
            const v:YMGoal = (goals as any)[k];
            v.id = k;
        });
        return goals;
    },
    _ym(...data:any){
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-undef
        return ym(...data);
    },
    reachGoal(goal:YMGoal){
        this._ym(APP_ID,'reachGoal',goal.id)
    },
};

export default YandexMetrikaSerivice;

