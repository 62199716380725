








import Vue from 'vue';
import SelectElMixin from '@/components/mixins/SelectElMixin.vue';
import { getBizModels } from '@/common/config';

export default Vue.extend({
    name: 'bizModelSelect',
    mixins: [SelectElMixin],
    props: {
        flags: { type: Array||undefined },
        modelData: {},
    },
    computed: {
        bizModelNames():string[]{
            return getBizModels().map(({name})=>name);
        },
    }
})
