























import Vue from 'vue';
import { mapGetters } from 'vuex';
import MiscMixin from '@/components/mixins/MiscMixin.vue';

const msgKey = Object.freeze( 'displayed-new-user-constructor-msg' );
const displayedValue = Object.freeze( 'true' );
export function showedNewUserConstructorMessage():string|null{
    //TODO: Use StorageService
    if( typeof Storage === 'undefined' || !localStorage ) return null;
    return localStorage.getItem(msgKey);
}

export default Vue.extend({
    name: 'newUserConstructorMessage',
    mounted(){
        if( this.showMessage ) return;

        //TODO: Use StorageService
        if( typeof Storage === 'undefined' || !localStorage ) return;

        const {storageMsgValue:item} = this;
        if( item && item == displayedValue ) return;

        const isMobile = (this as any).isMobile;
        this.showMessage = !this.isAuthenticated && !isMobile;
        if(!isMobile) this.$emit('show-wizard-overlay');
        if (isMobile && !this.isAuthenticated) {
            const redirLogIn = confirm("Похоже, что вы не авторизованы, все изменения в этом проекте будут потеряны. Хотите войти или зарегистрироваться?")
            if (redirLogIn) this.$router.push('/login')
        }
        localStorage.setItem(msgKey,displayedValue);
    },
    mixins: [MiscMixin],
    data(){
        return{ 
            showMessage: false,
        }
    },
    computed: {
        storageMsgValue():string|null{
            return showedNewUserConstructorMessage();
        },
        ...mapGetters(['isAuthenticated']),
    },
})
