















import { endpoints } from '@/common/config';
import { downloadFile } from '@/common/misc';
import Vue from 'vue'
export default Vue.extend({
    name: 'reportsTab',
    data(){
        return{ 
            isFetching: false
        }
    },
    methods: {
        async reportClick(reportType:'users'|'projects'|'actions'|'landing_requests'){
            let url:string;
            switch(reportType){
                case 'users': url = endpoints.adminGetUserReport; break;
                case 'projects': url = endpoints.adminGetProjectReport; break;
                case 'actions': url = endpoints.adminGetActionsReport; break;
                case 'landing_requests': url = endpoints.adminGetLandingRequestsReport; break;
            }
            this.isFetching = true;
            try{
                const {data} = await this.axios({
                    url,
                    method: 'get',
                    responseType: 'blob',
                });
                const fileName = `${reportType} `+new Date()
                    .toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'})
                    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
                downloadFile(data,fileName+'.csv','text/csv');
            }finally{ this.isFetching = false; }
        }
    }
})
