/* eslint-disable @typescript-eslint/no-use-before-define */

import {ReferenceLiteral } from '../interfaces';
import { Expense, RefExpense } from './expenses';
import { jsonCycler, smartToFixed } from '@/common/misc';
import jp from 'jsonpath';
import store from '@/store';
import { SET_COST_ERRORS } from '@/store/mutations';
import { ChannelModel, ProductModel } from './models';
import channelManager from './channel.manager';

export class Referencer{
    getProduct():ProductModel{
        return store.getters.product;
    }
    getChannels():ChannelModel[]{
        return store.getters.channels;
    }
    getNumberReferences():NumberReference[]{
        return [
            new NumberReference(this.getProduct().avgCheck,this.getProduct(),'$["avgCheck"]','Средний чек'),
            //new NumberReference(this.channel.cac,this.channel,'$["cac"]','CAC'),
            //new NumberReference(this.channel.targetMonthlySales,this.channel,'$["targetMonthlySales"]','Целевое кол-во продаж за месяц')
        ];
    }
    getReferences():(ExpenseReference|NumberReference)[]{
        const ret = this.getNumberReferences() as (ExpenseReference|NumberReference)[];
        ret.push( ...this.getProduct().expenses.map( (e)=> new ExpenseReference(e,this.getProduct()) ) );
        return ret;
    }
    findReferenceByPath(path:string):(ExpenseReference|NumberReference)[]{
        const filtered = this.getReferences().filter((r)=>r.path == path);
        if(filtered.length == 0) console.trace({msg:"Unable to find any references by path",path,references:this.getReferences()})
        return filtered;
    }
    findReferenceByExpense(expense:Expense){
        const filtered = this.getReferences().filter((r)=>r instanceof ExpenseReference && r.to.equalsTo(expense));
        if(filtered.length == 0) console.trace({msg:"Unable to find any references by expense",expense,references:this.getReferences()})
        return filtered;
    }
    allSafeReferences(expense:RefExpense):(NumberReference|ExpenseReference)[]{
        return [...this.getNumberReferences(),...this.safeExpenseReferences(expense)];
    }
    safeExpenseReferences(expense:RefExpense):ExpenseReference[]{
        const filterReferencesToThis = (a:Expense[],recursive=false)=>a.filter((e:Expense)=>{
                try{
                    if(!(e instanceof RefExpense)) return false;
                    const ref = e.reference
                    if(ref instanceof NumberReference) return false;
                    if(ref == undefined) return false;
                    if(!(ref instanceof ExpenseReference)){
                        // const j = (o:any)=>JSON.stringify(o,jsonCycler());
                        // if((window as any).webpackHotUpdate) 
                        //     console.warn({msg:'Unrecognized reference',cur:j(expense),ref:j(ref),e:j(e)}); 
                        return false;
                    }
                    //throw new Error(JSON.stringify(ref,jsonCycler(),2)+" is not a reference to expense or number")
                    let areEqual = ref.to.equalsTo(expense)
                    if(recursive && ref.to instanceof RefExpense && (ref.to.reference instanceof ExpenseReference) && filterReferencesToThis([ref.to],true).length > 0) areEqual = true
                    if(ref.to.subExpenses!=undefined){ 
                        ref.to.subExpenses.forEach((se)=>{
                            if(areEqual) return;
                            const recursReferenced = recursive && 
                                (se instanceof RefExpense) && 
                                filterReferencesToThis(se.subExpenses==undefined?[se]:[se,...se.subExpenses],true).length > 0
                            if(se.equalsTo(expense)||recursReferenced) areEqual = true;
                        })
                    }
                    return areEqual
                }catch(error){
                    store.commit(SET_COST_ERRORS,[...store.getters.costErrors,{error,expense:e}]);
                    return true;
                }
            })
        const possibleReferences = ( 
                this.getReferences()
                .filter((r)=>r instanceof ExpenseReference) as ExpenseReference[]
            )
            .filter((r: ExpenseReference)=>{
                //TODO: Move to outer function and add a filter layer
                // const monthCount = expense instanceof RefExpense && [1,2].includes( expense.type );
                const thisExpense = expense.parentExpense||expense;
                const toExpense = r.to.parentExpense||r.to;
                if( toExpense.equalsTo(thisExpense) || toExpense.type == -1 ) return false;
                //only unit for month expenses
                // if( monthCount && toExpense.type != 0 ) return false;
                const foundRecursiveReferences = !!filterReferencesToThis(r.to.withSubExpenses(),true).length;
                if(foundRecursiveReferences) return false;
                return true;
            });
        return possibleReferences;
    }
}
/**
 * Абстрактный класс ссылки на другой показатель или затрату.
 * Используется в {@link ReferenceExpense}.
 *
 * @see NumberReference Ссылка на показатель.
 * @see ExpenseReference Ссылка на затрату.
 * */
export class Reference{
    /** Путь к показателю или затрате в формате <a href="https://github.com/ashphy/jsonpath-online-evaluator">JSONPath</a>. */
    path:string
    /** Объект, внутри которого находится ссылаемый показатель или затрата. */
    data:ProductModel|ChannelModel
    constructor(path:string,data:ProductModel|ChannelModel){
        this.path = path
        this.data = data
    }
    toJSON = ():Reference=>{
        const match = [{t:ExpenseReference,s:'expRef'},{t:NumberReference,s:'numRef'}].filter(({t})=>this instanceof t)
        const cloned = Object.assign({},this);
        delete (cloned as any).data;
        delete (cloned as any).to;
        return {...cloned,jType:match[0].s,jData: 'product'};
    }
    static warnQuery(obj:any,path:string){
        const ret = jp.query(obj,path)
        if(ret.length != 1){ 
            console.warn({msg:`path query returned ${ret.length} results`,obj,path})
        }
        return ret
    }
    static from(literal:ReferenceLiteral):ExpenseReference|NumberReference{
        const jDataMap = [/*{s:'channel',d:store.getters.channel},*/{s:'product',d:store.getters.product}].filter((d)=>literal.jData==d.s)
        if(jDataMap.length == 0) throw new Error(`jData ${literal.jData} is not recognized`);
        //Used to check queries
        const refTo = this.warnQuery(jDataMap[0].d,literal.path);
        const references = store.getters.referencer.getReferences();
        switch(literal.jType){
            case "expRef": return references.filter((r:ExpenseReference)=>r.path == literal.path)[0];
            case "numRef": return references.filter((r:NumberReference)=>r.path == literal.path)[0];
            default: throw new Error(`Unrecognized jType ${literal.jType}\nJSON: ${JSON.stringify(literal,null,2)}`);
        }
    }
}
/**
 * Ссылка на другую затрату модели.
 * Используется в {@link ReferenceExpense}.
 *
 * @see Reference Для подробного описания сущности ссылки.
 * */
export class ExpenseReference extends Reference{
    /** Затрата, к которой ссылается данный класс. */
    to:Expense
    constructor(to:Expense,data:ProductModel|ChannelModel){
        const expenseQuery = `$.expenses[?(@.uuid=="${to.uuid}")]`
        const paths = jp.paths(data,expenseQuery);
        if(paths.length != 1) 
            console.error({msg:`Query ${expenseQuery} returned ${paths.length} results`,data,paths})
        super(expenseQuery,data);
        this.to = to;
    }
    getReferenceName():string{
        const toCost = Math.round(this.to.countCost());
        //FIXME: [LOW PRIOR] toCostMonth is different from actual 100% cost in expenses, where clientsPerSalesUnits % totalTargetMonthlyClients != 0
        const toCostMonth = toCost * channelManager.getTotalSales();
        const showMonthCost = this.to.type == 0;
        return `${this.to.name} (${smartToFixed(toCost)}${(showMonthCost ? ` за ед | ~${smartToFixed(toCostMonth)} в мес`:'')})`
    }
}

/**
 * Ссылка на другой показатель модели.
 * Используется в {@link ReferenceExpense}.
 *
 * @see Reference Для подробного описания сущности ссылки.
 * */
export class NumberReference extends Reference{
    /** Отображаемое название показателя, к которому ссылается данный класс. */
    displayName:string
    /** Последнее значения показателя, к которому ссылается данный класс (?не используется). */
    to:number
    constructor(to:number,data:ProductModel|ChannelModel,path:string,displayName:string){
        super(path,data);
        this.to = to;
        this.displayName = displayName;
    }
    validate():void{
        let query;
        //Catch error from jp.query
        try{
            query = jp.query(this.data,this.path)
            if(query.length!=1) throw new Error('path query returned '+query.length+' results');
        }catch(e){
            console.error({'error':e,to:this.to,data:this.data,path:this.path,query})
            throw new Error('path is invalid');
        }
    }
    getReferenceName():string{
        return `${this.displayName} (${smartToFixed(this.to,2)})`
    }
}