


























import Vue from 'vue';
import ResourceCollectionCard from '@/components/resources-tab/ResourceCollectionCard.vue';
import { Resource, ResourceCollection, ResourceSearchData, utils } from '@/common/types/resource.types';
import { resourceCollectionSearchPredicate, resourceSearchPredicate } from '@/common/search.utils';
import { maxResourcesInsideModal } from '@/common/config';
import { resourceSearchSortModesMap } from '@/common/search.utils';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';

export default Vue.extend({
    name: 'resourceCollectionQueryTable',
    components: {
        ResourceCollectionCard,
    },
    mixins: [ResponsiveDataMixin],
    props: {
        sortMode:{type:String},
        searchData:{},
        resourceCollections:{},
    },
    computed: {
        maxResourcesInsideModal():number{
            return maxResourcesInsideModal;
        },
        displayCollections():ResourceCollection[]{
            const filtered = this.collectionsArray.filter((res)=>resourceCollectionSearchPredicate(res,this.searchData as ResourceSearchData));
            const {views,resourceCount} = resourceSearchSortModesMap;
            let sortFun:(a:ResourceCollection,b:ResourceCollection)=>number;
            const countColViews = (col:ResourceCollection)=> utils.countResourceCollectionWatched(col);
            const filterRes = (resArr:Resource[])=>resArr.filter((r:Resource)=>resourceSearchPredicate(r,this.searchData as ResourceSearchData));
            switch(this.sortMode){
                case views: sortFun = (a,b)=>countColViews(b) - countColViews(a); break;
                case resourceCount: sortFun = (a,b)=>filterRes( b.resourceList ).length - filterRes( a.resourceList ).length; break;
                default: throw new Error("Sort mode "+this.sortMode+' is not implemented');
            }
            return filtered.sort(sortFun);
        },
        resourcesInsideDisplayCollections():Resource[]{
            return this.displayCollections.flatMap(({resourceList})=>resourceList);
        },
        collectionsArray():ResourceCollection[]{
            const spread = Object.values( this.resourceCollections as Record<string,Array<ResourceCollection>> );
            return ([] as ResourceCollection[]).concat( ...spread );
        },
    },
})
