











































import Vue from 'vue';
import NavigationDestination from '@/common/classes/nav.destination';
import {getAuthOnlyFeatureDialogObj, supportCards} from '@/common/config';
import {SupportCard, SupportModalReason} from '@/common/types/support.type';
import TabViewMixin from '@/components/mixins/TabViewMixin.vue';
import FeedbackModal from '@/components/elements/modals/FeedbackModal.vue';
import { mapGetters } from 'vuex';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';

const component = Vue.extend({
    name: 'supportView',
    components: {FeedbackModal},
    props: {destination:{type:NavigationDestination}},
    mixins: [TabViewMixin, MiscMixin, ResponsiveDataMixin],
    async mounted(){
        (this as any).onViewMount(component);
        const { showModal, reason } = this.$route.query;
        //TODO: (?) save value, change modal title, reset value on modal close
        history.pushState({},'',this.$route.path.split("?")[0]);
        // (reason as SupportModalReason)
        if(showModal == '1'){
            for (let i = 0; i < 10; i++) 
                if(!this.$refs.feedbackModal)
                    await new Promise((r)=>setTimeout(r,50));
                else 
                    break

            this.$modal.show('feedback-modal',{reason})
        }
    },
    data(){
        return {
            activeCard: undefined as (undefined|SupportCard),
            reason: '',
            mobileVerticalPromise: undefined as Promise<void>|undefined,
        }
    },
    methods: {
        cardClick(card:SupportCard){
            this.activeCard = this.activeCard==card ? undefined : card
        },
        openFeedbackModal(this:any){
            if(!this.isAuthenticated){
                const dialogObj = getAuthOnlyFeatureDialogObj(()=>this.$modal.hide('dialog'));
                this.$modal.show('dialog', dialogObj);
                return;
            }
            if(this.isMobileWidth && this.isHorizontalOrientation) {
                this.$modal.show('dialog', {
                    title: 'Поверните экран',
                    text: 'Для отправки сообщения команде izibiz необходимо повернуть устройство в вертикальное положение',
                    buttons: [
                        {title:'Продолжить', handler: () => { this.$modal.hide('dialog'); }},
                    ]
                })
                if(!this.mobileVerticalPromise) {
                    const verticalPromise:Promise<void> = this.getOrientationChangePromise(true);
                    this.mobileVerticalPromise = verticalPromise;
                    verticalPromise.then(() => {
                        this.mobileVerticalPromise = undefined;
                        this.$modal.hide('dialog');
                        this.$modal.show('feedback-modal');
                    });
                }
                return;
            }
            this.$modal.show('feedback-modal');
        },
    },
    computed: {
        supportCards(){
            return supportCards;
        },
        openFeedbackModalTooltip: {
            get(this:any): string|undefined{
                if(!this.isAuthenticated) return this.tltpText('featureRequiresAuthentication');
                // if(this.isMobileWidth && this.isHorizontalOrientation) return this.tltpText('featureRequiresVerticalOrientation');
                return undefined;
            },
            cache: false
        },
        ...mapGetters(['navDestinations','activeNavDestination','activeSubDestination'])
    }
})
export default component;
