





























import Vue from 'vue';
import { projectSearchSortModes, resourceSearchSortModes } from '@/common/search.utils';
import { ResourceCategory, ResourceSearchData } from '@/common/types/resource.types';
import { mapGetters } from 'vuex';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';

//TODO: Model parent obj & change active categories, sort mode.
//emit on suggest
export default Vue.extend({
    name: 'searchBottom',
    mixins: [ResponsiveDataMixin],
    props: {
        isResource:{type:Boolean,default:false},
        sortMode:{type:String},
        categoryCollections:{type:Array,default(){return [] as any;}},
        modelData:{},
    },
    methods: {
        isCategoryActive(category:ResourceCategory){
            return this.searchCategories.find((c)=>c.id==category.id);
        },
        categoryClick(category:ResourceCategory){
            const index = this.searchCategories.findIndex((c)=>c.id == category.id);
            if(index!=-1) this.searchCategories.splice(index,1);
            else this.searchCategories.push(category);
        },
        allCategoriesClick(){
            let categories:ResourceCategory[];
            if(this.isAllCategoriesSelected) categories = [];
            else categories = this.allCategories;
            this.$set(this.searchData,'categories',categories.map((category)=>Object.assign({},category)));
        },
    },
    computed: {
        isAllCategoriesSelected():boolean{
            return this.allCategories.length == this.searchCategories.length;
        },
        allSortModes():string[]{
            return this.isResource ? resourceSearchSortModes : projectSearchSortModes;
        },
        allCategories():ResourceCategory[]{
            return this.categoryCollections.map((cc:any)=>cc.category);
        },
        searchCategories():ResourceCategory[]{
            return this.searchData.categories;
        },
        searchData():ResourceSearchData{
            return this.modelData as ResourceSearchData;
        },
        ...mapGetters(['isAuthenticated']),
    }
})
