









import Vue from 'vue';
import Resource from '@/components/resources-tab/Resource.vue';
export default Vue.extend({
    name: 'resourceTable',
    components: { Resource },
    props: {
        resourceCollections: {},
        curResourceCollection: {}
    },
})
