


































































































































import Vue from 'vue';
import ResourceFieldSelect from '@/components/resources-tab/ResourceFieldSelect.vue';
import { Resource, ResourceCategory, ResourceCollection } from '@/common/types/resource.types';
import UploadImageMixin from '@/components/mixins/UploadImageMixin.vue';

type FormTexts = {
    submitBtn: string,
};

export default Vue.extend({
    name: 'resourceEditForm',
    mounted():void{
        this.editObjClone = Object.assign({},this.editObj);
        this.parentCollection = this.resourceParentCollection;
        this.filterCategory = this.parentCollection?.category;
    },
    mixins: [UploadImageMixin],
    components: {
        ResourceFieldSelect,
    },
    props: {
        directEdit: {type:Boolean, default: false},
        editObj: {type:Object},
        formType: {type:String, validator: (v)=>['suggest','admin','admin-suggest'].includes(v) },
        resourceCategories: {type:Array},
        isEmbedded: {type:Boolean, default: false},
        resourceCollections: {default:()=>[] as ResourceCollection[]},
        resourceParentCollection: {default:undefined as ResourceCollection|undefined},
    },
    data(){
        return{
            editObjClone: undefined as any,
            parentCollection: undefined as (undefined|ResourceCollection),
            filterCategory: undefined as (undefined|ResourceCategory),
            isUploadingImage: false,
        }
    },
    watch: {
        parentCollection(newCollection){ if(newCollection != undefined) this.$emit('resource-parent-collection-change',newCollection); },
    },
    methods: {
        async selectUploadResourceImage(this:any){
            let selectedImage:Record<string,any>|undefined = undefined;
            try {
                console.log('awaiting selectImagePromise')
                selectedImage = await this.selectImagePromise();
                console.log('selected image')
            } catch (error) {
                alert('При выборе изображения произошла ошибка.' + error);
            }

            this.isUploadingImage = true;
            try {
                console.log({selectedImage})
                const imageUrl = await this.selectImageForUpload(selectedImage);
                if (!imageUrl) throw new Error("Ссылка на изображение не была сформирована");
                this.$set( this.activeResource, 'image', imageUrl );
            } catch (error) {
                console.error(error);
                alert("При загрузке изображения произошла ошибка. "+error);
            } finally {
                this.isUploadingImage = false;
            }
        },
        deleteResource(index:number){
            const {resourceList} = this.activeResource as ResourceCollection;
            this.$set( this.activeResource, 'resourceList', resourceList.filter((r,i)=>i!=index));
        },
        isResourceCollection(resource:any){
            return resource && typeof resource == 'object' && Array.isArray( resource.resourceList );
        },
        saveActiveResourceChanges(){
            this.$emit('object-save');
        },
        activeResourceCategoryChange(e:any){
            const lookForText = e.target.value;
            (this.activeResource as any).category = this.resourceCategories.find((rc:any)=>rc.text == lookForText);
        },
        addNewResourceToList(){
            (this.activeResource as ResourceCollection).resourceList.push({
                name: '',
            } as Resource);
        },
    },
    computed: {
        categoryResourceCollections():ResourceCollection[]{
            const curCategoryId = this.filterCategory?.id;
            return this.resourceCollections.filter(({category})=>curCategoryId!=undefined ? category.id==curCategoryId : true);
        },
        elementsText():FormTexts{
            let formsText:FormTexts = {} as any;
            if(this.isAdmin)
                formsText = {
                    submitBtn: 'Сохранить изменения',
                }
            else if(this.isSuggest)
                formsText = {
                    submitBtn: 'Предложить ресурс',
                }
            else if(this.isAdminSuggest)
                formsText = {
                    submitBtn: 'Опубликовать ресурс',
                }
            return formsText;
        },
        activeResource: {
            get(): (Resource|ResourceCollection) {
                return this.directEdit ? this.editObj : this.editObjClone;
            },
            set(value:Resource|ResourceCollection): void {
                if(this.directEdit) this.$emit('object-edit',value);
                else this.editObjClone = value;
            },
        },
        isAdmin():boolean{ return this.formType == 'admin'; },
        isSuggest():boolean{ return this.formType == 'suggest'; },
        isAdminSuggest():boolean{ return this.formType == 'admin-suggest'; },
        activeResourceIsPresent():boolean{
            return this.activeResource && Object.keys( this.activeResource ).length!=0;
        },
        //?Find a better way of checking type
        isEditingResource():boolean{
            return this.activeResourceIsPresent && !this.isEditingResourceCollection;
        },
        //?Find a better way of checking type
        isEditingResourceCollection():boolean{
            return this.activeResourceIsPresent && this.isResourceCollection(this.activeResource);
        },
    }
})
