




















import { putToClipboard } from '@/common/misc';
import { Resource as ResourceType, ResourceCollection } from '@/common/types/resource.types';
import Resource from '@/components/resources-tab/Resource.vue';
import Vue from 'vue'
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'resourceCollectionContents',
    props: {
        resources: {default: [] as ResourceType[]},
        currentCollection: {default: undefined as (undefined|ResourceCollection)}
    },
    components: { Resource, },
    methods: {
        hideModal():void{ this.$modal.hide('resources-modal'); },
        shareResource():void{
            (this.$refs.shareBtn as HTMLElement).focus();
            putToClipboard(`https://izibiz.club/rid/${this.currentCollection.rid}`);
        },
    },
    computed: {
        maxHeight():number{
            return window.innerHeight - 125;
        },
        ...mapGetters(['isAuthenticated'])
    }
})
