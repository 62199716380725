








































































import Vue from 'vue';
import { Expense } from '@/common/classes/expenses';
import Switches from 'vue-switches';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { mapGetters } from 'vuex';
import { ChannelModel } from '@/common/classes/models';
import BudgetSeasonsModal from '../constructor-budget-tab/BudgetSeasonsModal.vue';

export default Vue.extend({
    name: 'projectSwitches',
    data(){
        return {
            inputDump: {
                costSwitch: true,
                structSwitch: true,
            },
        }
    },
    mixins: [MiscMixin],
    props: {
        currentTab:{},
        tabs:{},
    },
    components: {
        Switches,
        BudgetSeasonsModal,
    },
    watch: {
        async inputStructSwitch(newValue:boolean):Promise<void>{
            await this.handleSwitch(newValue,false);
        },
        async inputCostSwitch(newValue:boolean):Promise<void>{
            await this.handleSwitch(newValue,true);
        },
        useCost(newValue:boolean):void{
            if(this.inputDump.costSwitch!=newValue)
            this.$set( this.inputDump, 'costSwitch', newValue );
            if(!this.isLoadingProject && newValue){ 
                this.$set( this.product, 'useUnit', true );
                this._globalSwitch('cost'); 
            }
        },
    },
    methods: {
        async handleSwitch(val:boolean,isCost:boolean){
            const setUseFlags = (v:boolean) => {
                if(isCost){
                    this.$set( this.product, 'useCost', v);
                    this.$set( this.product, 'useUnit', v);
                }else this.$set( this.product, 'useStructure', v)
            }
            if(!val){
                const hideDialog = () => this.$modal.hide('dialog')
                const isSkipping = this.isTesting || this.productExpenses.length == 0;
                const dialogConfirm = new Promise<void>((res,rej)=>{
                    if(isSkipping) {res(); return;}
                    this.$modal.show('dialog',{
                        title: 'Вы уверены, что хотите продолжить?',
                        text: 'Детали расчетов будут потеряны.',
                        buttons: [
                                {title:'Да',handler:res},
                                {title:'Нет',handler:rej}
                            ]
                    });
                });
                try{ //continue
                    await dialogConfirm;
                    setUseFlags(false);
                }
                catch(e){ //cancel
                    this.$set(this.inputDump,isCost ? 'costSwitch' : 'structSwitch', true);
                }
                if(!isSkipping) hideDialog();
                return;
            }else setUseFlags(true);
        },
        _globalSwitch(to:string):void{
            const switchExpenses = (this.product.expenses as Expense[])
                .flatMap((e)=>e.withSubExpenses())
                .filter((e)=>e.subExpenses==undefined)
            switch(to){
                case "cost": switchExpenses.forEach((e:Expense)=> e.changeToCostExpense()); return;
                default: console.warn(`Unrecognized to argument, switch to ${to}`); return;
            }
        },
    },
    computed: {
        inputCostSwitch():boolean{ return this.inputDump.costSwitch; },
        inputStructSwitch():boolean{ return this.inputDump.structSwitch; },
        productExpenses():Array<Expense>{ return this.product.expenses },
        useCost():boolean{
            return this.product.useCost;
        },
        switchCost: {
            get():boolean{
                return this.channels[0].useCost;
            },
            set(val:boolean):void{ this.channels.forEach((channel:ChannelModel)=>this.$set(channel,'useCost',val)); }
        },
        switchConversion: {
            get():boolean{
                return this.channels[0].useConversion;
            },
            set(val:boolean):void{ this.channels.forEach((channel:ChannelModel)=>this.$set(channel,'useConversion',val)); }
        },
        ...mapGetters(['isLoadingProject','product','isTesting','channels']),
    },
})
