






























import Vue from 'vue';
import { wizardSlides } from '@/common/config';
import { WizardSlide } from '@/common/types/wizard.type';
import { createClickAnchor, getLinkToFeedback } from '@/common/misc';

export default Vue.extend({
    name: 'wizardOverlay',
    data(){
        return{
            currentSlide: wizardSlides[0],
        }
    },
    methods: {
        requestConsultation(slide:WizardSlide){
            createClickAnchor(getLinkToFeedback('request-consultation'),(el)=>el.target='_blank');
        },
        openVideo(slide:WizardSlide){
            createClickAnchor(slide.video,(el)=>el.target='_blank');
        },
    },
    computed: {
        curSlideIndex():number{
            const index = wizardSlides.indexOf(this.currentSlide);
            if(index == -1 ) throw new Error("Unable to determine currentSlideIndex");
            return index;
        },
        hasPrevSlide():boolean{ return !!this.prevSlide; },
        prevSlide():WizardSlide|undefined{ return this.allSlides[this.curSlideIndex-1]; },
        hasNextSlide():boolean{ return !!this.nextSlide; },
        nextSlide():WizardSlide|undefined{ return this.allSlides[this.curSlideIndex+1]; },
        allSlides(){ return wizardSlides; },
    },
})
