import { Unit } from './unit';
import { CostExpense, Expense, RefExpense, UnitExpense } from './expenses';
import store from '@/store';
import {SET_UNITS} from '@/store/mutations';

//FIXME: Throws error, seems like project can send Object unit instead of string
//2021-01-23 09:08:48.505  WARN 8035 --- [https-jsse-nio-443-exec-1] .w.s.m.s.DefaultHandlerExceptionResolver : Resolved [org.springframework.http.converter.HttpMessageNotReadableException: JSON parse error: Cannot deserialize instance of `java.lang.String` out of START_OBJECT token; nested exception is com.fasterxml.jackson.databind.exc.MismatchedInputException: Cannot deserialize instance of `java.lang.String` out of START_OBJECT token
// at [Source: (PushbackInputStream); line: 1, column: 2269] (through reference chain: com.example.system.entities.unitproject.Project["product"]->com.example.system.entities.unitproject.Product["expenses"]->java.util.ArrayList[8]->com.example.system.entities.unitproject.expenses.UnitExpense["unit"])]

export const defExpTypes = [-1,0,1,2];
export const defaultUnits = [
    new Unit('мес',[1,2]),
    new Unit('%',defExpTypes),
    new Unit('час',defExpTypes),
    new Unit('шт',defExpTypes),
    new Unit('чел',defExpTypes),
    new Unit('кг',defExpTypes),
];

class UnitManager{
    static refUnits = Object.freeze(['%','мес']);
    tryAddDefaultUnits(){
        if(this.getUnits().length == 0)
            this.addUnit(defaultUnits);
    }
    getUnits():Unit[]{
        return store.getters.units;
    }
    getUnitsForExpense(expense:Expense){
        const {type} = expense;
        // console.log({name:expense.name,u,type:u.expenseTypes.includes(type),isRef:UnitManager.refUnits.includes(u.text)}); 
        if(expense instanceof RefExpense) return this.getUnits().filter((u)=> u.expenseTypes.includes(type) && UnitManager.refUnits.includes(u.text) );
        else if(expense instanceof UnitExpense) return this.getUnits().filter((u)=> u.expenseTypes.includes(type) && !UnitManager.refUnits.includes(u.text) );
        if(!(expense instanceof CostExpense) && expense.subExpenses == undefined) console.warn({expense,msg:'Units are requested for unrecognized expense'});
        return [];
        //return this.getUnits().filter((u)=>u.expenseTypes.includes(type));
    }
    addUnit(unit:Unit|Unit[]){
        if(unit instanceof Unit) unit = [unit];
        //filter out unit text matches
        unit = unit.filter((u)=>!this.getUnits().map((tu)=>tu.text).includes(u.text));
            store.commit(SET_UNITS,[...unit, ...this.getUnits()]);
    }
    deleteUnit(unit:Unit){
        const index = this.getUnits().indexOf(unit);
        if(index==-1) {console.warn({units:this.getUnits(),unit,msg:`Tried to delete ${unit.text} unit.`}); return;}
        const sliced = this.getUnits()
        sliced.splice(index,1);
        store.commit(SET_UNITS,sliced);
    }
    clearUnits(){ store.commit(SET_UNITS,[]); }
}
export default new UnitManager();