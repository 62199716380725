







import Vue from 'vue';
import {channelTypes} from '@/common/config';
import SelectElMixin from '@/components/mixins/SelectElMixin.vue';
export default Vue.extend({
    name: 'channelTypeSelect',
    data(){
        return{
            channelTypes
        }
    },
    mixins: [SelectElMixin],
})
