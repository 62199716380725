import store from "@/store";
import { tooltipsText } from '@/common/config';
import StorageService from "@/common/storage.service";

type Tooltip = {
    name:string;
    value:string;
    isChanged?:boolean;
};

class StorageTooltipData {
    tooltips: Tooltip[];
    fetched: Date;
    constructor(tooltips:Tooltip[], fetched:Date){
        this.tooltips = tooltips;
        this.fetched = fetched;
    }
    static fromJson(json:Record<string,any>):StorageTooltipData{
        const castData = json as StorageTooltipData;
        return new StorageTooltipData(castData.tooltips, new Date(castData.fetched));
    }
}

export function formMainFieldTooltip(key:string):{content:any, mainField: boolean, key:string}{
    return {content: getTooltipText(key), mainField: true, key}
}

export function formComputedTooltip(key:string, chartI: number):{content:any, chartI: number, key:string}{
    return {content: getTooltipText(key), chartI, key}
}

export function getTooltipsDisabled():boolean{
    try {
        return StorageService.getStorageItems().SETTINGS_DISABLE_TOOLTIPS.isTrue();
    } catch (e) { return false; }
}

export function getTooltipText(key:string, args?:any[]):string|undefined{
    const tooltip = store.state.tooltips[key] || (tooltipsText as Record<string,string>)[key];
    if(getTooltipsDisabled()) return undefined;
    if(tooltip == undefined && key && !store.state.isTesting) console.warn({msg:'no matching tooltip',key,tooltip,tooltipsText})
    return tooltip;
}

export {Tooltip, StorageTooltipData};