
















import Vue from 'vue'
export default Vue.extend({
    name: 'collapsible',
    props: {
        fold:{type:Boolean},
        name:{type:String},
        flags:{},
        isExternal:{type:Boolean,default:false}
    },
    // watch: {
    //     dFold(fold:boolean){ this.$emit(fold?'folded':'unfolded'); this.$emit('change',fold); }
    // },
    data(){
        return{
            localFold: this.fold
        }
    },
    computed: {
        dFold: {
            get():boolean{
                return this.isExternal ? this.fold : this.localFold;
            },
            set(v:boolean):void{
                if(this.isExternal){
                    this.$emit('change',v);
                    this.$emit(v?'folded':'unfolded');
                }else{
                    this.localFold=v;
                }
            },
        },
        smallCollapseButton():boolean{
            return Array.isArray(this.flags) && this.flags.includes('small-btn');
        },
        smallCollapseActive():boolean{
            return Array.isArray(this.flags) && this.flags.includes('active');
        }
    }
})
