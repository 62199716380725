import { Unit } from '@/common/classes/unit';
import { SET_UNITS } from './mutations'

export default {
    state: {
        units: [] as Unit[]
    },
    mutations: {
        [SET_UNITS](state:any,units:Unit[]){
            state.units = units;
        }
    },
    getters: {
        units: (state:any):Unit[]=>state.units
    },
    actions: {
    }
}