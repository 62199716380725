
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { isMobile } from '@/common/misc';
import { CostExpense, Expense, RefExpense, UnitExpense } from '@/common/classes/expenses';
import { LOAD_TOOLTIPS } from '@/store/actions';
import { getTooltipsDisabled, getTooltipText } from '@/common/tooltips';
import { detect } from 'detect-browser';

export default Vue.extend({
    name: 'miscMixin',
    async mounted(){
        await this.$store.dispatch(LOAD_TOOLTIPS);
    },
    data(){
        return {
            winWidth: window.innerWidth,
        }
    },
    created(){
        window.addEventListener('resize',this.windowResizeListener);
    },
    destroyed(){
        window.removeEventListener('resize',this.windowResizeListener);
    },
    methods: {
        windowResizeListener(e:UIEvent){ this.winWidth = window.innerWidth; },
        tltpText(key:string,args:any[]):any|undefined {
            return getTooltipText(key, args);
        },
        //(?) move to config
        expenseFieldTltpText(expense:Expense,field:'cost'|'amount'|'select'|'pricePerUnit'):string|undefined{
            if(getTooltipsDisabled()) return undefined;

            type ExpenseFieldTooltipData = {
                cost:{cost:string},
                unit:{amount:string,select:string,pricePerUnit:string,},
                ref:{amount:string,select:string},
            };
            const _unknown:ExpenseFieldTooltipData = {
                cost:{cost:'Стоимость'},
                unit:{amount:'Количество',select:'Единица',pricePerUnit:'Цена'},
                ref:{amount:'Цена',select:'База'},
            };
            const unit:ExpenseFieldTooltipData = {
                cost: {cost:'Стоимость. Здесь указывается стоимость этой статьи затрат из расчёта на одну единицу (unit). Например, если единица - это один проданный пирог, а статья затрат - "Упаковка" то здесь вписывается стоимость упаковки на один проданный пирог.'},
                unit: {
                    amount:'Количество. Здесь указывается количество (например, 50) единиц измерения этой статьи затрат (например, грамм) из расчёта на одну единицу (unit), например на один пирог.',
                    select:'Единица измерения. Здесь указывается едница измерения для расчёта переменных затрат, например, при расчёте затрат на производство пирога, мы можем за единицу измерения взять граммы или килограммы. В список единиц измерения можно добавлять свои единицы, наример, час или метр.',
                    pricePerUnit:'Цена. Здесь указывается цена выбранной единицы измерения. Например, если статья затрат - мука, а единица измерения - кг, то сюда заносится стоимость одного килограмма муки. Если единица измерения - г., то одного грамма муки (рассчитывается делением цены за килограмм на 1000).',
                },
                ref: {
                    amount: 'Количество процентов. Здесь указывается количество процентов от базы. Например, если за базовую веичину (базу) взят средний чек, и нам надо рассчитать налог по УСНО 6% от выручки, то сюда вписывается "6".',
                    select: 'База. Здесь указывается показатель, от которого будет рассчитываться процент. Например, если когда мы считаем налоги с оборота, мы используем за базу Средний чек, т.к. за каждую единицу (unit) мы получаем от клиента Средний чек рублей, и с него платим налог 6%.',
                },
            };
            const _const:ExpenseFieldTooltipData = {
                cost: {cost:'Стоимость. Здесь указывается размер этой статьи затрат из расчёта на один месяц. Например, если мы в месяц вносим арендную плату в размере 10 000₽, то здесь пишем 10000.'},
                unit: {
                    amount:'Количество. Здесь указывается количество (например, 10) единиц измерения этой статьи затрат (например, кв.м.) из расчёта на один месяц (речь ведь идёт о постоянных затратах.',
                    select:'Единица измерения. Здесь указывается едница измерения для расчёта постоянных затрат, например, при расчёте зарплат единицей измерения будет сотрудник (чел). В список единиц измерения можно добавлять свои единицы, наример, час или метр.',
                    pricePerUnit:'Цена. Здесь указывается цена выбранной единицы измерения. Например, если статья затрат - зарплата рабочего, а единица измерения - чел., то сюда заносится средняя зарплата одного рабочего.',
                },
                ref: {
                    amount: 'Количество процентов. Здесь указывается количество процентов от базы. Например, если за базовую веичину (базу) взята зарплата (чистыми, на руки) и нам надо рассчитать налоги и сборы на зарплату в размере 50% от зарплаты, то сюда вписывается "50".',
                    select: 'База. Здесь указывается показатель, от которого будет рассчитываться процент. Например, если когда мы считаем налоги и сборы на зарплаты, мы используем за базу статью затрат "Зарплаты", процент равен 50, то есть с каждого рубля, выплаченного сотрудникам на руки, мы платим 50 копеек налогов и сборов.',
                },
            };
            const onetime:ExpenseFieldTooltipData = {
                cost: {cost:'Стоимость. Здесь указывается размер этой статьи затрат за весь проект. Это разовые затраты, значит, размер этой величины не будет завиеть от продолжительности проекта. Например, если для запуска мы должны закупить оборудование на 100 000₽, то здесь пишем 10000.'},
                unit: {
                    amount:'Количество. Здесь указывается количество (например, 10) единиц измерения этой статьи затрат (например, компьютеров), которые надо закупить (оплатить) один раз для запуска проекта.',
                    select:'Единица измерения. Здесь указывается едница измерения для расчёта разовых затрат, например, при расчёте стоимости приобретения компьютеров для класса, единицей измерения будет компьютер. В список единиц измерения можно добавлять свои единицы, наример, компьютер или кв.м..',
                    pricePerUnit:'Цена. Здесь указывается цена выбранной единицы измерения. Например, если статья затрат - Закупка компьютеров, а единица измерения - компьютер, то сюда заносится средняя цена одного компьютера ',
                },
                ref: {
                    amount: 'Количество процентов. Здесь указывается количество процентов от базы. Например, если за базовую веичину (базу) взята ежемесячная аренда  и нам надо рассчитать аванс по аренде, котрый составит два месяца арендной ставки, то сюда вписывается "200", потому что 2 месяца аренды = 200% от ежемесячной арендной ставки.',
                    select:'База. Здесь указывается показатель, от которого будет рассчитываться процент. Например, если когда мы считаем аванс по аренде, здесь вносится "Аренда". А если мы считаем начальную закупку, то здесь вносится "Продукты" из переменных затрат. Учтите, что переменные затраты здесь персчитываются на месяц, не на единицу.',
                },
            };
            const structureType = (function structureTypeForExpense(){
                switch(expense.type){
                    case -1: return _unknown;
                    case 0: return unit;
                    case 1: return _const;
                    case 2: return onetime;
                    default: throw new Error("Unrecognized expense type "+expense.type);
                }
            })();
            const costType = (function costTypeForExpense(){
                if(expense instanceof CostExpense) return structureType.cost;
                if(expense instanceof UnitExpense) return structureType.unit;
                if(expense instanceof RefExpense) return structureType.ref;
                throw new Error("Expense field tooltip is requested for expenses that is not a instance of Cost/Unit/Ref type");
            })();
            const tooltip = (costType as Record<string,string>)[field];
            if(tooltip == undefined) console.error({msg:'unable to find a tooltip for field',structureType,costType,field,expense})
            return tooltip;
        },
    },
    computed: {
        isIOS():boolean{
            return detect()?.os == 'iOS';
        },
        isMobile():boolean{
            return isMobile();
        },
        userProfilePicture():string{
            if(this.isAuthenticated && this.user.picture){
                return this.user.picture;
            }
            return '/default-pfp.png';
        },
        ...mapGetters(['isAuthenticated','user'])
    }
})
