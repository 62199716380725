import { jsonCycler } from "../misc";

class ProductTax {
    name:string;
    type:string;
    /**
     * Tax rate, ranges from 0 to 100
     */
    rate:number;
    activationMonth=1;
    disabled?:boolean;
    constructor(name:string, type:string, rate:number, disabled?:boolean){
        this.name = name;
        this.type = type;
        this.rate = rate;
        this.disabled = disabled;
    }
    toJSON = ():ProductTax=>{
        const jTypes = [{t:ProductTax,s:'basicTax'}];
        let match = jTypes.find(({t})=>this instanceof t)
        if(match == undefined) {
            console.warn({msg:'Unable to recognize tax type.', jTypes, self: JSON.stringify(this, jsonCycler())});
            match = jTypes[0];
        }
        const cloned = Object.assign({},this);
        return {...cloned,jType:match.s};
    }
}

export { ProductTax };