

























































import { ProjectModel } from '@/common/classes/models';
import { endpoints, fetchProjectPresetImages} from '@/common/config';
import { redirToProjectCreation } from '@/router';
import DropdownMenu from '@/components/elements/DropdownMenu.vue';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { createClickAnchor, escapeHtml, getLinkToProject, processResponseError, putToClipboard } from '@/common/misc';
import { Resource } from '@/common/types/resource.types';
import ApiService from '@/common/api.service';

export default Vue.extend({
    name: 'projectCard',
    components: {DropdownMenu},
    mixins: [MiscMixin],
    props: {
        project:{type:ProjectModel},
        type:{type:String},
        hideAuthor:{type:Boolean,default:false},
        isDisabled:{type:Boolean,default:false},
    },
    async mounted(){
        if(this.isRegularType && !this.project.data.imageLink) this.defaultProjectImage = (await fetchProjectPresetImages()).defaultProjectImage;
    },
    data(){
        return{
            defaultProjectImage: undefined as (undefined|string),
            isFetchingFavorite: false,
        };
    },
    methods: {
        async shareProject(type:'project'|'channels'|'budget'):Promise<void> {
            let url = this.linkToProject;
            if(type == 'channels' || type == 'budget') url += `&tab=${type}`;
            if(!url) { alert('При создании ссылки на проект произошла ошибка'); return; }
            try { await putToClipboard(url); }
            catch (e){
                console.error(e+'');
                console.error(e);
                alert('При добавлении значения '+url+' в буфер обмена произошла ошибка.');
            }
        },
        projectImgClick():void{
            if(this.isDisabled) {
                // if((this as any).isMobile) return;
                this.$emit('disabled-project-click');
            }
            else this.onclick();
        },
        onclick():void{
            if(this.isAddType){
                redirToProjectCreation();
            }else if(this.isRegularType){
                createClickAnchor(`/create?load=${this.project.data.id}`,(a)=>a.target='_blank')
            }
        },
        async favoriteProject():Promise<void>{
            if(!this.isAuthenticated || !this.user || !this.user.id) return;
            this.isFetchingFavorite = true;
            try {
                const {data} = await this.fetchFavorite(this.project);
                this.$emit('update-projects');
            } catch (error) {
                //TODO: Pretty alert
                alert('Во время обработки запроса произошла ошибка <br> '+processResponseError(error+''));
            } finally{ this.isFetchingFavorite = false; }
        },
        fetchFavorite(obj:(ProjectModel|Resource)) {
            const {isAuthenticated,user} = this;
            if(!isAuthenticated) throw new Error("Unable to set as favorite, user is not authenticated");
            const userId = (user as any).id;
            let isFavorite:boolean;
            let objId:any;
            if(obj instanceof ProjectModel){
                const {setFavoriteProject, unsetFavoriteProject } = endpoints;
                isFavorite = (obj.data.favoriteIds as string[]).includes(userId);
                objId = obj.data.id;
                return ApiService.put(isFavorite ? unsetFavoriteProject(objId) : setFavoriteProject(objId));
            }else{
                const {setFavoriteResource, unsetFavoriteResource} = endpoints;
                objId = obj.id;
                isFavorite = obj.favoriteIds.includes(userId);
                return ApiService.put(isFavorite ? unsetFavoriteResource(objId) : setFavoriteResource(objId));
            }
        },
        deleteCardProject():void{
            const hideDialog = () => this.$modal.hide('dialog');
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            const userPromptPromise = new Promise((resolve,reject)=>{
                this.$modal.show('dialog',{
                    title: 'Подтвердите действие',
                    text: `Вы уверены, что хотите удалить проект '${escapeHtml(this.project.data.name)}'?`,
                    buttons: [
                        { title:'Продолжить', handler: resolve, },
                        { title: 'Отмена', handler(){ hideDialog(); reject(); }, }
                    ]
                })
            }) as Promise<void>;
            ProjectModel.deleteProject(userPromptPromise,this.project.data.id,undefined,()=>{
                self.$emit('update-projects');
                hideDialog();
            });
        }
    },
    computed: {
        linkToProject: {
            get():string|undefined{
                return getLinkToProject(this.project.data)
            },
            cache: false,
        },
        isFavorite():boolean{
            return this.isAuthenticated && this.user && this.user.id && this.project.data.favoriteIds.includes(this.user.id);
        },
        projectImage():string{
            return this.project.data.imageLink||this.defaultProjectImage||'/default-project.jpg';
        },
        projectAuthorImage():string{
            return this.project.data.author.picture||'/default-pfp.png';
        },
        isAddType():boolean{
            return this.type == 'add';
        },
        isExemplary():boolean{
            return this.type == 'exemplary';
        },
        isRegularType():boolean{
            return !this.isAddType;
        },
        ...mapGetters(['isAuthenticated','user','projectData'])
    }
})
