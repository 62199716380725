import StorageService from '@/common/storage.service';
import { ProjectModel } from '@/common/classes/models';
import { b64DecodeUnicode, b64EncodeUnicode, jsonCycler } from '@/common/misc';

export class BasicStorageItem {
    key='';
}

export class StorageDataItem extends BasicStorageItem {
    setData(data:string){
        StorageService.setItem(this,data);
    }
    getData(){
        return StorageService.getItem(this);
    }
}

export class GenericObjectStorage<T> extends BasicStorageItem {
    setData(data:T){
        const stringified = JSON.stringify(data,jsonCycler());
        const encoded = b64EncodeUnicode( stringified );
        StorageService.setItem(this, encoded);
    }
    getData():Record<string,any>|null{
        const baseStr = StorageService.getItem(this);
        return baseStr ? JSON.parse( b64DecodeUnicode(baseStr) ) : null;
    }
}

export class StorageProjectItem extends BasicStorageItem {
    setData(project:ProjectModel){
        const stringified = JSON.stringify(project,jsonCycler());
        const encoded = b64EncodeUnicode( stringified );
        StorageService.setItem(this, encoded);
    }
    getData():Record<string,any>|null{
        const baseStr = StorageService.getItem(this);
        return baseStr ? JSON.parse( b64DecodeUnicode(baseStr) ) : null;
    }
}

// export class StorageTooltipsItem extends BasicStorageItem {
//     setData(data:StorageDataItem){
//         const 
//     }
// }

export class StorageBooleanItem extends BasicStorageItem {
    setValue(value:boolean){
        StorageService.setItem(this, value ? 'true' : 'false');
    }
    setTrue(){
        StorageService.setItem(this,'true');
    }
    isTrue():boolean{
        const item = StorageService.getItem(this);
        return typeof item == 'string' && item == 'true';
    }
    isFalse():boolean{
        return !this.isTrue();
    }
}

export class StorageStringItem extends BasicStorageItem {
    setValue(value:string){
        StorageService.setItem(this, value);
    }
    getValue():string|null{
        return StorageService.getItem(this);
    }
}