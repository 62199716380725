




















import Vue from 'vue';
import equal from 'deep-equal';
import { endpoints } from '@/common/config';
import ApiService from '@/common/api.service';
import { BasicPromocode, DiscountPromocode, ServerTariff, TariffPromocode } from '@/common/types/tariff.types';
import AdminPromocodeElement from '@/components/admin/AdminPromocodeElement.vue';
import { processResponseError } from '@/common/misc';
export default Vue.extend({
    name: 'adminPromocodeTab',
    components: { AdminPromocodeElement },
    data(){
        return {
            newPromocodeName: '',
            promocodes: [] as BasicPromocode[],
            tariffs: [] as ServerTariff[],
            isSaving: false,
        }
    },
    mounted(){
        this.updatePromocodeList();
    },
    methods: {
        async updatePromocodeList(tariffPromoCodes?:TariffPromocode[], discountPromoCodes?:DiscountPromocode[]):Promise<void>{
            if(tariffPromoCodes == undefined || discountPromoCodes == undefined){
                const fetchedPromoCodes = (await ApiService.get(endpoints.adminGetPromoCodes)).data.promoCodes;
                tariffPromoCodes = fetchedPromoCodes.tariffPromoCodes;
                discountPromoCodes = fetchedPromoCodes.discountPromoCodes;
            }
            tariffPromoCodes = tariffPromoCodes?.map((tariffPromo)=>TariffPromocode.fromPlain(tariffPromo));
            discountPromoCodes = discountPromoCodes?.map((discountPromo)=>DiscountPromocode.fromPlain(discountPromo));

            if(tariffPromoCodes == undefined || discountPromoCodes == undefined) {
                console.error({msg: 'PromoCodes are undefined', tariffPromoCodes, discountPromoCodes})
                alert('При загрузке промокодов произошла ошибка')
                return;
            }

            this.tariffs = (await ApiService.get(endpoints.getTariffs)).data.tariffs;

            this.promocodes = [
                ...tariffPromoCodes,
                ...discountPromoCodes
            ];

            this.promocodes.forEach((promoCode)=>{
                const {expiration} = promoCode;
                if(typeof expiration == 'string') promoCode.expiration = new Date(expiration);
            })
        },
        async saveChanges():Promise<void>{
            this.isSaving = true;
            try {
                const putPromoCodesResponse = await ApiService.put(endpoints.adminPutPromoCodes, {
                    tariffPromoCodes: this.promocodes.filter((promo)=>promo.isTariffType()),
                    discountPromoCodes: this.promocodes.filter((promo)=>promo.isDiscountType())
                });
                const { tariffPromoCodes, discountPromoCodes } = putPromoCodesResponse.data.promoCodes;
                await this.updatePromocodeList(tariffPromoCodes, discountPromoCodes);
                this.promocodes.forEach((p)=>this.$set(p, 'collapse', true));
            } catch (error) {
                alert(processResponseError(error));
            } finally { this.isSaving = false; }
        },
        deletePromocode(index:number){
            const cloned = [...this.promocodes];
            console.log(cloned.map((promo)=>promo.code).join(','))
            if(!confirm(`Вы уверены, что хотите удалить промокод ${cloned[index].code}?`)) return;
            cloned.splice(index, 1);
            this.promocodes = cloned;
        },
        replacePromocode(e:{index:number, promocode:BasicPromocode}){
            this.$set(this.promocodes, e.index, e.promocode);
        },
        createNewPromocode():void{
            const name = this.newPromocodeName;
            const hasSameCode = !!this.promocodes.find(({code})=>code == name);
            if(!name || hasSameCode) {
                alert(hasSameCode ? `Промокод ${name} уже существует` : 'Поле промокод не заполнено');
                return;
            }
            const promocode = new DiscountPromocode(undefined, name, this.tariffs[0], new Date(), 1, 'ONE_TARIFF');
            this.promocodes.push(promocode);
            this.newPromocodeName = '';
        },
        replacePromocodeWith(orig:BasicPromocode, to:BasicPromocode):void{
            const index = this.promocodes.findIndex((p)=>equal(orig, p));
            this.$set(this.promocodes, index, to);
            this.vuePromocodes.forEach((vue)=>vue.$forceUpdate());
        },
    },
    computed: {
        vuePromocodes():Vue[]{
            const vuePromocodes = this.$refs.vuePromocodes;
            if(vuePromocodes == undefined) return [];
            if(!Array.isArray( vuePromocodes )) return [vuePromocodes as Vue];
            return vuePromocodes as Vue[];
        },
    },
})
