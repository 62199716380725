



























import Vue from 'vue';
import Header from '@/components/elements/Header.vue';
import VueFooter from '@/components/elements/VueFooter.vue';
// import NavBar from '@/components/elements/NavBar.vue';
import { CHECK_AUTH } from '@/store/actions';
import { fetchProjectPresetImages } from '@/common/config';
import { mapGetters } from 'vuex';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import { RouteConfig } from 'vue-router';
import 'tippy.js/themes/light-border.css';
import { RenderData } from '@/store';

export default Vue.extend({
    name: 'navHeader',
    mixins: [MiscMixin, ResponsiveDataMixin],
    components: {
        Header,
        VueFooter,
        // NavBar
    },
    data(){
        return {
            showMobileMenu: false,
        }
    },
    watch: {
        activeNavDestination(newActiveNavDestination){
            if(newActiveNavDestination.path == '/projects')
                fetchProjectPresetImages();
        }
    },
    computed: {
        tabContentContainerMobilePadding: {
            get():string|undefined{
                const rootHeader = (document.querySelector('#root-header') as HTMLElement);
                if(!rootHeader) return;
                const rootHeaderHeight = rootHeader.offsetHeight;
                return (this.renderData as RenderData).addMobileHeaderPaddingToContent ? rootHeaderHeight+'px' : undefined;
            },
            cache: false
        },
        addMobilePadding():boolean{
            return !(this.renderData as RenderData).disableMobileStickyHeaderOnOffset;
        },
        routes():RouteConfig[]{ 
            return this.$router.options.routes || []; 
        },
        ...mapGetters(['activeNavDestination','isAuthenticated', 'renderData']),
    }
})
