













//TODO: Wrap as promise
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ImageUploader from '@/components/elements/ImageUploader.vue';

export default Vue.extend({
    name: 'imageUploaderModal',
    components: {ImageUploader},
    props: {
        modalWidth: {type:Number, default: 410},
        selectFileOnMounted: {type:Boolean, default: false},
    },
    data(){
        return {
            getUploadPromise: undefined as (()=>Promise<string>)|undefined,
        }
    },
    methods: {
        onCropImageChange(data:{file:File, url:string, getPromise:()=>Promise<string>}):void{
            this.getUploadPromise = data.getPromise;
        },
        async continueClick():Promise<void>{
            const imgUrl = await (this.getUploadPromise as any)();
            this.$emit('image-upload-complete',imgUrl);
        },
    },
    computed: {
        userImage():string{
            return this.user.picture || '/default-pfp.png'
        },
        modalHeight(){
            return Math.min(document.documentElement.scrollHeight, 410);
        },
        ...mapGetters(['user','isAuthenticated']),
    }
})
