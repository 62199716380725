







































import Vue from 'vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { ServerTariff } from '@/common/types/tariff.types';
import { mapGetters } from 'vuex';
import TariffService, { DisplayTariffData } from '@/common/tariff.service';
import YandexMetrikaService from '@/common/yandex.metrika.service';
import { createClickAnchor, parseDate, processResponseError } from '@/common/misc';

export default Vue.extend({
    name: 'tariffList',
    mixins: [MiscMixin],
    async mounted(){
        if(this.isAuthenticated){
            await this.fetchUserTariffs();
        }
        this.isFetching = false;
    },
    watch: {
        isAuthenticated(newVal){
            if(newVal) this.fetchUserTariffs();
        },
    },
    data(){
        return{
            fetchedTariffs: [] as ServerTariff[],
            fetchedActiveTariffs: [] as ServerTariff[],
            paidUserTariffsDisabled: false,
            isFetching: true,
        }
    },
    methods: {
        async fetchUserTariffs():Promise<void>{
            try {
                this.isFetching = true;
                const { tariffs, activeTariffs, paidUserTariffsDisabled } = (await ApiService.get(endpoints.getTariffs)).data;
                this.fetchedTariffs = tariffs;
                this.fetchedActiveTariffs = activeTariffs;
                if (typeof paidUserTariffsDisabled == 'boolean' && paidUserTariffsDisabled === true) {
                    this.paidUserTariffsDisabled = true;
                }
            } catch (error) {
                alert(processResponseError(error));
            } finally {
                this.isFetching = false;
            }
        },
        showButtonForTariff(tariff:DisplayTariffData):boolean{
            if(this.fetchedActiveTariffs.length == 0) return true;
            const activePaidTariff = this.fetchedActiveTariffs
                .filter((tariff)=>tariff.upgradable)
                .sort((a,b)=>a.rubCost-b.rubCost)[0];
            const hideTariffNames = [
                this.tariffs[0].name, //Free tariff is first
                ...(activePaidTariff ? this.fetchedTariffs
                    .filter((tariff)=>{
                        // console.log(tariff.id,activePaidTariff.id,tariff.upgradable,tariff.rubCost,activePaidTariff.rubCost); 
                        return tariff.id != activePaidTariff.id && tariff.upgradable && tariff.rubCost < activePaidTariff.rubCost
                    })
                    .map(({name})=>name) : [])
            ]
            return !hideTariffNames.includes( tariff.name );
        },
        getBtnTextForActiveTariff(tariff:DisplayTariffData){
            const tariffData = this.getServerTariffForDisplayTariff(tariff);
            let text = 'Активный тариф';
            // console.log(tariffData);
            if(tariffData && tariffData.expiration) {
                // text += '\n';
                text += tariffData.strType == 'promo_code' ? ' (промокод)' : '';
                text += `\nдо ${parseDate( tariffData.expiration ).toLocaleDateString('ru') }`;
            }
            return text;
        },
        tariffClick(tariff:DisplayTariffData){
            if(!this.isAuthenticated) this.$router.push('/login')
            const {CHANGE_TARIFF_BTN_CLICK} = YandexMetrikaService.getGoals();
            YandexMetrikaService.reachGoal(CHANGE_TARIFF_BTN_CLICK);
            // Commented out to disable checking if server returned tariff data with matching name. All buttons will open request support modal
            /*const matchingNameTariff = this.isAuthenticated ? this.getServerTariffForDisplayTariff(tariff) : undefined;
            if(matchingNameTariff) {
                if(this.paidUserTariffsDisabled) {
                    this.$modal.show('dialog', {
                        title: 'Платные тарифы отключены',
                        text: 'Прежде платный функционал теперь доступен всем пользователям без ограничений.',
                        buttons: [
                            {
                                title: 'ОК',
                                handler: () => this.$modal.hide('dialog'),
                            }
                        ]
                    })
                } else { createClickAnchor(`/payments?action=create&tariffId=${matchingNameTariff.id}`); }
            }
            else */this.$modal.show('feedback-modal', {reason: tariff.feedbackReason || 'tariff-reason-unknown'});
        },
        _compareTariffs(a:DisplayTariffData|ServerTariff,b:DisplayTariffData|ServerTariff):boolean{
            return a.name.toLowerCase().trim() == b.name.toLowerCase().trim();
        },
        isDisplayTariffActive(displayTariff:DisplayTariffData):boolean{
            if(this.fetchedActiveTariffs.length == 0){
                const index = this.tariffs.indexOf( displayTariff );
                return index == 0;
            }
            const matchingFetched = this.getServerTariffForDisplayTariff(displayTariff);
            return !!( matchingFetched && this.fetchedActiveTariffs.find((activeTariff)=>this._compareTariffs(activeTariff,matchingFetched)) );
        },
        getServerTariffForDisplayTariff(displayTariff:DisplayTariffData):(ServerTariff|undefined){
            const findTariffPredicate = (t:ServerTariff)=>this._compareTariffs(t,displayTariff)
            return this.fetchedActiveTariffs.find(findTariffPredicate) || this.fetchedTariffs.find(findTariffPredicate);
        },
    },
    computed: {
        tariffs(){
            return TariffService.getDisplayTariffData();
        },
        ...mapGetters(['user','isAuthenticated'])
    }
})
