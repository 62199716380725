













































































































import { ResourceSearchData } from '@/common/types/resource.types'
import Vue from 'vue'
import BizModelSelect from '@/components/elements/BizModelSelect.vue';
import IndustrySelect from '@/components/elements/IndustrySelect.vue';
import ResourceFieldSelect from '@/components/resources-tab/ResourceFieldSelect.vue';
import ChannelTypeSelect from '@/components/elements/ChannelTypeSelect.vue';
import SocialFilterSelect from '@/components/elements/search/SocialFilterSelect.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'searchOptions',
    components: {
        ResourceFieldSelect,
        BizModelSelect,
        IndustrySelect,
        ChannelTypeSelect,
        SocialFilterSelect,
    },
    mounted(){
        this.keyDownListener = (e:KeyboardEvent)=> {
            if(e.key == 'Escape') this.hideSearchSettings();
        }
        window.addEventListener('keydown',this.keyDownListener);
    },
    beforeDestroy(){
        window.removeEventListener('keydown',this.keyDownListener);
    },
    data(){
        return{
            keyDownListener: undefined as any,
        }
    },
    props: {
        modelData: {},
        isResource: {type:Boolean}
    },
    methods: {
        hideSearchSettings(){
            this.$emit('hide-search-settings');
        },
    },
    computed: {
        searchData():ResourceSearchData{
            return this.modelData as ResourceSearchData;
        },
        ...mapGetters(['isAuthenticated'])
    }
})
