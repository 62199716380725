




import Vue from 'vue'

//TODO: [LOW PRIOR] Bad solution, look for alternatives
//Scroll change 5% of input value - prevent scroll event on focus, change value https://stackoverflow.com/a/20838527
export default Vue.extend({
    name: 'smartNumberInput',
    props: {
        selOnClick: {type:Boolean,default:false},
        modelData: {type:Object},
    },
    methods: {
        updateVal():void{
            const {modelData,inputVal} = this;
            const {validity} = this.inputEl;
            const {b,p} = modelData as any;
            if(typeof inputVal == 'number' && validity.valid){ 
                this.$set(b,p,inputVal); 
                this.$emit('change', inputVal); 
            }
        },
        inputClick():void{
            const input = this.inputEl;
            if(this.selOnClick) input.select();
            if(input.value.trim() == '0'){
                //note: does not work on mobile safari (https://stackoverflow.com/a/4067488)
                input.select();
                //input.setSelectionRange(0, input.value.length);
            }
        },
    },
    computed: {
        inputEl():HTMLInputElement{
            return this.$refs.smartInput as HTMLInputElement;
        },
        bindVal():string{
            const {b,p} = this.modelData as any;
            return b[p];
        },
        inputVal: {
            get():(string|number){
                const {value} = this.$refs.smartInput as HTMLInputElement;
                const parsed = value.includes(".") ? parseFloat(value) : parseInt(value);
                return isNaN(parsed) ? value : parsed;
            },
            cache: false
        }
    }
})
