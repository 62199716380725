






























import Vue from 'vue';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { Resource, ResourceCategory, ResourceCollection } from '@/common/types/resource.types';
import ResourceEditForm from '@/components/elements/ResourceEditForm.vue';
import { escapeHtml, processResponseError } from '@/common/misc';

const MODAL_WIDTH = 660;

//Edits resource/collection
export default Vue.extend({
    name: 'suggestResourceModal',
    components: {
        ResourceEditForm
    },
    data(){
        return{
            modalWidth: MODAL_WIDTH,
            activeResource: undefined as (undefined|Resource),
            activeResourceParentCollection: undefined as (undefined|ResourceCollection),
            resourceCategories: [] as ResourceCategory[],
            resourceCollections: [] as ResourceCollection[],
            isFetchingSuggest: false,
            showSuccessMessage: false,
        }
    },
    created() {
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
    },
    methods: {
        beforeOpen({params}:any):void{
            const {curCollection,resourceCategories,resourceCollections} = params;
            this.resourceCollections = resourceCollections;
            this.resourceCategories = resourceCategories.map((rc:any)=>rc.category);
            this.activeResourceParentCollection 
            this.activeResourceParentCollection = curCollection || resourceCollections[0];
            this.activeResource = {name:''} as Resource;
        },
        async saveActiveResourceChanges(){
            try {
                this.isFetchingSuggest = true;
                const resourceCollection = this.activeResourceParentCollection;
                const resource = this.activeResource;
                if(!resourceCollection) throw new Error("Коллекция ресурса не указана");
                await ApiService.post(endpoints.postSuggestResource(resourceCollection.id),resource);
                this.showSuccessMessage = true;
                setTimeout(()=>{
                    this.showSuccessMessage = false;
                    this.$modal.hide('suggestResourceModal');
                },2000);
            } catch (e) { alert(`Во время обработки запроса произошла ошибка \n ${processResponseError(e)}`); }
            finally{ this.isFetchingSuggest = false; }
        },
        async fetchResourceCategoriesAndCollections(){
            this.resourceCategories = 
                (await ApiService.get(endpoints.adminGetResourceCategories))
                    .data
                    .resourceCategories;
            this.resourceCategories = this.resourceCategories.sort((a,b)=>a.priority-b.priority);
        },
        isResourceCollection(resource:any){
            return resource && typeof resource == 'object' && Array.isArray( resource.resourceList );
        },
    },
    computed: {
        //?Find a better way of checking type
        isEditingResource():boolean{
            return !!this.activeResource && !this.isEditingResourceCollection;
        },
        //?Find a better way of checking type
        isEditingResourceCollection():boolean{
            return !!this.activeResource && this.isResourceCollection(this.activeResource);
        },
        modalHeight():number{
            return Math.min( 720, window.innerHeight );
        }
    }
})
