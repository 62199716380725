










































import Vue from 'vue';
import {endpoints} from '@/common/config';
import ApiService from '@/common/api.service';
import { createClickAnchor, processResponseError } from '@/common/misc';
export default Vue.extend({
    name: 'forgotPassword',
    data(){
        return{
            forgotEmail: '',
            newPass: '',
            confirmPass: '',
            isFetching: false,
            showSuccessMessage: false,
        }
    },
    watch: {
        newConfirmPass: {
            handler(newVal){
                const {newPass,confirmPass} = newVal;
                const confirmPassInput = this.$refs.confirmPassInput as HTMLInputElement;
                if(newPass!=confirmPass) confirmPassInput.setCustomValidity('Пароли не совпадают')
                else confirmPassInput.setCustomValidity('');
            },
            deep: true,
        }
    },
    methods: {
        async forgetPass(){
            try{
                this.isFetching = true;
                await ApiService.post(endpoints.postForgotPassword,{email:this.forgotEmail});
                this.showSuccessMessage = true;
                await new Promise((r)=>setTimeout(r,3000));
                createClickAnchor('/');
            }catch(e){
                alert('При обработке произошла ошибка '+processResponseError(e));
            }finally{ this.isFetching = false; }
        },
        async resetPass(){
            try{
                this.isFetching = true;
                await ApiService.put(endpoints.putResetPassword, {token:this.resetPassToken,password:this.newPass});
                this.showSuccessMessage = true;
                await new Promise((r)=>setTimeout(r,3000));
                createClickAnchor('/');
            }catch(e){
                alert('При обработке произошла ошибка '+processResponseError(e));
            }finally{ this.isFetching = false; }
        },
    },
    computed: {
        newConfirmPass():any{
            const {newPass,confirmPass} = this;
            return {newPass,confirmPass};
        },
        isForgotPassword: {
            get():boolean{
                return !this.isResettingPassword;
            },
            cache: false
        },
        isResettingPassword: {
            get():boolean{
                return this.$route.query.action == 'reset';
            },
            cache: false
        },
        resetPassToken: {
            get():any{
                return this.$route.query.token;
            },
            cache: false
        }
    }
})
