













import Vue from 'vue'
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { getTooltipsDisabled } from '@/common/tooltips';
//Circle slices/segments - https://stackoverflow.com/a/54272993

export default Vue.extend({
    name: 'userProfilePicture',
    data(){
        return {

        }
    },
    mixins: [MiscMixin],
    props: {
        pUser: {
            type: Object
        },
        onNavbar: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    computed: {
        hasChapterScores():boolean{
            return !!this.chapterScores.find((v)=>!!v);
        },
        chapterScores():number[]{
            // eslint-disable-next-line no-constant-condition
            // if(true == true) return [1,2,3,4,5]
            return this.pUser?.bookChapterScores || [0,0,0,0,0]
        },
        profilePictureUrl():string{
            return this.pUser?.picture || (this as any).userProfilePicture;
        },
        tooltipText():string|undefined{
            if(getTooltipsDisabled() || !this.hasChapterScores) return undefined;
            let str = `Результаты опросов -<br>`;
            for (let i = 0; i < this.chapterScores.length; i++) {
                const score = this.chapterScores[i];
                str += ` Глава ${i+1} - ${score}/5<br>`
            }
            return str;
        },
    },
})
