import {Expense} from './expenses';
import UnitManager from '@/common/classes/unit.manager';
import store from '@/store';
import Vue from 'vue';
import { escapeHtml, getUniqueName, roundToDecimals } from '../misc';
import { channelTypes, endpoints, testChannelType } from '../config';
import { SET_PRODUCT, SET_CHANNELS, SET_REFERENCER, AFTER_ROUTE } from '@/store/mutations';
import { Referencer } from './referencer';
import ApiService from '../api.service';
import channelManager from './channel.manager';
import { v4 as uuidv4 } from 'uuid';
import { ProductTax } from '../types/product.types';

export class ProductModel{
    unit: string;
    industry: string;
    bizModel: number;
    avgCheck: number;
    retention: number;
    expenseUnits: string[];
    expenses: Expense[];
    seasonality= [...Array(12)].map(()=>100);
    tax?:ProductTax;
    clientReturnTime = 30;
    discountingRate = 30;
    monthsTillTargetSales = 0;
    totalTargetMonthlySales = 1;
    clientsPerSalesUnit = 1;
    useStructure = true;
    useCost = true;
    useUnit = true;
    constructor(){
        this.unit = ''
        this.industry = ''
        this.bizModel = undefined as any
        this.avgCheck = 0
        this.retention = 0
        this.expenseUnits = ["кг", "шт", "чел"]
        this.expenses = []
    }
    initialize():void{
        this.useStructure = true;
        this.useCost = true;
        this.useUnit = true;
        this.clientsPerSalesUnit = this.clientsPerSalesUnit || 1;
        if(this.tax != undefined && this.tax.activationMonth == undefined) Vue.set(this.tax, 'activationMonth', 1)
        this.seasonality = this.seasonality || [...Array(12)].map(()=>100);
    }
    getUnitExpenses():Expense[]{ 
        return this.expenses.filter((e:Expense)=>e.type===0);
    }
    getConstExpenses():Expense[]{
        return this.expenses.filter((e:Expense)=>e.type===1);
    }
    getOnetimeExpenses():Expense[]{
        return this.expenses.filter((e:Expense)=>e.type==2);
    }
    getApc():number{
        const calcRetention = Math.max(0,this.retention);
        return 1 / ( 1 - calcRetention / 100);
    }
    static calcApcFromRetention(retention:number){
        const calcRetention = Math.max(0,retention);
        return 1 / ( 1 - calcRetention / 100);
    }
    static calcRetentionFromApc(apc:number){
        const calcApc = Math.max(0,apc);
        return (1 - 1 / calcApc) * 100;
    }
}

//relies on input and global variables, ?computed to methods
export class ChannelModel{
    //NOTE: Don't forget to add new properties in clone method
    id?: number;
    targetMonthlySales: number;
    targetFraction = 0;
    channelType = -1;
    activationMonth = 1;
    stages: ChannelStageModel[];
    uuid = uuidv4();
    name = "Новый канал";
    useCost = true;
    useConversion = true;
    constructor(){
        this.targetMonthlySales = 0
        this.stages = [
            new ChannelStageModel('Просмотр',100,new ChannelStageExpenseModel()),
            new ChannelStageModel('Оплата',100,new ChannelStageExpenseModel())
        ];
    }
    clone():ChannelModel{
        const cloned = Object.assign(new ChannelModel(),JSON.parse(JSON.stringify(this))) as ChannelModel;
        delete cloned.id;
        cloned.name = getUniqueName(this.name,store.getters.channels.map((chan:ChannelModel)=>chan.name));
        cloned.uuid = uuidv4();
        cloned.targetFraction = 0;
        return cloned;
    }
    _getProduct(){
        return store.state.product;
    }
    initialize():void{
        this.useConversion = true;
        this.useCost = true;
        this.uuid = this.uuid || uuidv4();
        this.stages.forEach((stage)=>{ 
            stage.uuid = stage.uuid || uuidv4() 
            this.activationMonth = this.activationMonth || 1;
            stage.activationMonth = stage.activationMonth || 1;
        });
        // this.useConversion = !!this.stages.find((s)=>s.conversion);
        // this.useCost = this.useConversion && !!this.stages.find((s)=>s.stageExpense.amount);
    }
    assignFraction(){
        const totalTargetMonthlySales = channelManager.getTotalTargetMonthlySales();
        let fraction = this.targetMonthlySales / totalTargetMonthlySales * 100;
        fraction = roundToDecimals( fraction, 2 ) / 100;
        Vue.set( this, 'targetFraction', fraction );
    }
    cac():number{
        return this.monthChannelExpense()/this.targetMonthlySales || 0;
    }
    singleSaleCost():number{
        const cac = this.cac();
        return cac / this._getProduct().getApc();
    }
    monthChannelExpense():number{
        return this.stages.reduce((acc:number,stage:ChannelStageModel)=>acc+this.calcStageCost(stage),0) || 0
    }
    // inaccurate calculations ((targetMonthly)1.98 / 50 * 100 = 3.95555555558)
    // Answer: inaccurate decimal calculations from 0 to 1 https://stackoverflow.com/a/5037927
    // Seems like this doesn't affect calculation results.
    // If checking against an excel model, check for any rounded (on display) values
    calcConverted(stage:ChannelStageModel, targetMonthlySales=this.targetMonthlySales):number{
        const {stages} = this;
        const index = stages.indexOf(stage);
        if(index == stages.length-1) return targetMonthlySales;
        const nextStage = stages[index+1];
        const { conversion } =  stage;
        const prevConverted = nextStage ? this.calcConverted(nextStage, targetMonthlySales) : 0;
        // const ret = doDecimalSafeMath(prevConverted, '/', conversion, 16) * 100
        const ret = prevConverted * 100 / conversion;
        // if(this.name == 'Блогеры' && index == 2) {
        //     console.log({stageIndex: index, prevConverted, ret, conversion, mathResult: prevConverted / conversion * 100});
        //     console.log((prevConverted * 100) / (conversion * 100), 0.0396 * 100)
        // }
        return ret == Infinity ? 0:ret||0;
    }
    calcStageCost(stage:ChannelStageModel, converted = this.calcConverted(stage), overrideTargetMonthlySales?:number):number{
        const {stageExpense} = stage;
        if(overrideTargetMonthlySales != undefined) converted = this.calcConverted(stage, overrideTargetMonthlySales)
        if(stageExpense.unit==undefined) stageExpense.unit = "rub";
        let cost:number;
        switch(stageExpense.unit){
            case "rub": cost = converted * stageExpense.amount; break;
            case "%": 
                // eslint-disable-next-line no-case-declarations
                let clientsPerSale = this._getProduct().clientsPerSalesUnit;
                if(clientsPerSale == undefined) clientsPerSale = 1;
                cost = this._getProduct().avgCheck / clientsPerSale / 100 * stageExpense.amount*converted;
                break;
            default: 
                console.warn({msg:'Unrecognized stage expense unit',unit:stageExpense.unit,stage}); 
                return 0;
        }
        return cost;
    }
    totalSales():number{
        const {product} = store.state;
        return this.targetMonthlySales * product.getApc() / product.clientsPerSalesUnit;
    }
    getPaidStages():ChannelStageModel[]{
        return this.stages.filter(({stageExpense})=>stageExpense.amount > 0);
    }
    wasChanged(channelType = this.channelType):boolean{
        const origType = channelTypes[channelType];
        if(origType == undefined || origType.stages.length != this.stages.length) 
            return true;

        //TODO: ?Check performance, rewrite with hashes
        const origStagesJson = origType.stages.map((stage)=>JSON.stringify(stage));
        const mismatchedStage = this.stages.find((stage)=>!origStagesJson.includes(JSON.stringify(stage)));
        // console.log({origType,type:this.channelType,match})
        return !!mismatchedStage;
    }
    /**
     * Applies retention to clients
     * @param clients applies retention to this amount of clients
     * @param retention customer retention (0-100)
     */
    static applyRetention(clients:number,retention:number){
        retention = Math.max(0,retention);
        return clients / (1 - retention / 100);
    }
    static utilReduceTotalSales(channels:ChannelModel[]){
        return channels.reduce((acc,channel)=>acc+channel.totalSales(),0);
    }
    static utilReduceTotalConverted(channels:ChannelModel[]){
        return channels.reduce((acc,channel)=>channel.targetMonthlySales+acc,0);
    }
    static setStagesFromPreset(channel:ChannelModel,preset:{stages:ChannelStageModel[],[x: string]:any}){
        if(!preset.stages || !preset.stages.length) return;
        const cloneStage = (stage:ChannelStageModel)=>{
            const ret = Object.assign({},stage);
            ret.stageExpense = Object.assign({},stage.stageExpense);
            return ret;
        }
        Vue.set(channel,'stages',[...preset.stages.map(cloneStage)]);
    }
}
export class ChannelStageModel{
    name:string;
    uuid:string;
    activationMonth = 1;
    conversion:number;
    stageExpense:ChannelStageExpenseModel;
    constructor(name='',conversion=100, stageExpense = new ChannelStageExpenseModel()){
        this.name = name;
        this.conversion = conversion;
        this.stageExpense = stageExpense;
        this.uuid = uuidv4();
    }
}
export class ChannelStageExpenseModel{
    name:string;
    amount:number;
    unit:string;
    constructor(name='',amount=0,unit='rub'){
        this.name = name;
        this.amount = amount;
        this.unit = unit;
    }
}
export class ProjectDataModel {
    id='';
    name='';
    watchedIds=[] as string[];
    likedIds=[] as string[];
    favoriteIds=[] as string[];
    created='';
    updated='';
    prevAuthorId=undefined as string|undefined;
    loadQuery=undefined as {tab?:'product'|'channels'|'budget', chart?:number}|undefined;
    invitationCode?='';
    isPublished?:boolean;
    orig?:ProjectDataModel;
    [x: string]: any;
    static projectSaveTypeFor(data:ProjectDataModel):('new-project'|'loaded-project'|'user-project'){
        if( data.orig && data.orig.author ){ 
            const isUserProject = data.author && data.author.id == (store.state.user as any).id;
            return isUserProject ? 'user-project' : 'loaded-project';
        }
        else return 'new-project';
    }
}
export class ProjectModel{
    product:ProductModel;
    channels:ChannelModel[];
    data:ProjectDataModel;
    [x: string]: any;
    constructor(product=new ProductModel(),channels=[new ChannelModel()],data=new ProjectDataModel()){
        this.product = product;
        this.channels = channels;
        this.data = data;
    }
    //needs to be refactored to regular method
    static deleteIds(project:any,channels:ChannelModel[]):void {
        delete project.id;
        delete project.author;
        delete project.created;
        delete project.updated;
        delete project.invitationCode;
        if(channels) channels.forEach((channel)=> delete (channel as any).id);
    }
    toJSON = ():ProjectModel => {
        const clone = Object.assign({},this);
        clone.product = Object.assign({},clone.product);
        delete (clone as any).data;
        clone.product.expenseUnits = UnitManager.getUnits().map((u)=>u.text);
        const cloneData = Object.assign({}, this.data);
        // if(cloneData.author) cloneData.author = {id: cloneData.author.id}
        return {...clone,...cloneData};
    }

    static FLAGS = Object.freeze( {
        EXEMPLARY: "exemplary",
        ADMIN_HIDDEN: "admin_hidden",
    } );

    static loadDefaultProject(){
        store.commit(SET_PRODUCT,new ProductModel());
        const channel = new ChannelModel();
        channel.channelType = 0;
        if(!store.state.isTesting){ 
            ChannelModel.setStagesFromPreset(channel,channelTypes[0]);
            channel.stages.forEach(({stageExpense})=>stageExpense.amount = 0);
        } else {
            ChannelModel.setStagesFromPreset(channel, testChannelType);
        }
        store.commit(SET_CHANNELS,[channel]);
        store.commit(SET_REFERENCER, new Referencer());
        const projectData = new ProjectDataModel();
        projectData.isPublished = true;
        Vue.set(store.state, 'projectData', projectData);
    }
    static async deleteProject(userPromise=new Promise((r)=>r()) as Promise<void>,projectId:number|string,onFail?:(e:any)=>void,onSuccess?:()=>void){
        try{
            await userPromise;
        }catch(e){ return; }
        try{
            await ApiService.delete(endpoints.deleteProject(projectId));
        }catch(e){
            if(!onFail){
                alert( 'При удалении проекта произошла ошибка\n'+escapeHtml(e+'') );
                location.reload();
            }else{ onFail(e); return; }
        }
        if(store.state.projectData && projectId == (store.state.projectData as any).id)
            store.commit(AFTER_ROUTE);
        if(onSuccess) onSuccess();
    }
}

export class LoadProjectDTO {
    id?:number|string;
    tab?:'product'|'channels'|'budget';
    chart?:number;
    invitationCode?:string;
    project?:Record<string,any>;
    constructor({id=undefined,invitationCode=undefined,project=undefined,tab=undefined,chart=undefined}:{id?:number|string,invitationCode?:string,project?:Record<string,any>,tab?:'product'|'channels'|'budget',chart?:number}={}){
        this.id = id;
        this.invitationCode = invitationCode;
        this.project = project;
        this.tab = tab;
        this.chart = chart;
    }
    isEmpty():boolean{
        const {id,invitationCode,project} = this;
        return id == undefined && invitationCode == undefined && project == undefined;
    }
}