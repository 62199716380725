






import Vue from 'vue';
import { detect } from 'detect-browser';
import { Route } from 'vue-router';
import { escapeHtml, isMobile } from '@/common/misc';

const msgKey = Object.freeze( 'displayed-unsupported-device-msg' );
const displayedValue = Object.freeze( 'true' );

//TODO: Use https://www.npmjs.com/package/outdated-browser-rework to detect outaded browsers, display a warning message with markdown links to firefox and chrome
export default Vue.extend({
    name: 'unsupportedDeviceWarning',
    data(){
        const supportedDeviceRoutes = this.$router.options.routes?.filter((r:any)=>r.meta.onMobile)
                .flatMap(({path,alias})=> [path, ...(Array.isArray(alias) ? alias : [alias])]) as string[];

        return{
            supportedDeviceRoutes,
            msgKey,
            displayedValue,
            showDialog: true,
        }
    },
    watch: {
        curRoute(){ this.showWarningIfUnsupported(); },
    },
    mounted(){ this.showWarningIfUnsupported(); },
    methods: {
        async showWarningIfUnsupported(){
            //TODO: Use storageService
            let dialogMsg = '';
            if(typeof Storage === 'undefined' || !localStorage) return;
            const item = localStorage.getItem(msgKey);
            if(item && item == displayedValue) return;

            const dontShowAgain = () => localStorage.setItem(msgKey,displayedValue);

            switch( detect()?.os ){
                case 'Windows Mobile':
                case 'BlackBerry OS':
                case 'iOS':
                case 'Android OS':
                case 'android':
                    dialogMsg += `Операционная система ${detect()?.os} временно не поддерживается.\nЧасть функционала может быть недоступна.`
                    break;
            }
            switch( detect()?.name ){
                case 'edge-ios':
                case 'ios-webview':
                case 'safari':
                    dialogMsg += (dialogMsg?'\n':'')+`Браузер ${detect()?.name} временно не поддерживается.\nЧасть функционала может быть недоступна.`
                    break;
            }
            const supportedBrowsers = ['Mozilla Firefox','Google Chrome'];
            const supportedOS = ['Windows'];
            //Check if url is supported on mobile (without trailing slash, except for home page)
            const isRouteOnMobile = this.supportedDeviceRoutes.includes(this.$route.path.replace(/\/$/, "")||'/');
            const isRouteSupported = !isMobile() || isRouteOnMobile;

            if(dialogMsg && !isRouteSupported) {

                dialogMsg += '\n\nРекомендуемые браузеры: \n'+supportedBrowsers.join(', ');
                dialogMsg += '\n\nРекомендуемые ОС: '+supportedOS.join(', ');

                this.showDialog = true;
                await Vue.nextTick();
                await Vue.nextTick();
                const handler = () => {this.$modal.hide('dialog'); this.showDialog = false;}
                // console.log({dialogMsg,isRouteSupported})

                this.$modal.show('dialog',{
                    title: 'Предупреждение',
                    text: escapeHtml(dialogMsg).split('\n').join('<br>'),
                    buttons: [ {title:'ОК',handler}, {title: 'Больше не показывать',handler:()=>{dontShowAgain(); handler();}} ]
                })
            }else this.showDialog = false;
        },
    },
    computed: {
        curRoute():Route{ return this.$route; },
    },
})
