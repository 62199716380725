import ApiService from '@/common/api.service';
import { ChannelStageModel, ProjectModel } from './classes/models';
import axios from 'axios';
import { Expense } from '@/common/classes/expenses';
import store from '@/store';
import { BizModel, BizModelOptions, ProjectPrivacyState } from '@/common/types/project.types';
import TariffService from '@/common/tariff.service';
import { WizardSlide } from '@/common/types/wizard.type';
import { v4 as uuidv4 } from 'uuid';
import router from '@/router';

export const productionTip = false

export const isRemote = (window as any).webpackHotUpdate;
export const API_URL = isRemote ? "https://192.168.39.1:8443" : ""
export const CAPTCHA_SITE_KEY = "6LeKbzEaAAAAAOKJHNbROZSV19gf4LLE1sZMvtRV";

export const DISPLAY_BOOK_LANDING_BANNERS = true;

const socialEndpoints = Object.freeze({
    //#region resources
    watchResource: (id:string|number) => `/api/resource/${id}?action=watch`,
    likeResource: (id:string|number) => `/api/resource/${id}?action=like`,
    unlikeResource: (id:string|number) => `/api/resource/${id}?action=unlike`,
    setFavoriteResource: (id:string|number) => `/api/resource/${id}?action=setFavorite`,
    unsetFavoriteResource: (id:string|number) => `/api/resource/${id}?action=unsetFavorite`,
    // likeResourceCollection: (id:string|number) => `/api/resource/collection/${id}?action=like`,
    // unlikeResourceCollection: (id:string|number) => `/api/resource/collection/${id}?action=unlike`,
    //#endregion
    //#region projects
    likeProject: (id:string|number):string => `/api/unit-economy/${id}?action=like`,
    unlikeProject: (id:string|number):string => `/api/unit-economy/${id}?action=unlike`,
    setFavoriteProject: (id:string|number):string => `/api/unit-economy/${id}?action=setFavorite`,
    unsetFavoriteProject: (id:string|number):string => `/api/unit-economy/${id}?action=unsetFavorite`,
    //#endregion
})

const adminEndpoints = Object.freeze({
    adminPanel: "/api/admin",
    adminGetDocs: "/api/admin/docs",
    adminPostDocs: "/api/admin/docs",
    adminGetOverrideTooltips: "/api/admin/tooltips",
    adminPutOverrideTooltips: "/api/admin/tooltips",
    adminImportResources: "/api/admin/resources/import",
    adminGetResourceCategories: "/api/admin/resources/categories",
    adminSaveResourceCategories: "/api/admin/resources/categories/save",
    adminGetResourceCollection: "/api/admin/resources/collections",
    adminSaveResourceCollection: "/api/admin/resources/collections/save",
    adminGetSuggestResources: `/api/admin/resources/suggested`,
    adminGetProjectReport: "/api/admin/reports/projects",
    adminGetUserReport: "/api/admin/reports/users",
    adminGetActionsReport: "/api/admin/reports/actions",
    adminGetLandingRequestsReport: "/api/admin/reports/landing-requests",
    adminGetFeedbackRequests: "/api/admin/support/feedback-requests",
    adminGetAllProjects: "/api/admin/project/all",
    adminGetPromoCodes: "api/admin/promo-codes/all",
    adminPutPromoCodes: "/api/admin/promo-codes/update",
    adminGetSurveyList: (key:string):string => `https://api.surveyjs.io/private/Surveys/getActive?accessKey=${key}`,
    adminGetSurveyResults: `/api/admin/quiz/results`,
    adminPostSaveQuizzes: `/api/admin/quiz/save-all`,
    adminGetPublicSurvey: (id:string):string => `https://api.surveyjs.io/public/Survey/getSurvey?surveyId=${id}`,
    adminPutExemplaryProject: (id:string|number,isExemplary:boolean):string => `/api/admin/project/${id}/exemplary-flag?value=${isExemplary?'1':'0'}`,
    adminPutAdminHiddenProject: (id:string|number,isExemplary:boolean):string => `/api/admin/project/${id}/admin-hidden-flag?value=${isExemplary?'1':'0'}`,
    adminDeleteResourceCollection: (id:string|number):string => `/api/admin/resources/collections/${id}`,
    adminAddResourceToCollection: (id:string|number):string => `/api/admin/resources/collections/${id}/save-resource`,
    // adminPutPublishSuggestedResource: (id:string|number):string => `/api/admin/resources/${id}/publish`,
    adminDeleteResource: (resourceId:string|number):string => `/api/admin/resources/${resourceId}`,
    adminDeleteResourceFromCollection: (collectionId:string|number,resourceId:string|number):string => `/api/admin/resources/collections/${collectionId}/delete-resource/${resourceId}`,
})

export const endpoints = Object.freeze({
    ...socialEndpoints,
    ...adminEndpoints,
    getTooltips: '/api/tooltips',
    getProject: (id:string|number):string=>`/api/unit-economy/${id}`,
    getProjectWithInvitationCode: (invitationCode:string):string=>`/api/unit-economy/invitation/${invitationCode}`,
    deleteProject: (id:string|number):string=>`/api/unit-economy/${id}`,
    changeUsername: `/api/user/update-name`,
    getUserOperations: `/api/user/operation/all`,
    postCancelPayment: (id:string) => `/api/payment?id=${id}&action=cancel`,
    redirShowPayment: (id:string) => `/payment?id=${id}`,
    postCancelAutoPay: (id:string) => `/api/payment?id=${id}&action=cancel-auto-pay`,
    vkPopupAuth: `/api/authenticate/vkontakte`,
    getQuizzes: `/api/quiz/all`,
    getStartQuiz: (id:string) => `/api/quiz/by-var/${id}?check_limit=1`,
    postCompleteQuiz: (id:string) => `/api/quiz/by-var/${id}/complete`,
    getProjects: `/api/unit-economy/all`,
    getExemplaryProjects: `/api/unit-economy/all/exemplary`,
    getMyProjects: `/api/unit-economy/my`,
    getCurrentEmail: `/api/user/current-email`,
    putUpdateUsername: `/api/user/update-name`,
    putUpdateNotificationEmail: (email:string) => `api/user/set-notification-email?email=${email}`,
    saveProject: `/api/unit-economy/save`,
    getResourceCollections: `/api/resource/collection/all`,
    getTariffs: `/api/tariff/all`,
    getTariffPreview: (id:string|number) => `/api/tariff/preview/${id}`,
    postBuyTariff: `/api/tariff/buy`,
    postTryDiscountPromoCode: (tariffId:string|number, promoCode:string|number) => `/api/tariff/${tariffId}/try-discount-promo/${promoCode}`,
    postActivatePromoCode: (promoCode:string|number) => `/api/tariff/activate-promo/${promoCode}`,
    postForgotPassword: `/api/user/forgot-email-password`,
    postFeedbackRequest: `/api/support/feedback-request`,
    postLandingDiscountRequest: `/api/landing/discount-request`,
    postLandingContactAuthorRequest: `/api/landing/contact-author-request`,
    putResetPassword: `/api/user/reset-password-token`,
    postUserProfilePicture: `/api/user/picture`,
    postSuggestResource: (id:string|number) => `/api/resource/collection/${id}/suggest`,
    getResourceCollectionById: (id:string|number) => `/api/resource/collection/${id}`,
});

//TODO: Replace default project image with static file
let defaultProjectImage:any,imageSet:any;
let presetImagesFetch:any;
export const fetchProjectPresetImages = async ()=>{
    //eslint-disable-next-line prefer-const
    if(defaultProjectImage&&imageSet) return {defaultProjectImage,imageSet};
    if(store.state.isTesting) return {defaultProjectImage,imageSet};
    if(!presetImagesFetch)
        presetImagesFetch = axios.get("/project-preset-images.json");
    const {basePath,defaultFilename,filenames} = await (await presetImagesFetch).data;
    presetImagesFetch = undefined;
    const FNAME_PLACEHOLDER = "*FILENAME*";
    defaultProjectImage = basePath.replace(FNAME_PLACEHOLDER,defaultFilename);
    imageSet = filenames.map((fname:string)=>basePath.replace(FNAME_PLACEHOLDER,fname));
    return {defaultProjectImage,imageSet}
}

export const fetchHomepageProjects = async ():Promise<ProjectModel[]>=>{
    const responses:ProjectModel[] = [];
    const parsed = await (await axios.get("/home-projects.json")).data;
    const homepageProjectIds:number[] = parsed.ids;
    const promises = homepageProjectIds.map((id)=>ApiService.get(endpoints.getProject(id)).then(({data})=>responses.push(data.project)));
    await Promise.all(promises);
    return responses;
};

function toExpArr(arr:string[][]){
    const mapped = {
        unit: [] as Expense[],
        const: [] as Expense[],
        oneTime: [] as Expense[],
        getAll(){ return [...this.unit,...this.const,...this.oneTime] }
    };
    mapped.unit = arr[0].map((name)=>new Expense(name,0,[]));
    mapped.const = arr[1].map((name)=>new Expense(name,1,[]));
    mapped.oneTime = arr[2].map((name)=>new Expense(name,2,[]));
    return mapped;
}

export const getExpensePresets:()=>readonly Expense[] = ()=>{
    const arr = toExpArr( [ 
        ['Эквайринг', 'Налоги с выручки', 'Прочие (переменные)', 'Непредвиденные (переменные)', 'Маркетинг и продажи'],
        ['Реклама (постоянные)', 'Обслуживание и ремонт оборудования', 'Зарплаты (постоянные)', 'Налоги и сборы на зарплату (постоянные)', 'Аренда помещения (помесячно)', 'Коммунальные расходы (помесячно)', 'Оплата хостинга/сервера/защиты', 'Бухгалтерское сопровождение', 'Услуги банка', 'Обслуживание кредита', 'Прочие (постоянные)', 'Непредвиденные (постоянные)'],
        ['Закупка оборудования', 'Аванс по аренде', 'Создание сайта, покупка домена', 'Юридическое оформление', 'Прочие (разовые)', 'Непредвиденные (разовые)']
    ] );

    return Object.freeze( arr.getAll() )
};

//TODO: MAKE SURE REST OF CARDS ARE NOT TOO BIG FOR 720p
export const supportCards:readonly{name:string,description:string}[] = Object.freeze( [
    {
        name:'Что такое сообщество izibiz?',
        description:'Это сайт, где мы помогаем считать экономику бизнес-проектов. Мы считаем главной его ценностью ― встроенный калькулятор юнит-экономики. После регистрации на сайте тебе откроется доступ к типовым моделям бизнеса в образовании, общепите, онлайн-торговле и возможность делиться своими расчетами по ссылке. Это место единомышленников. Мы объединяем ребят, которые хотят научиться самостоятельно зарабатывать деньги и грамотно подходить к открытию своего бизнеса. Это место для развития. Вдохновение и профессиональные знания ты найдешь в разделе «Ресурсы» на сайте izibiz.club. Мы создали библиотеку для всех, кто хочет развиваться и учиться предпринимательскому делу.',
    },
    {
        name:'В каком возрасте можно вступать в клуб izibiz?',
        description:'Наше сообщество существует только онлайн. Материалы нашего сайта будут полезны ребятам от 12 лет, а также начинающим предпринимателям и педагогам бизнес-школ. Регистрация на сайте бесплатна для всех.',
    },
    {
        name:'Как пользоваться калькулятором экономики проекта?',
        description:'Посмотреть инструкцию на примере разбора юнит-экономики занятия с детьми можно на нашем YouTube-канале. Чтобы начать тестировать расчеты, пройдите <a href="https://izibiz.club/create">в раздел «Юнит-экономика».</a>'
    },
    {
        name:'Сколько стоит сервис izibiz.club?',
        description://'Сколько стоит сервис izibiz.club?\n'+
        'Основные возможности сайта бесплатны: расчёт юнит-экономики и поиск ресурсов для углубления знаний. Достаточно зарегистрироваться. '+
        // 'Вступая в клуб (оплачивая подписку) можно получить доступ к новым возможностям.\n'+
        // '\tТариф «Практика» (99 ₽) позволяет сохранять 10 проектов, закрывать проект от просмотра другими и сохранять его в PDF и PowerPoint, открывает коллекцию готовых проектов юнит-экономики из разных отраслей.\n'+
        // '\tТариф «Реальный бизнес» (1590 ₽) открывает возможность сохранять неограниченное количество проектов юнит-экономики, выгружать их не только PDF и PowerPoint, но и в Excel, а к коллекции готовых проектов из разных отраслей добавляет опцию их сравнения.\n'+
        'Приобрести дополнительный тариф можно через <a href="https://izibiz.club/settings">личный кабинет</a> после регистрации. Подробнее о тарифах можно прочитать в разделе <a href="https://izibiz.club/settings?tab=3">«Тарифы»</a>.'
    },
    {
        name:'Чем полезна регистрация на izibiz.club?',
        description:'Сразу после регистрации на сайте можно:\n'+
        '1. Сохранять расчеты для 3-х проектов и вернуться к ним в любое время.\n'+
        '2. Получить доступ к типовым проектам для готового бизнеса.\n'+
        '3. Делиться своими расчетами с друзьями по готовой ссылке.\n'+
        '4. Сохранять подборки ресурсов из нашей базы знаний.\n'+
        '5. Бесплатно получить рекомендации по твоим расчетам. Мы также поможем с теорией, если это потребуется.'
    },
    // {
    //     name:'Платные тарифы пользователей izibiz.club',
    //     description:'Регистрируясь на сайте, пользователю становятся доступны скрытые возможности портала. Покупка ежемесячной подписки увеличивает количество бонусов.\n'+
    //     '\tТариф «Практика» (99 ₽) позволяет сохранять 10 проектов, закрывать проект от просмотра другими и сохранять его в PDF и PowerPoint, открывает коллекцию готовых проектов юнит-экономики из разных отраслей.\n'+
    //     '\tТариф «Реальный бизнес» (1590 ₽) открывает возможность сохранять неограниченное количество проектов юнит-экономики, выгружать их не только PDF и PowerPoint, но и в Excel, а к коллекции готовых проектов из разных отраслей добавляет опцию их сравнения.\n'+
    //     'Приобрести тариф можно через <a href="https://izibiz.club/settings">личный кабинет</a> после регистрации. Подробнее о тарифах можно прочитать в разделе «Тарифы».'
    // },
    // {
    //     name:'Что происходит после окончания тарифа?',
    //     description:'Все ваши проекты останутся в вашем распоряжении навсегда, даже когда срок действия пакета истечёт.\n'+
    //     // 'Об окончании пакета вас предупредит письмо, отправленное на адрес электронной почты, указанный при оплате.\n'+
    //     'После того, как закончится действие оплаченного тарифа, появятся ограничения по созданию и сохранению новых проектов.\n'+
    //     'Если количество проектов превышает лимит бесплатного тарифа, то вы не сможете сохранять новые проекты, в том числе делать копии чужих проектов.\n'+
    //     'Продлить пакет всегда можно в личном кабинете. '
    // },
    {
        name:'На сайте что-то не работает. Как связаться с техподдержкой?',
        description:'Отправить сообщение об ошибке можно нам на имэйл mail@izibiz.club или в мессенджер по номеру +7 (917) 579 16 20 (Whatsapp или Telegram).',
    },
] );

export const tooltipsText = Object.freeze(
    {
        //#region product-info
        industry: 'Отрасль, в которой реализуется проект. Выбирая отрасль ты сможешь увидеть примеры затрат в этой отрасли.',
        bizModel: 'Ответ на вопрос "Как именно ты будешь зарабатывать?" Выбор бизнес-модели влияет на то, как будут вычисляться некоторые результаты проекта."',
        unit: 'Название выбранной единицы для расчёта юнит-экономики. За единицу можно взять единицу продукции (хот дог или скворечник), одну покупку (билет на концерт, заказ в магазине или кафе) или, например, одно мероприятие (концерт или урок).',
        avgCheck: 'Средняя сумма, потраченная клиентом на одну среднюю покупку. Рассчитывается как выручка делённая на количество продаж. Если продажи различаются от месяца к месяцу, то считается среднее по году. Если есть несколько продуктов, то можно либо рассчитать экономику каждого продкта в отдельности, либо в расчёте брать средние показатели с учётом доли продуктов.',
        clientsPerSalesUnit: '',
        retention: 'Конверсия в повторную продажу клиенту. Рассчитывается исходя из среднего количества покупок одним клиентом (APC) по формуле Ретеншн = 1-1/APC',
        chartUnit: 'Расчёты на одну единицу',
        chartClient: 'Расчёты на одного клиента (он может сделать несколько покупок)',
        chartMonth: 'Расчёты на один месяц',
        chartRange: 'Расчёты на весь проект - на заданное количество месяцев',
        //#endregion
        //#region product-info-computed
        unitProfitNoMarketing: 'Разница между стоимостью единицы продукции для покупателя (средним чеком) и переменными затратами без учёта затрат на маркетинг и продажи.',
        unitProfitPercent: 'Доля маржи в выручке. Рассчитывается как маржа, делённая на выручку, указывается в процентах.',
        saleCost: 'Маркетинговые затраты, которые необходимо в среднем понести, чтобы сделать продажу одной единицы продукции. Рассчитывается как CAC/APC',
        unitProfit: 'Сумма, которую приносит каждая дополнительная продажа. Рассчитывается как разница между стоимостью единицы продукции для покупателя (средним чеком) и переменными затратами с учётом затрат на маркетинг и продажи.',
        apc: 'Average Payment Count. Среднее количесво покупок, которое сделает один новый клиент за всё время, пока он остаётся клиентом. ',
        ltv: 'Life Time Value клиента. Маржа, которую принесёт нам клиент за всё время, пока он остаётся нашим клиентом. Например, если я много лет являюсь клиентом мобильного оператора (МТС, Билайн, Мегафон...) то оператор рассчитывает мой LTV как сумму всех денег, которые я ему принесу за всё время, пока я являюсь их клиентом, за вычетом прямых затрат на моё обслуживание. Человек не может быть вечно клиентом, можно сделать оценку средней длительности контракта.',
        cac: 'Customer Acquisition Cost. Затраты на привлечение одного нового клиента, рассчитывается как сумма затрат на маркетинг и продажи, делённая на количество привлечённых клиентов (покупок, единиц).',
        ltvCacRation: 'Важнейший показатель экономического здоровья проекта и канала. Это отношение должно быть больше 1, но лучше - больше 3 или даже 5.',
        unitProfitOneClient: 'Сумма, которую приносит каждый дополнительный клиент. Разница между LTV  и CAC',
        unitsTillEven: 'Количество продаж, которые нам нужно иметь в месяц, чтобы окупить постоянные затраты. Рассчитывается делением постоянных затрат на маржу на одной продаже.',
        targetMonthlySales: 'Прогнозное количество новых клиентов в месяц. Речь идёт о идеальном месяце, когда бизнес заработает в полную силу. Новые клиенты могут появляться через несколько разных каналов.',
        totalSales: 'Количество единиц, которые будут продаваться в месяц. Рассчитывается, как количество новых клиентов, умноженное на среднее количество покупок (APC)',
        monthlyEarnings: 'Сумма, полученная от всех клиентов. Рассчитывается, как количество месяцев, умноженное на количество продаж в месяц, умноженное на средний чек',
        monthlyProfit: 'Чистый заработок в месяц. Рассчитывается как чистая маржа минус постоянные затраты.',
        monthlyCleanMargin: 'Выручка минус переменные затраты, включая затраты на маркетинг и продажи',
        regionUnitExpensesAndMarketing: 'Переменные затраты, которые нужно понести для обеспечения продаж и привлечения клиентов в расчёте на выбранный период. Рассчитываются как (Переменные затраты на единицу + Стоимость одной продажи), умноженные на Количество новых клиентов в месяц, умноженные на Среднее количество покупок одним клиентом, умноженное на количество месяцев.',
        regionMonthlyExpensesWithTaxes: '',
        regionMonthlyExpenses: 'Постоянные затраты за период, рассчитываются как Количество месяцев в выбранном периоде, умноженное на Постоянные затраты за месяц',
        regionMonthlyProfit: 'Чистый заработок. Рассчитывается как чистая маржа минус постоянные затраты.',
        regionProfit: 'Чистый доход от проекта за выбранный период с учётом всех видов затрат. Рассчитывается, как Прибыль минус Разовые затраты.',
        monthlyProfitPercent: 'Показатель, который рассчитывается за месяц (или год) по формуле: Рентабельность = Прибыль / Выручка',
        monthsTillEven: 'Количество месяцев с начала запуска проекта до того момента, когда все начальные затраты будут покрыты полученной прибылью. В рамках юнит-экономики точка окупаемости оценивается, как разовые затраты, делённые на среднемесячную прибыль.',
        singleClientEarnings: 'Сумма денег, которую заплатит нам клиент за всё время, пока он остаётся нашим клиентом. Этот показатель особо важен, если есть постоянные клиенты или если бизнес-модель - подписка. Человек не может быть вечно клиентом, можно сделать оценку средней длительности контракта.',
        earningsPerGroup: '',
        //#endregion
        //#region budget-info-computed
        compCf: 'График чистых денежных потоков по месяцам. Чистый денежный поток за месяц считается, как сумма всех доходов (кроме привлечённого финансирования) минус сумма всех расходов за месяц. ',
        compAccCf: 'График чистых денежных потоков по месяцам накопительным итогом. Чистый денежный поток за месяц считается, как сумма всех доходов (кроме привлечённого финансирования) минус сумма всех расходов. "Накопительным итогом" означает, что мы читаем сумму по всем месяцам, начиная с первого и до текущего.',
        //#endregion
        //#region product
        unitExpenses: 'Затраты, которые напрямую зависят от объёма продаж, например, затраты на ингредиенты для производства.',
        monthlyExpenses: 'Затраты, которые мы несём регулярно, независимо от объёма продаж, например, арендные платежи или оплата хостинга.',
        annualExpenses: 'Затраты которые необходимо понести один раз в начале проекта для его запуска. К разовым затратам относятся, например, закупка оборудования или разработка сайта.',
        expenseName: 'Название статьи затрат',
        expenseInputCost: 'Стоимость',
        expenseInputPricePerUnit: 'Стоимость за единицу',
        expenseSelectReference: 'Ссылка на затрату',
        expenseSelectUnit: 'Единица измерения',
        expenseInputAmount: 'Количество',
        structureSwitch: 'Включить/выключить разделение затрат на переменные, постоянные и разовые',
        costSwitch: 'Включить/выключить демонстрацию стоимости по каждой из затрат',
        foldExpense: ''/*'Развернуть статью затрат'*/,
        addSubExpense: 'Добавить новую подстатью затрат',
        expenseTypeCost: 'Стоимость статьи затрат вводится вручную в рублях (например, указывается размер арендной платы)',
        expenseTypeUnit: 'Вводится количество и цена за единицу, стоимость рассчитывается автоматически, как количество, умноженное на цену. Например, площадь помещений (50 кв.м.), единица, на которую известна цена (1 кв.м.) и цена за единицу (размер арендной платы за 1 кв.м. - 1000₽). Тогда стоимость будет рассчитана как 50х1000/1 = 50 000₽.',
        expenseTypeRef: 'Стоимость рассчитывается пропорционально другой величине из модели, например, налог с оборота = 6% от среднего чека или обслуживание оборудования = 2% от стоимости оборудования.',
        deleteExpense: ''/*'Удалить статью затрат'*/,
        selectProductTax: '',
        productTaxRate: '',
        //#endregion
        //#region channel
        channelName: 'В названии канала можно указать и канал продвижения, и целевую аудиторию, например, "Таргет в инстаграм, 16-18 лет, Москва".',
        channelType: 'Выбор типа канала помогает установить этопы, конверсии и стоимость этапов по умолчанию. Если нужного типа канала нет, можно использовать любой другой в качестве шаблона, а потом поменять все его параметры.',
        channelTargetFraction: 'Если канал только один, то доля канала = 100%. Если каналов несколько, то можно указать, какой процент новых клиентов через какой канал приходит. Затраты на канал рассчитываются, исходя из целевого количества новых клиентов в месяц. Например, если цель = 30 новых клиентов в месяц, а доля канала = 20%, то количество оплат в этом канале будет равно 6 (это 20% от 30). Доля последнего в списке каналов рассчитывается автоматически так, чтобы сумма всех долей составила 100%.',
        channelTargetMonthlySales: 'Плановое количество новых клиентов в месяц, привлечённых через этот канал',
        channelConversionSwitch: 'Включение конверсий. Конверсия - это доля клиентов, которые переходят из одного положения в воронке продаж в другое.',
        channelCostSwitch: 'Включение расчёта стоимости привлечения клиентов.',
        channelAddStage: 'Добавить этап воронки продаж',
        channelDeleteStage: 'Удалить этап воронки продаж',
        channelOptionsMenu: 'Операции с каналами',
        channelExpenses: 'Сумма всех затрат по данному каналу за месяц',
        channelSingleSalesCost: 'Затраты на маркетинг и продажи, которые необходимо в среднем понести, чтобы сделать продажу одной единицы продукции. Рассчитывается как стоимость привлечения нового клиента (CAC), делённая на количество новых клиентов в месяц (APC)',
        channelStageName: 'Этапы воронки продаж проходят от первого контакта с человеком (потенциальным клиентом), например, с момента, когда он увидел рекламу (оцениваем количество "Просмотров"), и до превращения его в платящего клиента (количество "Оплат"). Количество оплат по всем каналам в сумме составляет Количество новых клиентов.',
        channelStageConverted: 'Количество на каждом этапе рассчитывается автоматически, исходя из нужного количества новых платящих клиентов.',
        channelStageConversion: 'Конверсия - это доля клиентов, которые переходят из одного этапа воронки в другой. Например, если "Конверсия из кликов в регистрации" составляет 5%, это означает, что из каждых 100 человек, которые кликнули на рекламный баннер, в среднем 5 человек зарегистрируются на сайте.',
        channelStageExpenseName: 'Название затраты на данном этапе воронки продаж.',
        channelStageExpenseAmount: 'Размер затраты на данном этапе воронки продаж. Затраты могут быть указаны в процентах (от среднего чека) или в рублях.',
        channelStageExpenseUnit: 'Затраты могут быть указаны в процентах (от среднего чека) или в рублях.',
        channelStageExpenseCost: 'Общий размер затрат на данном этапе воронки продаж за месяц.',
        channelStageOptionsMenu: 'Операции с этапами воронки',
        monthChannelExpense: 'Сумма всех маркетинговых затрат для привлечения клиентов через этот канал',
        // newClientCost: 'Сколько надо потратить денег, чтобы один новый клиент дошёл до оплаты. Рассчитывается, как Всего затрат на маркетинг и продажи / Новых клиентов за месяц.',
        //#endregion
        //#region budget
        switchMonthlyYearlyBudget: 'Бюджет можно показать в расчёте по годам или по месяцам',
        monthsTillTargetSales: 'Условный срок выхода на плановые показатели по продажам. Если в юнит-экономике мы предполагаем, что бизнес уже полностью работает, то в реальности мы не сразу добиваемся нужного количества продаж в месяц. Если "Количество месяцев до выхода на плановые продажи" равно 10, это значит, что в первый месяц количество новых клиентов = 1/10 от того, которое указано в юнит-экономике, второй месяц - 2/10 и т.д.',
        saleCycleDuration: 'Зачастую маркетинговые затраты приходится нести за несколько месяцев до того, как появится оплата от клиентов. Этот период и называется "продолжительность цикла сделки".',
        clientReturnTime: 'Если у нас есть повторные продажи (APC > 1), это значит, что часть клиентов вернётся через некоторое время. Для построения бюджета нам важно знать время, через которое он вернётся (в среднем). Оно измеряется в днях. Если оно меньше 30 (например, человек заходит  в кафе каждый день до работы), то за месяц у нас будет несколько повторных покупок. Если больше 30, (например, человек ходит каждые три месяца в парикмахерскую), то у нас будет всего нескольк повторных покупок в год.',
        discountingRate: 'Ставка дисконтирования - это оценка "стоимости денег", то есть уровня ожидаемой доходности инвестиций (в год) с учётом риска проекта. Она может быть 20%, 30% или даже 50% для высокорисковых проектов. Как правило, ставка выше доходности по депозитам, котторые считаются низкорисковыми. От ставки дисконтирования зависит оценка бизнеса методом дисконтированных денежных потоков (NPV).',
        investmentVolume: 'Это сумма, которую надо иметь в наличии в начале проекта, для того, чтобы не остаться без денег раньше, чем проект начнёт окупаться. Рассчитывается, как минимальное значение чистых денежных потоков накопительным итогом. Чистый денежный поток за месяц считается, как сумма всех доходов (кроме привлечённого финансирования) минус сумма всех расходов. Накопительным итогом означает, что мы читаем сумму по всем месяцам, начиная с первого и до текущего.',
        paybackPeriod: 'Месяц, начиная с которого чистый денежный поток накопительным итогом станет больше нуля, то есть прибыль покроет все инвестиции. Накопительным итогом означает, что мы читаем сумму по всем месяцам, начиная с первого и до текущего.',
        breakevenPoint: 'Месяц, начиная с которого появляется прибыль, то есть ежемесячные доходы начинают превышать расходы',
        npv: 'Бизнес можно оценивать по-разному. Один из способов - метод дисконтированных денежных потоков. Он предполагает, что 1) мы можем прогнозировать чистые денежные потоки (FCF) по месяцам на пару-тройку лет вперёд и 2) у нас задана ставка дисконтирования (R) - это ожидаемый уровень годовой доходности проектов со схожим уровнем риска. Если всё так, то расчёт делается следующим образом: чистый денежный поток каждого месяца (FCFn) умножается на коэффициент дисконтирования данного месяца, и полученные дисконтированные денежные потоки суммируются по всему периоду прогнозирования. А затем прибавляется стоимость проекта в конце периода. Итоговая велицина называется NPV (Net Present Value или чистая приведённая стоимость). Коэффициент дисконтирования рассчитывается, как 1/(1+r)^n, где r - это месячная ставка дсконтирования, а n - номер месяца. Месячная ставка дсконтирования рассчитывается, как (1+R)^(1/12)-1. Стоимость проекта в конце периода рассчитывается, как чистый денежный поток последнего прогнозного месяца, делённый на r и умноженный на коэффициент дисконтирования того же месяца.',
        irr: 'По определению IRR проекта равен такой годовой ставке дисконтирования R, при которой NPV проекта обращается в ноль. Находится подбором (можно проверить в Excel - там для этого есть функция IRR или - в русской версии - ВНР). Если объём финансированиия нулевой (денег не надо), то IRR не определён, т.к. NPV всегда больше нуля.',
        moneyFlow: 'График чистых денежных потоков по месяцам. Чистый денежный поток за месяц считается, как сумма всех доходов (кроме привлечённого финансирования) минус сумма всех расходов.',
        moneyFlowAccum: 'График чистых денежных потоков по месяцам накопительным итогом. Чистый денежный поток за месяц считается, как сумма всех доходов (кроме привлечённого финансирования) минус сумма всех расходов. "Накопительным итогом" означает, что мы читаем сумму по всем месяцам, начиная с первого и до текущего.',
        seasonality: '',
        //#endregion
        //#region misc
        featureRequiresAuthentication: 'Для получения доступа к этой функции необходимо авторизоваться',
        featureRequiresTariff: 'Для получения доступа к этой функции необходимо перейти на новый тариф',
        wizardButton: 'Пошаговая инструкция',
        userProfile: 'Личный кабинет',
        logout: 'Выйти',
        //#endregion
        //#region unit economy project
        projectName: 'Название проекта. Если поделиться проектом, именно это название будет отображаться.',
        projectImage: 'Эта картинка будет показываться вместе с названием и описаним проекта в каталоге проектов. На данный момент можно выбрать только картинку из заранее сделанной подборки. Но мы работаем над тем, чтобы можно было загрузить свою картинку.',
        projectDescription: 'Краткое описание проекта, которое можно будет посмотреть в каталоге проектов. Здесь важно описать суть продукта, целевую аудиторию, бизнес-модель (за что именно и как часто будут платить клиенты), каналы привлечения новых клиентов.',
        saveProject: 'Сохранить проект. Если вы просматриваете чужой проект, то сохранение означает сохранение копии проекта в вашем аккаунте. При этом вы сможете поменять название проекта.',
        copyProject: 'Сохранить копию этого проекта. Вся информация по проекту будет скопирована в новый проект. При этом вы сможете поменять название проекта, чтобы не путать их.',
        shareProject: 'Поделиться со всеми или только с некоторыми людьми (по ссылке)',
        foldDescription: 'Свернуть описание проекта',
        unfoldDescription: 'Развернуть описание проекта',
        //#endregion
        //#region resource
        resourceCost0: '100% бесплатный',
        resourceCost1: 'Бесплатный в начале',
        resourceCost2: 'Платный',
        //#endregion
    }
);

const stage = (name:string,conversion:number,expenseName:string,expenseAmount:number,expenseUnit:string) => {
    return {
        name,
        conversion,
        activationMonth: 1,
        uuid: uuidv4(),
        stageExpense:{
            name:expenseName,
            amount:expenseAmount,
            unit:expenseUnit,
        }
    }
};
type Industry = {
    name:string;
    unitName:string;
    presets:Expense[];
}

export const getIndustries:()=>readonly Industry[] = () => Object.freeze( [
    {
        name:'Общественное питание',
        unitName:'Средняя продажа',
        presets: toExpArr([
            ['Продукты','Расходные материалы','Производство','Упаковка','Доставка','Утилизация'],
            [],
            ['Ремонт помещения','Начальная закупка продуктов','Начальная закупка расходников','Одежда','Меню'],
        ]).getAll()
    },
    {
        name:'Оказание услуг',
        unitName:'Средняя услуга',
        presets: toExpArr([
            ['Зарплата специалиста (по часам)', 'Налоги и сборы на зарплату (переменные)'],
            [],
            ['Ремонт помещения', 'Разработка методических материалов', 'Обучение педагогов', 'Получение лицензии'],
        ]).getAll()
    },
    {
        name:'Образование',
        unitName:'Средняя группа',
        presets: toExpArr([
            ['Зарплата педагога (по часам)', 'Налоги и сборы на зарплату (переменные)', 'Аренда помещения (по часам)', 'Еда и напитки', 'Сертификаты', 'Подарки участникам', 'Возвраты'],
            [],
            ['Ремонт помещения', 'Разработка методических материалов', 'Обучение педагогов', 'Получение лицензии'],
        ]).getAll()
    },
    {
        name:'Производство',
        unitName:'Средняя единица продукции',
        presets: toExpArr([
            ['Зарплата (производство, по часам)', 'Налоги и сборы на зарплату (переменные)', 'Сырьё', 'Электроэнергия', 'Вода', 'Упаковка', 'Доставка', 'Брак'],
            ['Уборка помещений', 'Аренда склада'],
            ['Ремонт помещения', 'Тестирование технологии', 'Обучение мастров', 'Аванс по аренде склада', 'Одежда'],
        ]).getAll()
    },
    {
        name:'Торговля',
        unitName:'Средняя продажа',
        presets: toExpArr([
            ['Доставка', 'Возвраты'],
            ['Доработка платформы', 'Аренда склада'],
            ['Аванс по аренде склада'],
        ]).getAll()
    },
    {
        name:'Шоу бизнес',
        unitName:'Среднее мероприятие',
        presets: toExpArr([
            ['Зарплаты исполнителей и ведущих', 'Налоги и сборы на зарплату (переменные)', 'Аренда площадки (по часам)', 'Уборка помещений', 'Еда, напитки, расходники', 'Подарки участникам', 'Обновление лендинга', 'Авторский гонорар (переменный)'],
            [],
            ['Декорации и костюмы', 'Разработка программы', 'Авторский гонорар (разовый)'],
        ]).getAll()
    },
    {
        name:'Приложение',
        unitName:'Средняя продажа',
        presets: toExpArr([
            ['Комиссия продавца'],
            ['Доработка приложения'],
            ['Публикация приложения', 'Разработка приложения'],
        ]).getAll()
    },
    {
        name:'Прочие',
        unitName:'',
        presets: []
    }
] );

export const getBizModels: () => readonly BizModel[] = () => {
    const defaultOptions:BizModelOptions = {
        allowClientPerSale: false,
    }
    function bModel(name:string,options=defaultOptions):BizModel{
        return {
            name,
            ...options
        };
    }
    return Object.freeze( [
        bModel('Разовая продажа'),
        bModel('Подписка'),
        bModel('Комиссия с транзакции'),
        bModel('Фримиум'),
        bModel('Аукцион'),
        bModel('Двусторонняя платформа'),
        bModel('Сколько заплатишь'),
        bModel('Прочее'),
        bModel('Групповая продажа',{allowClientPerSale:true}),
    ] );
}

export const getAllProjectPrivacyStates = ()=>{
    const traitTypes = TariffService.getTraitTypes();
    return [
        {
            display:'Приватный',
            name:'private',
            // requiredTrait:traitTypes.PROJECT_VISIBILITY_PRIVATE
        },
        {
            display:'По ссылке',
            name:'protected',
            // requiredTrait:traitTypes.PROJECT_VISIBILITY_PROTECTED
        },
        {
            display:'Публичный',
            name:'public'
        }
    ] as ProjectPrivacyState[];
}

type FooterLinkCollection = {label:string,links:FooterLink[]}[];
type FooterLink = {
    name:string;
    route:string;
    redirectOnClick?:()=>boolean;
    authOnly?:boolean;
    newTab?:boolean;
    onMobile?:boolean;
}

export type {Industry,FooterLinkCollection,FooterLink};

export const footerData:FooterLinkCollection = 
    [
        {
            label: 'Проект',
            links: [
                {name:'О проекте',route:'/about'},
                {name:'Частые вопросы',route:'/support'},
                {name:'Пользовательское соглашение',route:'/tos.pdf',newTab:true, onMobile: true},
                {name:'Политика по персональным данным',route:'/policy-personal-data.pdf',newTab:true, onMobile: true},
                {name:'Контакты',route:'/support?showModal=1&reason=contacts',authOnly:true}
            ]
        },
        {
            label: 'Личный кабинет',
            links: [
                {name:'Учётная запись',route:'/settings?tab=1',authOnly:true},
                {name:'Пароль',route:'/settings?tab=2',authOnly:true},
                {name:'Тарифы',route:'/settings?tab=3'},
            ]
        },
        {
            label: 'Юнит-экономика',
            links: [
                {
                    name:'Инструкция',
                    route:'/create?wizard=1', 
                    redirectOnClick(this:any):boolean{
                        this.$emit('show-wizard-overlay'); 
                        return router.currentRoute.path != '/create'; 
                    } 
                },
                {name:'Словарь терминов',route:''},
                {name:'Найти проекты юнит-экономики',route:'/projects',onMobile:true},
                {name:'Конкурсы проектов юнит-экономики',route:''},
            ]
        },
        {
            label: 'Ресурсы',
            links: [
                {name:'Найти полезные ресурсы',route:'/resources',onMobile:true},
                {name:'Предложить ресурс',route:'/resources?suggest=1',authOnly:true},
                {name:'Пожаловаться на ресурс',route:'/support?showModal=1&reason=report-resource',authOnly:true},
            ]
        },
    ];

export const maxResourcesInsideModal = 80;

export const testChannelType:{name:string,stages:ChannelStageModel[]} = Object.freeze( {
    name: "Таргет (test)",
    stages: [
        stage('Просмотр',1,'Стоимость показа',0,'rub'),
        stage('Клик', 20, '', 0,'rub'),
        stage('Переписка', 10, 'Время менеджера по продажам', 0, 'rub'),
        stage('Заказ', 80, '', 0, 'rub'),
        stage('Оплата', 100, 'Бонус менеджера по продажам', 0, 'rub')
    ],
} );

export const channelTypes:readonly{name:string,stages:ChannelStageModel[]}[] = Object.freeze( [
    {
        name: "Таргет",
        stages: [
            stage('Просмотр',0.5,'Стоимость показа', 0,'rub'),
            stage('Клик', 20, 'Стоимость клика', 20, 'rub'),
            stage('Переписка', 10, 'Время менеджера по продажам', 50, 'rub'),
            stage('Заказ', 80, 'Подарок с первым заказом', 200, 'rub'),
            stage('Оплата', 100, 'Бонус менеджера по продажам', 1, '%')
        ]
    },
    {
        name: "Наружная реклама",
        stages: [
            stage('Трафик', 5, 'Стоимость рекламы в пересчёте', 0.1, 'rub'),
            stage('Увидели', 1, '', 0,'rub'),
            stage('Связались', 40, 'Время менеджера по продажам', 100, 'rub'),
            stage('Заказали', 80, 'Подарок с первым заказом', 200, 'rub'),
            stage('Оплата', 100, 'Бонус менеджера по продажам', 1, '%')
        ]
    },
    {
        name: "Прямые продажи - B2C",
        stages: [
            stage('Контакт', 20, 'Стоимость рекламы в пересчёте', 10, 'rub'),
            stage('Заказ', 80, 'Время менеджера по продажам', 100, 'rub'),
            stage('Оплата', 100, 'Бонус менеджера по продажам', 5, '%')
        ]
    },
    {
        name: "Продажи с бесплатным пробником",
        stages: [
            stage('Контакт', 20, 'Стоимость контакта', 10, 'rub'),
            stage('Презентация', 80, 'Время промоутера', 50, 'rub'),
            stage('Пробник', 20, 'Стоимость пробника', 500, 'rub'),
            stage('Заказ', 90, 'Время менеджера по продажам', 100, 'rub'),
            stage('Оплата', 100, 'Бонус менеджера по продажам', 5, '%')
        ]
    },
    {
        name: "Прямые продажи - B2B",
        stages: [
            stage('Контакт', 20,'Стоимость контакта',10,'rub'),
            stage('Презентация', 10,'Время менеджера по продажам',100,'rub'),
            stage('Предложение', 20,'Время менеджера по продажам',1000,'rub'),
            stage('Договор', 90,'Время менеджера по продажам',2000,'rub'),
            stage('Предоплата', 100,'Бонус менеджера по продажам',5,'%'),
        ]
    },
    {
        name: "Органика",
        stages: [
            stage('Посещение', 70,'',0,'rub'),
            stage('Заказ', 80,'Время менеджера по продажам',100,'rub'),
            stage('Оплата', 100,'Бонус менеджера по продажам',5,'%'),
        ]
    }
]);

export const wizardSlides:readonly WizardSlide[] = Object.freeze( [
    {
        label:'Выберите единицу и оцените средний чек',
        description:'Выберите единицу продаж, оцените средний чек (AvP), количество покупок одним покупателем (APC) и целевое количество новых клиентов в месяц (NB). Рассчитайте выручку в месяц.',
        image:'/wizard-images/1.png',
        video:'https://youtu.be/BN2TMpZJUjw',
    },
    {
        label:'Оцените и сгруппируйте затраты',
        description:'Перечислите все затраты, сгруппируйте их по типам: переменные (COGS), постоянные (Fix) и разовые (Inv). Оцените их размер (переменные – на единицу продаж, постоянные – на месяц, разовые – на весь проект)',
        image:'/wizard-images/2.png',
        video:'https://youtu.be/UxaQE9KSXf4',
    },
    // {
    //     label:'Рассчитайте маржу и LTV (маржу на одного клиента)',
    //     description:'Посчитайте маржу, маржинальность и LTV ― маржу на одного клиента с учётом повторных продаж',
    //     image:'/wizard-images/3.png'
    // },
    {
        label:'Рассчитайте стоимость привлечения клиента',
        description:'На этом шаге нужно описать всю воронку продаж, оценить затраты на каждом этапе, включая затраты на первую продажу (1sCOGS) и рассчитать стоимость привлечения одного клиента (CAC)',
        image:'/wizard-images/3.png',
        video:'https://youtu.be/4FrDlohc0t8',
    },
    {
        label:'Сделайте вывод о “сходимости” юнит-экономики',
        description:'Рассчитайте отношение LTV/CAC и сделайте вывод о «сходимости» юнит-экономики. Если отношение LTV/CAC низкое (ниже 2-3, то есть экономика не сходится), можно поискать способы увеличения LTV (увеличение среднего чека или количества повторных продаж) или снижения CAC (увеличение конверсий или снижение затрат)',
        image:'/wizard-images/4.png',
        video:'https://youtu.be/2-T1_kaWJKg',
    },
    {
        label:'Расчитайте ежемесячную прибыль и окупаемость проекта',
        description:'Проверьте постоянные затраты и посмотрите график “Месяц”, который включает в расчёты постоянные затраты. Обратите внимание на  ежемесячную прибыль.и рентабельность. А затем посмотрите график “Проект”,  который учитывает разовые затраты и посчитайте, за сколько месяцев они окупятся  за счёт прибыли',
        image:'/wizard-images/5.png',
        video:'https://youtu.be/0CEIQZIbugc',
    },
    {
        label:'Сформируйте финансовый план проекта',
        description:'Откройте вкладку “Бюджет” и уточните параметры бюджета. ( количество дней до возврата покупателя и скорость выхода на плановый объём продаж в месяц). Посмотрите на графики “Денежные потоки” и “Денежные потоки накопительным итогом”, чтобы понять, как быстро окупится проект',
        image:'/wizard-images/6.png',
        video:'https://youtu.be/UzSuHXwlpy8',
    },
] );

export const exemplaryProjectIds = [201,195,198,204,200,265,339,199,206,264,306,270,307,308,392];

export const socialMediaLinks = {
    youtube: 'https://www.youtube.com/channel/UCOG8PiLKVTS-V9nmWf7NkSg',
    vkontakte: 'https://vk.com/izibiz.club',
};

export const isImageEditorInEditProjectModal = () => window.innerHeight > 579;

export function getAuthOnlyFeatureDialogObj(hideDialog:() => void, message?: string):Record<string,any> {
    return {
        title: 'Необходимо авторизоваться',
        text: message || 'Эта функция доступна только для авторизованных пользователей',
        buttons: [
            {
                title: 'Авторизоваться',
                handler(){
                    hideDialog();
                    router.push('/login');
                },
            },
            {
                title: 'ОК',
                handler: hideDialog,
            },
        ]
    };
}