



































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { endpoints } from '@/common/config';
import { Resource } from '@/common/types/resource.types';
import ApiService from '@/common/api.service';
import { createClickAnchor } from '@/common/misc';
import SocialResource from '@/components/resources-tab/SocialResource.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';

export default Vue.extend({
  components: { SocialResource },
    //TODO: Check vue meta property
    name: 'resource',
    props: {
        resource: {type:Object},
    },
    mixins: [MiscMixin],
    data(){
        return{
            fetchingLikes: false,
            fetchingFavorite: false,
        }
    },
    methods: {
        async switchLike(){
            if(this.fetchingLikes||!this.isAuthenticated) return;

            this.fetchingLikes = true;
            try{
                const {id} = this.resource;
                const url = this.isLiked ? endpoints.unlikeResource(id) : endpoints.likeResource(id);
                const {data} = await ApiService.put(url);
                this.setResource( data.resource );
            }finally{ this.fetchingLikes = false; }
        },
        async switchFavorite(){
            if(this.fetchingFavorite||!this.isAuthenticated) return;

            this.fetchingFavorite = true;
            try{
                const { id }=this.resource;
                const url = this.isFavorite ? endpoints.unsetFavoriteResource(id) : endpoints.setFavoriteResource(id);
                const { data } = await ApiService.put(url);
                this.setResource( data.resource );
            }finally{ this.fetchingFavorite = false; }
        },
        setResource(resource:Resource):void{
            this.$emit('set-resource',resource);
        },
        async visitResource(){
            // createClickAnchor(this.resource.link,(a)=>a.target='_blank');
            if(!this.isAuthenticated) return;
            const {data} = await ApiService.put(endpoints.watchResource(this.resource.id));
            this.setResource( data.resource );
        }
    },
    computed: {
        iconClass():string{
            const localizedCategories = {
                article: 'статья',
                video: 'видео',
                course: 'курс',
                book: 'книга',
                service: 'сервис',
                miscellaneous: 'прочее',
            } as {[x: string]:string};
            const {type} = (this.resource as {type:string});
            return localizedCategories[type] || type;
        },
        isLiked():boolean{
            if(!this.isAuthenticated) return false;

            const {id} = this.user;
            const {likedIds} = this.resource as Resource;
            return likedIds.includes(id);
        },
        isFavorite():boolean{
            if(!this.isAuthenticated) return false;

            const {id} = this.user;
            const {favoriteIds} = this.resource as Resource;
            return favoriteIds.includes(id);
        },
        ...mapGetters(['isAuthenticated','user'])
    }
})
