import { ExtendedVue } from 'vue/types/vue';
import { NavDestinationUserType } from '../types/nav.destination.types';

export default class NavigationDestination{
    isActive=false;
    type:number;
    route:string|undefined;
    displayText:string;
    component:ExtendedVue<Vue,any,any,any,any>|undefined;
    userType:NavDestinationUserType;
    subDestinations=[] as NavigationDestination[];
    constructor(
        {text='',type=0,route=undefined,component=undefined,userType='any',subDestinations=undefined}: 
        {text?:string,type?:number,route?:string,component?:ExtendedVue<Vue,any,any,any,any>,userType?:NavDestinationUserType,subDestinations?:NavigationDestination[]}={}){
        this.displayText = text;
        this.type = type;
        this.route = route;
        this.component = component;
        this.userType = userType;
        if(subDestinations){ 
            this.subDestinations = subDestinations;
            this.subDestinations.forEach((subDestination)=>subDestination.type=type);
        }
    }
    allActiveDestinations():NavigationDestination[]{
        let destinations = [] as NavigationDestination[];
        this.subDestinations
            .filter((destination)=>destination.isActive)
            .forEach((destination)=>destinations=[...destinations,...destination.allActiveDestinations()]);
        if(this.isActive) destinations.push(this);
        return destinations;
    }
    getActiveSubDestination():NavigationDestination|undefined{
        return this.allActiveDestinations().find((destination)=>destination!=this);
    }
    setIsActive(isActive=this.isActive){
        if(isActive == false)
            this.subDestinations.forEach((destination)=>destination.setIsActive(false));
        this.isActive = isActive;
    }
    static findDestinationByComponent(component:ExtendedVue<Vue,any,any,any,any>, destinations:NavigationDestination[]){
        return destinations.find((destination)=>{
            return (destination as any).component?.options.name == (component as any).options.name;
        });
    }
}