import store from '@/store';
import { getBizModels } from '@/common/config';
import { Expense } from '@/common/classes/expenses';
import { ProductModel } from '@/common/classes/models';

class ProductManager{
    //List changes are not saved to store
    _getAllWithSubExpenses():Expense[]{
        return this.getAllExpenses().flatMap((e)=>e.withSubExpenses());
    }
    getAllExpenses():Expense[]{
        return store.state.product.expenses;
    }
    getProduct():ProductModel{
        return store.state.product;
    }
    expenseFromUUID(uuid:string):Expense{
        const expenses = this._getAllWithSubExpenses();
        const mapped = expenses.map((e)=>e.uuid);
        const found = expenses.find((e)=>e.uuid == uuid);
        if(!found) console.warn({msg:'unable to find expense with uuid',uuid,mapped});
        return found as Expense;
    }
    getSiblingsFor(expense:Expense):Expense[]{
        return expense.parentExpense ? expense.parentExpense.subExpenses as Expense[] : this.getAllExpenses();
    }
    getProductInfoChartDataColors(){
        const avgCheck = {chart:'#9597AB',computed:'#E3E3E3'};
        const unitExpenseCost = {chart:'#A2A8E7',computed:'#ECEDFD'};
        const unitProfitNoMarketing = {pos:'#CDF0D6',neg:'#FF7B7B'};
        
        const _singleSaleCost = '#D7FDFF'; 
            const singleSaleCost = {chart:_singleSaleCost,computed:_singleSaleCost};
        
        const unitProfit = {pos:'#BDFCC2',neg:'#FFCABF'};

        const unit = {
            avgCheck,
            unitExpenseCost,
            unitProfitNoMarketing,
            singleSaleCost,
            unitProfit
        };

        const oneClientEarnings = avgCheck;
        //unitExpCost
        const ltv = unitProfitNoMarketing;
        const cac = singleSaleCost;
        // unitProfit

        const client = {
            oneClientEarnings,
            unitExpenseCost,
            ltv,
            cac,
            unitProfit,
        }

        const monthEarnings = avgCheck;
        const unitExpCostAndMarketing = unitExpenseCost;
        const monthlyProfitNoConst = unitProfitNoMarketing;
        const constExpenses = {chart:'#F595B8',computed:'#F7D6E2'};
        const monthProfits = {pos:'#9FE194',neg:'#E86350'};
        const taxCost = {chart:'#F7D6D6',computed:'#F7D6D6'}

        const month = {
            monthEarnings,
            unitExpCostAndMarketing,
            monthlyProfitNoConst,
            constExpenses,
            monthProfits,
            taxCost,
            monthCleanProfit: unitProfit,
        }

        const rangeEarnings = avgCheck;
        //unitExpCostAndMarketing
        //rangeProfitNoConst
        //constExpenses
        //rangeProfitNoOnetime
        const onetimeExpenses = {chart:'#DFC6FF',computed:'#F3E9FF'};
        //unitProfit

        const region = {
            rangeEarnings,
            unitExpCostAndMarketing,
            rangeProfitNoConst: unitProfitNoMarketing,
            constExpenses,
            rangeProfitNoOnetime: monthProfits,
            onetimeExpenses,
            investmentReturn: unitProfit
        }

        return { unit, client, month, region, }
    }
    showclientsPerSalesUnit():boolean{
        const product = this.getProduct();
        const bizModels = getBizModels();
        const hasClientPerSaleModel = 
            product.bizModel != undefined && 
            bizModels[product.bizModel] && 
            bizModels[product.bizModel].allowClientPerSale;
        return !!(product.clientsPerSalesUnit != 1 || hasClientPerSaleModel);
    }
}

export default new ProductManager();