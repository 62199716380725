
import Vue from 'vue';
import ApiService from '@/common/api.service';
import {dataURLtoFile, fileToImg, rescaleImgCanvas} from '@/common/rescale.service';
import { processResponseError } from '@/common/misc';

export default Vue.extend({
    data(){
        return{
            onChangeCB: undefined as ((file:File)=>Promise<void>)|undefined,
        }
    },
    methods: {
        selectImagePromise(){
            return new Promise((res, rej)=>{
                this.onChangeCB = async (file:File)=>{
                    const img = await fileToImg(file);
                    const rescaledImg = rescaleImgCanvas(img, 1280, 720);
                    const rescaledImgURL = rescaledImg.toDataURL('image/png');
                    res( {file,url:rescaledImgURL} );
                };
                let input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input = document.documentElement.appendChild(input);
                input.click();
                input.style.display = 'none';
                input.addEventListener('change',e => {
                    const file = ((e.target as HTMLInputElement).files||[])[0];
                    (this.onChangeCB as (file:File)=>Promise<void>)(file).catch(rej);
                    input.remove();
                });
            }) as Promise<{file:File,url:string}>;
        },
        selectImageForUpload(selectedImage:{file:File,url:string}){
            return new Promise((res, rej)=>{
                const uploadSelectedImage = ({url,file}:{file:File,url:string})=> {
                        file = dataURLtoFile(url,file.name,'image/png');
                        this.uploadImg(file).then(res).catch(rej);
                };

                if(selectedImage) {
                    uploadSelectedImage(selectedImage);
                } else {
                    this.selectImagePromise()
                        .then(uploadSelectedImage)
                        .catch(rej);
                }
            }) as Promise<string>;
        },
        async uploadImg(file:File){
            const data = new FormData();
            data.append('file',file);
            let ret;
            try {
                ret = (await ApiService.post('/api/user/upload-image',data)).data.image;
            } catch (error) { alert(processResponseError(error)); }
            return ret;
        },
    }
});
