





























import Vue from 'vue';
import outdatedCheck from 'outdated-browser-rework';
import { detect } from 'detect-browser';
import StorageService from '@/common/storage.service';
import 'focus-within-polyfill';

//NOTE: This component does not work unless Vue is supported by a browser (see .browserslistrc for a list).
export default Vue.extend({
    name: 'outdatedBrowserWarning',
    data(){
        return {
            showWarning: false,
        }
    },
    mounted(){
        //use lib to set showWarning
        outdatedCheck({
            browserSupport: {
                Chrome: 65, // Includes Chrome for mobile devices
                Edge: 79,
                Safari: 10,
                "Mobile Safari": 10,
                Firefox: 60,
                Opera: 62,
                Vivaldi: 2,
                Samsung: 10,
                MIUI: 9,
                // You could specify minor version too for those browsers that need it.
                Yandex: 17,
                // You could specify a version here if you still support IE in 2017.
                // You could also instead seriously consider what you're doing with your time and budget
                IE: false
            },
            requireChromeOnAndroid: false,
            isUnknownBrowserOK: false,
            language: 'ru',
        })

        //#region set isOutdated value
        const oldOnload:any = window.onload;
        const setIsOutdated = () => {
            const isAllowedOnRoute = this.$route.meta.showOutdatedBrowserWarning;
            if(isAllowedOnRoute && StorageService.getStorageItems().HIDE_OUTDATED_DEVICE_MESSAGE.isFalse() )
                this.showWarning = (document.querySelector('#outdated')?.children.length || 0) != 0;

            // console.log({isOutdated, chldr: document.querySelector('#outdated')?.children.length, el: document.querySelector('#outdated')});
        }

        //Check if browser is outdated anyways, then try again when window is loaded
        setIsOutdated();
        if (typeof window.onload !== "function") {
            window.onload = setIsOutdated;
        } else {
            window.onload = function() {
                if (oldOnload) oldOnload()
                setIsOutdated()
            }
        }
    },
    methods: {
        dontShowAgainClick(){
            StorageService.getStorageItems().HIDE_OUTDATED_DEVICE_MESSAGE.setValue(true);
            this.showWarning = false;
        },
    },
    computed: {
        detectBrowser():string|undefined {
            const {name, type} = detect() || {};
            if(!['browser', 'react-native'].includes(type+'') || !name)
                return undefined;
            const ret = name.charAt(0).toUpperCase() + name.slice(1);
            return ret.replace('-', ' ');
        },
        detectOS():string|undefined {
            const {os} = detect() || {};
            if(!os) 
                return undefined;
            return os;
        },
    },
})
