












































import Vue from 'vue';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { processResponseError, parseDate } from '@/common/misc';
import { FeedbackRequest } from '@/common/types/support.type';
// import Collapsible from '@/components/elements/Collapsible.vue';

export default Vue.extend({
//   components: { Collapsible },
    name: 'feedbackRequestTab',
    async mounted(){
        try {
            await this.updateFeedbackRequests();
        } catch (e) {
            console.error(e);
            alert('При обработке запроса произошла ошибка '+processResponseError(e));
        }finally{ this.isFetching = false; }
    },
    data(){
        return{
            feedbackRequests: [] as FeedbackRequest[],
            isFetching: true,
        }
    },
    methods: {
        async updateFeedbackRequests(){
            const {data} = await ApiService.get(endpoints.adminGetFeedbackRequests);
            this.feedbackRequests = data.feedbackRequests;
        },
        parseDate,
    }
})
