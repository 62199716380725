














































import Vue from 'vue';
import NavHeader from '@/components/elements/NavHeader.vue';
import ApiService from '@/common/api.service';
import TabViewMixin from '@/components/mixins/TabViewMixin.vue';
import { endpoints, getAuthOnlyFeatureDialogObj } from '@/common/config';
import { Resource, ResourceCategory, ResourceCollection, ResourceSearchData } from '@/common/types/resource.types';
import { mapGetters } from 'vuex';
import { createClickAnchor, getAppendMetaTagCallback } from '@/common/misc';
import { resourceCollectionSearchPredicate, resourceSearchPredicate } from '@/common/search.utils';
import NavigationDestination from '@/common/classes/nav.destination';
import SuggestResourceModal from '@/components/resources-tab/SuggestResourceModal.vue';
import ResourceCollectionQueryTable from '@/components/resources-tab/ResourceCollectionQueryTable.vue';
import ResourcesModal from '@/components/resources-tab/ResourcesModal.vue';
import ProjResSearch from '@/components/elements/search/ProjResSearch.vue';
import { getDefaultResourceFilters, getDefaultResourceSearchData, resourceSearchSortModes } from '@/common/search.utils';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import ResourceTable from '@/components/resources-tab/ResourceTable.vue';

const component = Vue.extend({
    name: 'resources',
    mixins: [TabViewMixin,ResponsiveDataMixin],
    components: {
        NavHeader,
        SuggestResourceModal,
        ResourceCollectionQueryTable,
        ResourcesModal,
        ProjResSearch,
        ResourceTable,
    },
    data(){
        return{
            currentResources: [] as Resource[],
            resourceCollections: {} as {[x:string]:ResourceCollection[]},
            curResourceCollection: undefined as ResourceCollection|undefined,
            fetchingLikeCollections: [] as ResourceCollection[],
            searchData: getDefaultResourceSearchData(),
            sortMode: resourceSearchSortModes[0],
        }
    },
    watch:{
        $route (to, from){
            this.$forceUpdate();
            if(!this.resId) this.curResourceCollection = undefined;
        },
    },
    async mounted(){
        (this as any).onViewMount(component);

        const { data } = await ApiService.get(endpoints.getResourceCollections);
        this.resourceCollections = data.resourceCollections;
        this.searchData.categories = this.resourceCategoryArray.map(({category})=>category);

        const resourceId = parseInt( this.resId );
        
        if(this.showSuggestModalOnLoad){ 
            this.showSuggestResourceModal(); 
            history.pushState({},'',this.$route.path.split("?")[0]); 
        }

        if(resourceId) {
            // try{
                // await this.watchResourceCollection(resourceId)
                this.setCurrentCollection(this.resourceCollectionArray.find((rc)=>rc.id==resourceId) as ResourceCollection);
                if(!this.mixedInIsMobileWidth) this.$modal.show('resources-modal');
            // }catch(e){
                
            //     let detailedText;
            //     const defaultText = 'При обработке запроса произошла ошибка.';
            //     try{ detailedText = `При обработке запроса ${location.href.split('?')[1]} произошла ошибка.<br>${escapeHtml(e+'')}`; }catch(e){/**/}
            //     this.$modal.show('dialog',{
            //         title: 'Ошибка',
            //         width: '450px',
            //         text: detailedText||defaultText,
            //         buttons: [{title: 'OK', handler: () => createClickAnchor('/resources')}]
            //     });
            // }
        }
        if(this.status && this.status=='resource-not-found') {
            this.$modal.show('dialog',{
                title: 'Ошибка',
                width: '450px',
                text: 'Запрашиваемый ресурс не был найден',
                clickToClose: false,
                buttons: [{title: 'OK', handler: () => createClickAnchor('/resources')}]
            });
        }
    },
    methods: {
        updateResource(updateResource:Resource){
            const siblings = this.resourceCollectionArray
                .find(({resourceList})=> resourceList
                .find(({id})=>id==updateResource.id) )?.resourceList;
            const resIndex = siblings?.findIndex(({id})=>id==updateResource.id);
            if(siblings && resIndex != undefined)
                this.$set( siblings, resIndex, updateResource);
            
            const curResIndex = this.currentResources
                .findIndex(({id})=>id==updateResource.id);
            if(curResIndex != undefined) 
                this.$set( this.currentResources, curResIndex, updateResource );
        },
        resetSearchDataOptions(){
            const filters:Record<string,string> = getDefaultResourceFilters();
            Object.assign(this.searchData,filters);
        },
        showSuggestResourceModalNoCollection(){
            this.curResourceCollection = undefined;
            this.showSuggestResourceModal();
        },
        showSuggestResourceModal(){
            this.$modal.hide('resources-modal');
            if(this.isAuthenticated) {
                this.$modal.show('suggestResourceModal',{
                    curCollection: this.curResourceCollection,
                    resourceCollections: this.resourceCollectionArray,
                    resourceCategories: this.resourceCategoryArray,
                });
            } else {
                const dialogObj = getAuthOnlyFeatureDialogObj(()=>this.$modal.hide('dialog'));
                this.$modal.show('dialog', dialogObj);
            }
        },
        scrollFullWidth(el:HTMLElement,isForward:boolean):void{
            const multiplier = isForward?1:-1;
            let top = el.offsetHeight * multiplier;
            let left = el.offsetWidth * multiplier;
            el.scrollBy({top, left});
        },
        showAllResources(){
            this.curResourceCollection = undefined;
            this.currentResources = this.resourceCollectionArray
                .filter((rc)=>resourceCollectionSearchPredicate(rc,this.searchData))
                .flatMap((cur)=>cur.resourceList)
                .filter((res)=>resourceSearchPredicate(res,this.searchData));
            if(!this.mixedInIsMobileWidth) this.$modal.show('resources-modal');
            else window.scrollTo({top: 0})
        },
        showResourceCollection(collection:ResourceCollection):void{
            this.setCurrentCollection(collection);
            
            //TODO: Add SEO tags. Seems like dynamicly applied meta are not recognized by crawlers (https://stackoverflow.com/questions/29127891/dynamic-meta-tags-in-html5-and-javascript-jquery)
            // const descriptionTag = getAppendMetaTagCallback();
            // descriptionTag.meta.name = 'description';
            // descriptionTag.meta.content = collection.resourceList.map(({name})=>name).join(', ') + '.';
            // descriptionTag.append();

            if(!this.mixedInIsMobileWidth) this.$modal.show('resources-modal');
            else window.scrollTo({top: 0})
        },
        setCurrentCollection(collection:ResourceCollection):void{
            this.currentResources = collection.resourceList.filter((r)=>resourceSearchPredicate(r,this.searchData));
            this.curResourceCollection = collection;
        },
        isCollectionLikedByUser(collection:ResourceCollection):boolean{
            return this.isAuthenticated && this.user && collection.likedIds.includes(this.user.id);
        },
        exitResourceCollectionContents():void{
            this.currentResources = [];
        },
    },
    computed: {
        showResourceCollectionContents():boolean{
            return this.mixedInIsMobileWidth && !!this.currentResources.length;
        },
        mixedInIsMobileWidth():boolean{
            return (this as any).isMobileWidth;
        },
        showSuggestModalOnLoad():boolean{
            return this.$route.query.suggest == '1';
        },
        resId: {
            get():string{
                const {resId} = this.$route.query;
                return resId ? resId+"" : "";
            },
            cache: false
        },
        status: {
            get():string{
                const {status} = this.$route.query;
                return status ? status+"" : "";
            },
            cache: false
        },
        resourceCategoryArray():{category:ResourceCategory,collections:ResourceCollection[]}[]{
            return Object.keys( this.resourceCollections )
            .map((k)=>{
                const collections = this.resourceCollections[k];
                return {category: collections[0].category,collections};
            }).sort((a,b)=>a.category.priority-b.category.priority);
        },
        resourceCollectionArray():ResourceCollection[]{
            let ret = [] as ResourceCollection[];
            if(!this.resourceCollections || Object.values(this.resourceCollections).length==0) return [];
            Object.values( this.resourceCollections ).forEach((c)=>ret = ret.concat(c));
            return ret;
        },
        ...mapGetters(['isAuthenticated','user','navDestinations'])
    }
})

export default component;
