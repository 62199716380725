
import jwtService from '@/common/jwt.service'
import { createClickAnchor } from '@/common/misc';
import Vue from 'vue'
export default Vue.extend({
    name: 'logoutView',
    mounted(){
        jwtService.destroyToken();
        createClickAnchor('/');
    }
})
