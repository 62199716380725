



















import Vue from 'vue';
import ImageUploader from '@/components/elements/ImageUploader.vue';
import { mapGetters } from 'vuex';
import { fetchProjectPresetImages } from '@/common/config';

export default Vue.extend({
    name: 'projectImageEditor',
    components: {
        ImageUploader,
    },
    data(){
        return {
            defaultProjectImage: undefined,
            imageSet: undefined,
            foldedImagePresets: true,
        };
    },
    async mounted(){
        const {defaultProjectImage,imageSet} = await fetchProjectPresetImages();
        this.defaultProjectImage = defaultProjectImage;
        this.imageSet = imageSet;
    },
    methods: {
        setProjectImage(img:string){
            this.$set(this.projectData,'imageLink',img)
            this.foldedImagePresets = true;
        },
    },
    computed: {
        uploadUserImagePromise():Promise<string>{
            const imageUploaderVue = (this as any).$refs.imageUploader;
            let uploadUserImagePromise;
            if(imageUploaderVue)
                uploadUserImagePromise = imageUploaderVue.uploadUserImagePromise;
            return uploadUserImagePromise;
        },
        ...mapGetters(['projectData'])
    }
})
