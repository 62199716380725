


















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import budget from '@/common/classes/budget';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { isMobile } from '@/common/misc';
export default Vue.extend({
    name: 'budgetSeasonsModal',
    data(){
        return {
            initSeasonality: [...Array(12)].map(()=>100),
            //input seasonality. Actual seasonality is stored in product.seasonality
            seasonality: [...Array(12)].map(()=>100),
            isChanged: false,
        }
    },
    mixins: [MiscMixin],
    watch: {
        seasonality: {
            handler(){
                this.isChanged = true;
            },
            deep: true
        },
    },
    methods: {
        onResize():void{
            const el = this.$refs.seasonsRootEl as HTMLElement;
            console.log('height '+el.scrollHeight);
            (el.parentElement as HTMLElement).style.height = el.scrollHeight+'px';
        },
        async saveClick():Promise<void>{
            this.$set( this.product, 'seasonality', this.seasonality );
            await this.$nextTick();
            this.isChanged = false;
        },
        closeClick():void{
            this.hideModal();
        },
        async beforeOpen():Promise<void>{
            this.seasonality = [...this.product.seasonality];
            this.initSeasonality = [...this.product.seasonality];
            await this.$nextTick();
            this.$nextTick(this.onResize);
            this.isChanged = false;
            // this.$set( this.product, 'seasonality', this.seasonalityCorr.map((s)=>Math.round(s)) );
        },
        async saveCloseClick():Promise<void>{
            // if(this.isChanged){ 
            //     this.$set( this.product, 'seasonality', this.seasonality );
            //     await this.$nextTick();
            //     this.isChanged = false;
            //     //Set corr seasonality to main
            // }
            // else this.hideModal();
        },
        hideModal(){
            this.$modal.hide('budget-seasons');
            // this.$set( this.product, 'seasonality', this.seasonality );
        },
        async revertChanges():Promise<void>{
            this.seasonality = this.initSeasonality;
            this.$set( this.product, 'seasonality', this.initSeasonality );
            await this.$nextTick();
            this.isChanged = false;
            await new Promise((r)=>setTimeout(r, 400));
            this.hideModal();
        },
    },
    computed: {
        // seasonality: {
        //     get():number[]{
        //         return this.product.seasonality;
        //     },
        //     set(vs:number[]){
        //         this.$set(this.product, 'seasonality', vs);
        //     },
        // },
        modalWidth():number{
            return isMobile() ? 468 : Math.min(window.innerWidth - 100, 900);
        },
        modalHeight():number{
            if(this.modalWidth > 899) return 208;
            return 272;
        },
        strMonths():string[]{
            return ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
        },
        //FIXME: CORR VALUES DON'T MATCH TABLE
        seasonalityPercentage():number{
            return budget.getSeasonalityPercentage(this.seasonality);
        },
        seasonalityCorrPercentage():number{
            return budget.getSeasonalityCorrPercentage(this.seasonalityCorr);
        },
        seasonalityCorr():number[]{
            return budget.getSeasonalityCorrArr(this.seasonality, this.seasonalityPercentage);
        },
        ...mapGetters(['product']),
    },
})
