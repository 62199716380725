



































import Vue from 'vue';
import { Resource, Resource as ResourceType, ResourceCategory, ResourceCollection } from '@/common/types/resource.types';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { processResponseError } from '@/common/misc';
import ResourceEditForm from '../elements/ResourceEditForm.vue';
import Collapsible from '../elements/Collapsible.vue';
export default Vue.extend({
    name: 'adminResourceSuggestionTab',
    components: {
        ResourceEditForm,
        Collapsible,
    },
    mounted(){
        this.updateSuggestedResources();
    },
    data(){
        return{
            foldCollections: {} as Record<string,boolean>,
            collectionsWithSuggestedResources: [] as ResourceCollection[],
            publishedCollections: [] as ResourceCollection[],
            resourceCategories: [] as ResourceCategory[],
            isFetching: true,
        }
    },
    methods: {
        async publishResource(collection:ResourceCollection,resource:Resource){
            try {
                this.isFetching = true;
                await ApiService.post(endpoints.adminAddResourceToCollection(collection.id),resource);
                this.updateSuggestedResources();
            } catch (e) { alert(`Во время обработки запроса произошла ошибка \n ${processResponseError(e)}`); }
            finally{ this.isFetching = false; }
        },
        async deleteResource(collection:ResourceCollection,resource:Resource){
            try {
                this.isFetching = true;
                await ApiService.delete(endpoints.adminDeleteResource(resource.id));
                this.updateSuggestedResources();
            } catch (e) { alert(`Во время обработки запроса произошла ошибка \n ${processResponseError(e)}`); }
            finally{ this.isFetching = false; }
        },
        async updateSuggestedResources():Promise<void>{
            try {
                this.isFetching = true;
                const collectionsMap:Record<string,ResourceCollection[]> = (await ApiService.get(endpoints.getResourceCollections)).data.resourceCollections;
                this.publishedCollections = Object.values( collectionsMap ).flatMap((v)=>v);
                this.resourceCategories = (await ApiService.get(endpoints.adminGetResourceCategories)).data.resourceCategories;
                const suggestedResourceCollections:ResourceCollection[] = (await ApiService.get(endpoints.adminGetSuggestResources)).data.collectionsWithSuggestedResources;
                suggestedResourceCollections.forEach((rc)=>(rc as any).fold = true);
                this.collectionsWithSuggestedResources = suggestedResourceCollections;
            } catch (e) { alert(`Во время обработки запроса произошла ошибка \n ${processResponseError(e)}`); }
            finally{ this.isFetching = false; }
        },
    }
})
