








import Vue from 'vue';

// I HATE this solution, but i found no way to bypass redundant navigation exception when calling router.push()
export default Vue.extend({
    mounted(){
        const query = this.$route.query;
        let params:string[] = [];
        for (const key in query) {
            if (Object.prototype.hasOwnProperty.call(query, key)) {
                const val = query[key];
                params.push(key+'='+val);
            }
        }
        if(params.length == 0) this.$router.push('/create');
        else this.$router.push('/create?'+params.join('&'));
    },
})
