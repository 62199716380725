export const SET_PRODUCT = "setProduct";
export const SET_CHANNELS = "setChannels";
export const SET_PROJECT = "setProject"
export const SET_REFERENCER = "setReferencer";
export const SET_AUTH = "setAuth";
export const PURGE_AUTH = "purgeAuth";
export const AFTER_ROUTE = "afterRoute";
export const BEFORE_ROUTE = "beforeRoute";
export const SET_COST_ERRORS = "setCostErrors";
export const SET_LOADING_PROJECT = "setLoadingProject";
export const SET_ACTIVE_NAV_DESTINATION = "setActiveNavDestination";
export const NAV_DESTINATION_CLICK = "navDestinationClick";

//UNIT MUTATIONS
export const SET_UNITS = "setUnits";