







import Vue from 'vue';
import SelectElMixin from '@/components/mixins/SelectElMixin.vue';

export default Vue.extend({
    name: 'socialFilterSelect',
    mixins: [SelectElMixin],
    props: {
        flags: { type: Array||undefined },
        modelData: {},
    },
    computed: {
        options(){
            return [
                'Только избранное',
                'Скрывать просмотренное',
                'Только просмотренное',
            ]
        },
    },
})
