

























import Vue from 'vue';
import { resourceSearchPredicate } from '@/common/search.utils';
import { Resource, ResourceCollection, utils } from '@/common/types/resource.types';
import SocialResource from '@/components/resources-tab/SocialResource.vue';

export default Vue.extend({
    name: 'resourceCollectionCard',
    components: {SocialResource},
    props: {
        resourceCollection:{type:Object},
        searchData:{},
        viewAll:{type:Boolean,default:false}
    },
    computed: {
        totalWatched():number{
            return utils.countResourceCollectionWatched(this.resourceCollection);
        },
        totalLiked():number{
            return utils.countResourceCollectionLikes(this.resourceCollection);
        },
        filteredResourcesCount():number{
            return this.resourceCollection.resourceList.filter((r:Resource)=>resourceSearchPredicate(r,this.searchData as any)).length;
        },
        typeResourceCollection():ResourceCollection{
            return this.resourceCollection;
        },
    }
})
