//??? Changing the order of imports breaks the whole app
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
// const Home = () => import('@/views/Home.vue');

// import UnitEconomyConstructor from '@/views/UnitEconomyConstructor.vue';
const UnitEconomyConstructor = () => import('@/views/UnitEconomyConstructor.vue');

import AdminPanel from '@/views/AdminPanel.vue';
//Crashes the app
// const AdminPanel = () => import('@/views/AdminPanel.vue');

import AboutView from '@/views/AboutView.vue';
import SettingsView from '@/views/SettingsView.vue';
import SupportView from '@/views/SupportView.vue';
// import ProjectsView from '@/views/ProjectsView.vue';
const ProjectsView = () => import('@/views/ProjectsView.vue');
import ForgotPassView from '@/views/ForgotPassView.vue';
import LogoutView from '@/views/LogoutView.vue';
import UnitEconomyMobileRedirect from '@/views/UnitEconomyMobileRedirect.vue';

const QuizView = () => import('@/views/QuizView.vue');

// import LoginRegisterView from '@/views/LoginRegisterView.vue';
const LoginRegisterView = () => import('@/views/LoginRegisterView.vue');

import PaymentsView from '@/views/PaymentsView.vue';
import Resources from '@/views/Resources.vue';
import TOSView from '@/views/TosView.vue';
const LandingView = () => import('@/views/LandingView.vue');

Vue.use(VueRouter);

function isMobile():boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export class RouteMeta{
  onNav: boolean;
  authOnly: boolean;
  ignoreParams:boolean;
  onMobile:boolean;
  cssClasses:string[];
  [x:string]: any;
  constructor(
    {onNav=true,authOnly=false,onMobile=false,ignoreParams=true,nofollow=true,showOutdatedBrowserWarning=true,cssClasses=[],options={}}: 
    {onNav?:boolean,authOnly?:boolean,onMobile?:boolean,ignoreParams?:boolean,nofollow?:boolean,showOutdatedBrowserWarning?:boolean,cssClasses?:string[],options?:Record<string,any>}={}){
    if(!onMobile && isMobile())
      onNav = false;
    this.onNav = onNav;
    this.onMobile = onMobile;
    this.authOnly = authOnly;
    this.ignoreParams = ignoreParams;
    this.cssClasses = cssClasses;
    this.showOutdatedBrowserWarning = showOutdatedBrowserWarning;
    for (const key in options) this[key] = options[key];
    if(nofollow){
      if(options.metaTags == undefined) options.metaTags = [];
      const metaTags = options.metaTags as {name:string,content:string}[]
      const robotsTag = metaTags.find(({name})=>name == 'robots');
      if(robotsTag) robotsTag.content += ' nofollow';
      else metaTags.push({name: 'robots', content: 'nofollow'})
    }
  }
}
//TODO: Before_route check unsaved changes
//NOTE: Don't forget to check layout in 1280x720 after adding a new route
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Главная',
    component: Home,
    meta: new RouteMeta({
      onNav: isMobile(), 
      onMobile: true, 
      showOutdatedBrowserWarning: true,
      options: {
        title: 'IziBiz homepage',
        metaTags: [
            { 
              name: 'description', content: 'izibiz – платформа для начинающих предпринимателей, калькулятор экономики, коллекции ссылок по темам: стартап, MVP, маркетинг, инвестиции, юридическая защита...' 
            },
        ],
      },
    }),
  },
  {
    path: '/create',
    name: 'Юнит-экономика',
    component: UnitEconomyConstructor,
    meta: new RouteMeta({onNav: true, onMobile: true})
  },
  {
    path: '/projects',
    name: 'Проекты',
    component: ProjectsView,
    meta: new RouteMeta({onMobile:true, onNav: true})
  },
  // {
  //   path: '/find',
  //   name: 'Найти',
  //   component: Find,
  //   meta: new RouteMeta({onNav: false})
  // },
  {
    path: '/resources',
    name: 'Ресурсы',
    component: Resources,
    meta: new RouteMeta({onMobile:true, ignoreParams:false})
  },
  {
    path: '/about',
    name: 'О проекте',
    component: AboutView,
    meta: new RouteMeta({onMobile: true,
      options: {
        metaTags: [
          {
            name: 'description', 
            content: 'На платформе izibiz.club мы собираем сообщество предпринимателей. Наш собственный ресурс – это калькулятор экономики проекта'
          }
        ]
      }
    })
  },
  {
    path: '/admin',
    name: 'Панель администратора',
    component: AdminPanel,
    meta: new RouteMeta({onNav:false})
  },
  {
    path: '/settings',
    name: 'Настройки',
    component: SettingsView,
    meta: new RouteMeta({onNav: false, onMobile: true})
  },
  {
    path: '/support',
    name: 'Поддержка',
    component: SupportView,
    meta: new RouteMeta({onNav: true, onMobile: true})
  },
  {
    path: '/forgot-password',
    name: 'Восстановление пароля',
    component: ForgotPassView,
    meta: new RouteMeta({onNav: false, onMobile: true})
  },
  {
    path: '/logout',
    name: 'Выход из учетной записи',
    component: LogoutView,
    meta: new RouteMeta({onNav: false, onMobile: true})
  },
  {
    path: '/login',
    name: 'Вход и регистрация',
    alias: ['/register'],
    component: LoginRegisterView,
    meta: new RouteMeta({onNav: false, onMobile: true})
  },
  {
    path: '/tos',
    name: 'Правовая информация',
    component: TOSView,
    meta: new RouteMeta({onNav: false, onMobile: true})
  },
  {
    path: '/create_redirect',
    name: 'Перенаправление на проект',
    component: UnitEconomyMobileRedirect,
    meta: new RouteMeta({onNav: false, onMobile: true}),
  },
  // {
  //   path: '/operations',
  //   name: 'Биллинг',
  //   component: BillingView,
  //   meta: new RouteMeta({onNav: false})
  // },
  {
    path: '/payments',
    name: 'Платежи',
    component: PaymentsView,
    meta: new RouteMeta({onNav: false, onMobile: true})
  },
  {
    path: '/quizzes',
    name: 'Опросы',
    component: QuizView,
    meta: new RouteMeta({onNav: false, onMobile: true}),
  },
  {
    path: '/book',
    name: '📖 «Твой первый бизнес» — уже в продаже',
    component: LandingView,
    meta: new RouteMeta({
      onMobile: true, 
      showOutdatedBrowserWarning: false, 
      cssClasses: ['book-landing-nav-link'],
      options: {
        metaTags: [
          {
            name: 'og:image',
            content: '/landing/MainSectionBG_PreviewCrop.jpg',
          }
        ]
      },
    }),
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to,from,next)=>{
  //read https://router.vuejs.org/ru/guide/advanced/navigation-guards.html#%D0%B3n%D0%BE%D0%B1%D0%B0n%D1%8C%D0%BD%D1%8B%D0%B5-%D1%85%D1%83%D0%BA%D0%B8-%D0%B4%D0%BE-%D0%BD%D0%B0%D0%B2%D0%B8%D0%B3%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85-%D1%85%D1%83%D0%BA%D0%BE%D0%B2  
// });

export function redirToProjectCreation(){
  router.push('/create?create_new=1');
}

export default router
