








import Vue from 'vue';
import UnsupportedDeviceWarning from '@/components/elements/UnsupportedDeviceWarning.vue';
import RegistrationPromoCodeMixin from '@/components/mixins/RegistrationPromoCodeMixin.vue';
import OutdatedBrowserWarning from '@/components/elements/OutdatedBrowserWarning.vue';
import '@/assets/css/tooltip.less';
import '@/assets/css/generic.less';
import 'normalize.css'
import { CHECK_AUTH } from '@/store/actions';
import { mapGetters } from 'vuex';
import GlobalAppMixin from '@/components/mixins/GlobalAppMixin.vue';
// import { isMobile } from '@/common/misc';

const app = Vue.extend({
  name: 'app',
  mixins: [RegistrationPromoCodeMixin, GlobalAppMixin],
  components: {UnsupportedDeviceWarning, OutdatedBrowserWarning},
  mounted(){
    this.$store.dispatch(CHECK_AUTH);

    //#region add a css class that indicates if app is running on a mobile device
    //A better solution w/ mutationObserver https://usefulangle.com/post/356/javascript-detect-element-added-to-dom
    // const applyGlobalMobileStyle = () => {
    //   if(!isMobile()) return;

    //   [...document.body.getElementsByTagName("*")]
    //     .forEach((el)=>el.classList.add('mobile-device'))
    // }
    // applyGlobalMobileStyle();
    // setInterval(applyGlobalMobileStyle, 50)
    //#endregion
  },
  computed: {
    ...mapGetters(['isAuthChecked'])
  }
});

export default app;
