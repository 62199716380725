import Vue from 'vue';
import { ExtendedVue } from 'vue/types/vue';
import { ProjectDataModel } from '@/common/classes/models';
import NavigationDestination from '@/common/classes/nav.destination';
import { SupportModalReason } from '@/common/types/support.type';
import * as clipboard from 'clipboard-polyfill/text';
import { Route } from 'vue-router';
// import stringSimilarity from 'string-similarity';

export function jsonCycler():any{
    const seen:any[] = [];
    return (key:any,val:any)=>{
        if (val != null && typeof val == "object") { if (seen.indexOf(val) >= 0){ return; } seen.push(val); }
        return val;
    }
}
//#region formatting
const rubFormatter = Intl.NumberFormat('ru',{style:'currency',currency:'RUB'})

export function formatAsRub(v:number,toFixed=-1):string{
  if(v==undefined) return '';
  if(Number.isNaN(v)) return v+'';
  if(toFixed>-1) v = smartToFixed(v,toFixed);
  return rubFormatter.format(v);
}

export function smartToFixed(number:number,decimals=2,roundIfMoreThan=10):number{
  if(Math.round(number) == number || number == 0 || Number.isNaN(number)) return number;
  if(Math.abs(number) >= roundIfMoreThan) return Math.round(number);
  const split = number.toString().split(".")
  if(split.length != 2) return 0;
  const totalDecimals = split[1].length || 0; 
  if(totalDecimals > decimals) return parseFloat(number.toFixed(decimals))
  else return number;
}

export function roundToDecimals(number:number,decimals:number):number{
    if(typeof number != 'number' && Number.isNaN(parseFloat(number))) return NaN;
    else number = parseFloat(number+'');
    return parseFloat( number.toFixed(decimals) );
}

export function numberWithSpaces(x:number):string|number {
  if(x.toString().length < 4 || isNaN(x)) return x;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function processFormatInput(s:string|number):number{
    let p = s;
    if(typeof p == 'string') p = parseInt(p+"");
    if(p == undefined || Number.isNaN(p)) return NaN;
    return p;
}
//#endregion

export function processResponseError(e:any):string{
  if(typeof e == 'string') return escapeHtml( e );
  try{
      if(e.response.data){
          const {data} = e.response;
          if(data.message||data.msg)
              return escapeHtml( data.message||data.msg );
      }
  }/* eslint-disable-next-line no-empty */
  catch(ignored){}
  const message = e.message as string;
  if(message){
      const eng = 'Request failed with status code'
      if(message.includes(eng)) 
          return escapeHtml( message.replace(eng,'Произошла неизвестная ошибка при выполнении запроса. Код состояния: ') );
  }
  return escapeHtml( e );
}

export function escapeHtml(unsafe:string):string {
    return (unsafe+'')
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
}

export function isMobile():boolean{
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // console.log({w:window.screen.width, isMobile})
    return isMobile;
}

export function escapeRegExp(s:string) {
    return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const phoneNumberRegex = "^((\\+|00)?(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)|(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[- ]?)[\\d\\- .()]{7,31}$"

export function upFirstLetter(str:string):string{
    if(str.length==0) return '';
    if(str.length==1) return str.toUpperCase();
    return str.charAt(0).toUpperCase()+str.slice(1);
}

export const mapObjectToUrlParams = (obj:any) => Object.entries(obj).map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val as any)}`).join('&');

export const userHasRole = (user:any,role:string) => user.authorities.map((a:any)=>a.authority).includes(role);

export function enumNameByIndex (index:number, _enum:any) { return Object.keys(_enum).find(k => _enum[k] === index); }

export function getUniqueName(baseName:string,siblings:string[]){
    let count = 0, name = baseName;
    while(siblings.includes(name))
      name=`${baseName}(${++count})`;
    return name;
}

//From https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
export function beforeEachApplyMeta(to:Route, from:Route):void{
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => (el.parentNode as any).removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return;

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map((tagDef:any) => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag:any) => document.head.appendChild(tag));
}

export function putToClipboard(s:string):Promise<any>{
    return clipboard.writeText(s);
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// https://stackoverflow.com/a/15289883
export function dateDiffInDays(a:Date, b:Date):number {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function downloadFile(content:any, fileName:string, contentType:string) {
    const file = new Blob([content], {type: contentType});
    const href = URL.createObjectURL(file);
    const setDownload = (a:HTMLAnchorElement)=>a.download=fileName;
    createClickAnchor(href,setDownload);
}

//eslint-disable-next-line @typescript-eslint/no-empty-function
export function createClickAnchor(href:string,beforeClick=(a:HTMLAnchorElement)=>{},afterClick=(a:HTMLAnchorElement)=>{}){
    const a = document.createElement("a");
    a.href=href;
    beforeClick(a);
    a.click();
    afterClick(a);
}

export function promiseFileSelect(accept=''){
    //note: might not work on safari, ?have to append to document
    const fileInput = document.body.appendChild( document.createElement('input') ) as HTMLInputElement;
    fileInput.style.display = 'none';
    fileInput.type = 'file';
    fileInput.accept = accept;
    fileInput.click();
    return new Promise((res,rej)=>{
        const beforeEnd = ()=>{
            window.removeEventListener('focus',winOnFocus);
            fileInput.remove();
        };
        //Override resolve and reject
        ((origRes:any,origRej:any)=>{
            res = (data:any)=>{ beforeEnd(); origRes(data); };
            rej = (data:any)=>{ beforeEnd(); origRej(data); };
        })(res,rej);
        const winOnFocus = async () => {
            //Delay to wait for fileInput change
            await new Promise((tRes)=>setTimeout(tRes,250));
            if(!fileInput.files || !fileInput.value) rej();
        };
        window.addEventListener('focus',winOnFocus);
        fileInput.onchange = (e)=>{
            if(fileInput.files) res(fileInput.files[0]);
            else rej();
        };
    });
}

export function destinationByComponent(component:ExtendedVue<Vue,any,any,any,any>,destinations:NavigationDestination[]){
    return NavigationDestination.findDestinationByComponent(component,destinations) as NavigationDestination;
}

export function whenAvailable(name:string, callback:(obj:any)=>void) {
    const interval = 25; // ms
    window.setTimeout(function() {
        const obj = (window as any)[name];
        obj ? callback(obj) : whenAvailable(name, callback);
    }, interval);
}

//https://stackoverflow.com/a/30106551
export function b64EncodeUnicode(str:string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode(('0x' + p1) as any);
    }));
}

export function b64DecodeUnicode(str:string) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export function loadExternalScript(src:string){
    const checkoutUiScript = document.createElement('script')
    checkoutUiScript.setAttribute('src', src)
    document.head.appendChild(checkoutUiScript)
    return new Promise((res)=>{checkoutUiScript.onload = res;})
}

export function getAppendMetaTagCallback():{meta:HTMLMetaElement,append:()=>void}{
    const meta = document.createElement('meta');
    const append = () => document.getElementsByTagName('head')[0].appendChild(meta);
    return {meta,append};
}

export const getLinkToProject = (projectData:ProjectDataModel):string|undefined=>{
    const {id,invitationCode,isPublished,orig} = projectData;
    const isProjectSaved = !!(isPublished && id) || !!invitationCode;
    const hasPrevData = orig && getLinkToProject(orig);
    if(!isProjectSaved && !hasPrevData) return;
    const baseUrl = location.protocol+'//'+location.host+'/create?';
    let savedProjectUrl = baseUrl + (isPublished ? 'load='+id : 'load_protected='+invitationCode);
    if(projectData.loadQuery){ 
        const {tab, chart} = projectData.loadQuery;
        if(tab == 'product' || tab == 'channels' || tab == 'budget') savedProjectUrl += `&tab=${tab}`;
        if(typeof chart == 'number' && chart > 0) savedProjectUrl += `&chart=${chart}`;
    }
    const origProjUrl = hasPrevData ? getLinkToProject(orig as ProjectDataModel) : undefined;
    return isProjectSaved ? savedProjectUrl : origProjUrl;
}

export const parseDate = (str:string):Date => {
    //Fix for safari - https://stackoverflow.com/questions/16616950/date-function-returning-invalid-date-in-safari-and-firefox
    str = str.replace(' ','T')
    return new Date(str.replace(/-g/, "/"));
}

export const getLinkToFeedback = (reason:SupportModalReason)=>`/support?showModal=1&reason=${reason}`;

//https://stackoverflow.com/a/12067046
export const checkIfElementsOverlap = (a:HTMLElement, b:HTMLElement, allowedOverlap:{left?:number,right?:number,top?:number,bottom?:number}={}) => {
    const rect1 = a.getBoundingClientRect(), rect2 = b.getBoundingClientRect();
    // console.log(rect1.right, rect1.left, rect1.bottom, rect1.top);
    // console.log(rect2.right, rect2.left, rect2.bottom, rect2.top);
    let {left:offL, right:offR, top:offT, bottom:offB} = allowedOverlap;
    offL = (offL||0),
    offR = (offR||0),
    offT = (offT||0),
    offB = (offB||0);
    return !(Math.max(0, rect1.right - offR) < rect2.left || 
        Math.max(0, rect1.left - offL) > rect2.right || 
        Math.max(0, rect1.bottom - offB) < rect2.top || 
        Math.max(0, rect1.top - offT) > rect2.bottom);
};

//https://stackoverflow.com/a/45337588 does safe decimal math, but it found no use in this instance