import Vue from 'vue'
import Vuex from 'vuex'
import {SET_CHANNELS,SET_PRODUCT, SET_REFERENCER, SET_AUTH, PURGE_AUTH, SET_PROJECT, AFTER_ROUTE, SET_COST_ERRORS, SET_LOADING_PROJECT, BEFORE_ROUTE} from './mutations'
import {LOAD_PROJECT,SAVE_PROJECT, LOGIN, LOGOUT, CHECK_AUTH, REGISTER, LOAD_TOOLTIPS} from './actions'
import { ChannelModel,LoadProjectDTO,ProductModel, ProjectDataModel, ProjectModel } from '../common/classes/models'
//FIXME: Circular import, ?reason for tests not working
import { Referencer } from '@/common/classes/referencer'
import ApiService from '@/common/api.service'
import { endpoints, tooltipsText } from '@/common/config'
import { dateDiffInDays, jsonCycler } from '@/common/misc'
import { Expense } from '@/common/classes/expenses'
import jwtService from '@/common/jwt.service'
import unitStore from './unit-store'
import UnitManager, {defaultUnits, defExpTypes} from '@/common/classes/unit.manager';
import { plainToClass } from 'class-transformer'
import { Unit } from '@/common/classes/unit'
import navStore from './nav-store'
import StorageService from '@/common/storage.service'
import { Route } from 'vue-router'
import { saveLastLocalProject } from '@/common/local.project.service'
import taxUtils, { getProductTaxesArr, getProductTaxesMap } from '@/common/classes/tax.utils'
import { ProductTax } from '@/common/types/product.types'
import { StorageTooltipData, Tooltip } from '@/common/tooltips'

Vue.use(Vuex)

type RenderData = {
  productChartMonthRange: number;
  disableMobileStickyHeaderOnOffset: boolean;
  addMobileHeaderPaddingToContent: boolean;
};

export {RenderData};

export default new Vuex.Store({
  state: {
    ...navStore.state,
    ...unitStore.state,
    user: {},
    product: new ProductModel(),
    channels: [new ChannelModel()],
    projectData: {} as ProjectDataModel,
    referencer: {} as Referencer,
    renderData: {productChartMonthRange: 12, disableMobileStickyHeaderOnOffset: false} as RenderData,
    isAuthenticated: !!jwtService.getToken(),
    isAuthChecked: false,
    isTesting: false,
    costErrors: [] as any[],
    isLoadingProject: false,
    restoreLastLocalProject: false,
    tooltips: Object.assign({}, tooltipsText) as Record<string,string>,
    tooltipsAreOverridden: false,
  },
  //TODO: Validate
  mutations: {
    ...navStore.mutations,
    ...unitStore.mutations,
    [SET_CHANNELS](state,channels:ChannelModel[]){
      state.channels = channels
    },
    [SET_PRODUCT](state,product:ProductModel){
      state.product = product
      taxUtils.afterProductIsSet();
    },
    [SET_PROJECT](state,project:any){
      state.projectData=project;
    },
    [SET_REFERENCER](state,referencer:Referencer){
      state.referencer = referencer;
    },
    [SET_AUTH](state, authResponse){
      const {message,tokenDto} = authResponse;
      const {id_token,user} = tokenDto;
      state.user = user;
      state.isAuthenticated = true;
      jwtService.saveToken(id_token);
    },
    //TODO: Wait for window.beforeunload, return if refresh is rejected
    [PURGE_AUTH](state){
      location.reload();
      state.user = {};
      state.isAuthenticated = false;
      jwtService.destroyToken();
    },
    [BEFORE_ROUTE](state,data:{to:Route,from:Route}){
      if(data && data.from.path == '/create') saveLastLocalProject();
    },
    [AFTER_ROUTE](state,data:{to:Route,from:Route}){
      if(data){
        const {to,from} = data;
        let title = 'Izibiz';
        if(to && to.name) title += ' - '+to.name;
        document.title = title;
        if(from.path=='/create') state.restoreLastLocalProject = true;
        if(to.path!='/create') ProjectModel.loadDefaultProject();
      }else ProjectModel.loadDefaultProject();
      if(!state.isTesting) window.scroll({top:0,left:0});
      // window.onbeforeunload = null;
    },
    [SET_COST_ERRORS](state,costErrors){
      const predicateNameMessage = (costError:any,name:string,message:string):boolean=>costError.expense.name == name && costError.error+"" == message;
      const setArray:any[] = [];
      costErrors.forEach((ce:any)=>{
        const {expense,error} = ce;
        const matches = setArray.filter((setCE)=>predicateNameMessage(setCE,expense.name,error+""))
        if(matches.length == 0) setArray.push(ce);
      });
      state.costErrors = setArray;
    },
    [SET_LOADING_PROJECT](state,isLoadingProject:boolean){
      state.isLoadingProject = isLoadingProject;
    }
  },
  getters: {
    ...navStore.getters,
    ...unitStore.getters,
    product(state):ProductModel{return state.product},
    channels(state):ChannelModel[]{return state.channels},
    referencer(state):Referencer{return state.referencer},
    renderData(state):RenderData{return state.renderData},
    isAuthenticated(state):boolean{return state.isAuthenticated},
    user(state):any{return state.user},
    projectData(state):any{return state.projectData},
    costErrors(state):any{return state.costErrors},
    isAuthChecked(state):boolean{return state.isAuthChecked},
    isTesting(state):boolean{return state.isTesting},
    isLoadingProject(state):boolean{return state.isLoadingProject},
    restoreLastLocalProject(state):boolean{return state.restoreLastLocalProject},
    tooltips(state):Record<string,string>{return state.tooltips},
  },
  actions: {
    ...navStore.actions,
    ...unitStore.actions,
    async [SAVE_PROJECT](context,project:ProjectModel){
      const {data} = await ApiService.post(endpoints.saveProject,JSON.stringify(project,jsonCycler()));
      const proj:any = context.state.projectData;
      const {created,updated,author,id,name,imageLink,invitationCode} = data.project;
      Vue.set(proj,'id',id);
      Vue.set(proj,'created',created);
      Vue.set(proj,'updated',updated);
      Vue.set(proj,'author',author);
      Vue.set(proj,'name',name);
      Vue.set(proj,'imageLink',imageLink);
      Vue.set(proj,'invitationCode',invitationCode);
      context.commit(SET_PROJECT,proj);
    },
    async [LOAD_PROJECT](context,loadDto:LoadProjectDTO):Promise<ProjectModel>{
      const {id,invitationCode,project:dtoProjectLiteral, tab, chart} = loadDto;
      let projectLiteral = dtoProjectLiteral as ProjectModel;
      if(loadDto.isEmpty()) throw new Error('Unable to load the project, both id and invitation code was not specified.');
      let data;
      if(id!=undefined) data = (await ApiService.get(endpoints.getProject(id))).data;
      else if(invitationCode != undefined) data = (await ApiService.get(endpoints.getProjectWithInvitationCode(invitationCode))).data;
      
      if(data) projectLiteral = data.project;
      // console.log({projectLiteral,dtoProjectLiteral,fetchProjectLiteral:data?data.project:undefined})
      const projectDataClone:ProjectDataModel = Object.assign(new ProjectDataModel(),projectLiteral);
      //Set load query obj 
      if(tab != undefined || chart != undefined)
        projectDataClone.loadQuery = {tab, chart};

      const curUser = context.getters.user;

      //#region save projectData.orig
      const origProjectDataClone = Object.assign(new ProjectDataModel(),projectDataClone);
      delete origProjectDataClone.product;
      delete origProjectDataClone.channels;
      StorageService.saveLastLoadProjectData({data:origProjectDataClone} as ProjectModel);
      const prevAuthor = origProjectDataClone.author;
      if(curUser != null && prevAuthor && prevAuthor.id && prevAuthor.id != curUser.id){
        projectDataClone.prevAuthorId = prevAuthor.id;
      }
      projectDataClone.orig = Object.assign(new ProjectDataModel(),origProjectDataClone);
      //#endregion

      //#region delete ids if user did not create the project
      if(!!projectDataClone && !!projectDataClone.author && curUser.id != projectDataClone.author.id){
        ProjectModel.deleteIds( projectDataClone, projectDataClone.channels );
        const projName = projectDataClone.name;
        if(projName) projectDataClone.name = `${projName}` + (curUser.username ?` (${curUser.username})`:'');
      }
      //#endregion
      delete projectDataClone.product;
      delete projectDataClone.channels;
      context.commit(SET_PROJECT,projectDataClone);

      const cb:(()=>void)[] = [];
      projectLiteral.product = plainToClass(ProductModel,projectLiteral.product);
      projectLiteral.product.expenses = projectLiteral.product.expenses.map((se:any) => Expense.from(se,cb) );
      
      const taxLiteral = projectLiteral.product.tax as Record<string,any>;
      if(taxLiteral) {
        const tax = plainToClass(ProductTax, taxLiteral);
        const taxName = getProductTaxesArr().find(({type})=>type==tax.type)?.name;
        if(taxName) tax.name = taxName;
        projectLiteral.product.tax = tax;
      }

      context.commit(SET_REFERENCER,new Referencer());

      //eslint-disable-next-line prefer-const
      let {product,channels} = projectLiteral;
      channels = plainToClass(ChannelModel,channels);
      //Assign channel flags
      channels.forEach((c)=>c.initialize());
      product.initialize();
      Vue.set( product, 'totalTargetMonthlySales', channels.reduce((acc,{targetMonthlySales})=>acc+targetMonthlySales,0));
      await Vue.nextTick();

      UnitManager.clearUnits();
      UnitManager.addUnit( defaultUnits.filter(({text})=>text=='мес') );
      UnitManager.addUnit( product.expenseUnits.map((u) => new Unit(u,defExpTypes) ) );
      this.commit(SET_PRODUCT,product);
      this.commit(SET_CHANNELS,channels);
      channels.forEach((c)=>c.assignFraction());

      await Vue.nextTick();

      cb.forEach((c)=>c());
      return projectLiteral;
    },
    async [LOGIN](context, loginForm){
      let response;
      try{
        response = await ApiService.post("api/login",loginForm);
        context.commit(SET_AUTH,response.data);
        ApiService.setHeader();
        context.commit(AFTER_ROUTE);
      }catch(error){
        console.error({response,error,msg:'Error occurred during login'});
        throw error;
      }
    },
    [LOGOUT](context){
      context.commit(PURGE_AUTH);
      context.commit(AFTER_ROUTE);
    },
    async [CHECK_AUTH](context){
      const onAuthChecked = () => Vue.set(context.state,'isAuthChecked', true);
      try {
        const token = jwtService.getToken();
        //Added isAuthChecked to if, see if causes bugs
        if(token && token != 'undefined' && !context.state.isAuthChecked){
          ApiService.setHeader();
          let response;
          try{
            response = await ApiService.get("api/user");
            context.commit(SET_AUTH,response.data);
          }catch(error){
            // alert('Catched error. '+processResponseError(error)+'\n response status: '+response?.status)
            console.error({response,error,msg:'Error occurred during auth check'}); 
            context.commit(PURGE_AUTH);
          }
        }
      } catch (error) {
        onAuthChecked();
        throw error;
      }
      onAuthChecked();
    },
    async [LOAD_TOOLTIPS](context){
      if(context.state.tooltipsAreOverridden) return;

      const _overrideTooltips = (overrideTooltips:Tooltip[]) => {
        overrideTooltips.forEach((t)=>Vue.set( context.state.tooltips, t.name, t.value) );
        context.state.tooltipsAreOverridden = true;
      };
    
      const storageItem = StorageService.getStorageItems().TOOLTIPS_DATA;
      let data = storageItem.getData();
      if(data != null) {
          data = StorageTooltipData.fromJson(data);
          if(dateDiffInDays( data.fetched, new Date() ) < 1 ) {
              _overrideTooltips(data.tooltips);
              return;
          }
      }
      context.state.tooltipsAreOverridden = true;
      const {tooltips} = (await ApiService.get(endpoints.getTooltips)).data;
      storageItem.setData(new StorageTooltipData(tooltips, new Date()));
      _overrideTooltips(tooltips);
    },
    async [REGISTER](context, registerForm){
      return await ApiService.post("api/register",registerForm);
    }
  }
})
