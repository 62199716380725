import store from '@/store';
import { Expense, RefExpense } from './expenses';
// import { ChartData, ChartOptions } from 'chart.js';
import { ChannelModel, ChannelStageModel, ProductModel } from "./models";
import { numberWithSpaces, smartToFixed } from '@/common/misc';
import channelManager from './channel.manager';
import TaxUtils from './tax.utils';

class Budget{
    getProduct():ProductModel{
        return store.getters.product;
    }
    getChannels():ChannelModel[]{
        return store.getters.channels;
    }
    allPaidStages():{channel:ChannelModel,stage:ChannelStageModel}[]{
        const ret = [] as {channel:ChannelModel,stage:ChannelStageModel}[];
        this.getChannels().forEach((channel)=> channel.getPaidStages().forEach((stage)=>ret.push({channel,stage})) );
        return ret;
    }
    allPaidChannels():ChannelModel[]{
        return this.getChannels().filter((c)=>c.singleSaleCost() > 0);
    }
    getProfitForMonth(month:number,allSales=this.getAllSalesForMonth(month)):number{
        return this.getEarningsForMonth(month,allSales) - this.getExpensesForMonth(month,allSales);
    }
    countBudgetVal(fun:(mi:number)=>number,colI:number,inYears:boolean,debug=false):number{
        colI--;
        if(debug) console.log({debug,fun,colI,inYears});
        if(inYears){
            const arr = Array(12).fill(0);
            const mapped = arr.map((v,i)=>{
                const funIndex = i+(colI*12);
                const result = fun(funIndex);
                if(debug) console.log({funIndex,result})
                return result;
            });
            const reduced = mapped.reduce((acc,prev)=>acc+prev);
            if(debug) console.log({result:reduced});
            return reduced;
        }else return fun(colI);
    }
    getExpensesForMonth(month:number,allSales=this.getAllSalesForMonth(month)):number{
        return this.getNoTaxExpensesForMonth(month, allSales) + this.getTaxCostForMonth(month, allSales);
    }
    getNoTaxExpensesForMonth(month:number,allSales=this.getAllSalesForMonth(month)):number{
        const expenses = [
            ...this.getUnitExpenses(),
            ...this.getConstExpenses(),
            ...this.getOnetimeExpenses()
        ];
        const expenseCosts = this.reduceExpensesCost(expenses, month, allSales);

        const channelCosts = this.getMarketingExpensesForMonth(month);

        return expenseCosts + channelCosts;
    }
    getMonthlyExpenses(months:number, allSales=this.getAllMonthlySales(months)):number[]{
        const expenses = [];
        for (let month = 0; month < months; month++) 
            expenses.push(this.getExpensesForMonth(month,allSales[month]));
        return expenses;        
    }
    //#region count channel expenses
    //TODO: Refactor, see export table
    countStageCost(month:number, stage:ChannelStageModel, channel:ChannelModel):number{
        if(month < 0) return -1;
        if(month < stage.activationMonth || month < channel.activationMonth ) return 0;
        const multiplier = ( this.getTotalClientsForMonth(month) / channelManager.getTotalTargetMonthlySales() ) || 0;
        return multiplier * channel.calcStageCost(stage);
    }
    getMarketingExpensesForMonth(month:number):number{
        return this.getChannels().reduce((acc,cur)=>this.channelMarketingExpenseForMonth(month,cur)+acc,0);
    }
    channelMarketingExpenseForMonth(month:number,channel:ChannelModel):number{
        return channel.getPaidStages().reduce((acc,stage)=>this.countStageCost(month,stage,channel)+acc,0);
    }
    //#endregion
    //#region taxes
    getMonthlyTaxCost(months:number, allMonthlySales = this.getAllMonthlySales(months)):number[]{
        return [...Array(months)].map((_,month)=>this.getTaxCostForMonth(month, allMonthlySales[month]));
    }
    getTaxCostForMonth(month:number, allSales=this.getAllSalesForMonth(month)):number{
        if(month < TaxUtils.getCurrentTax().activationMonth) return 0;
        
        const useGroupsForCalculation = this.getProduct().clientsPerSalesUnit != 1;
        const earningsMultiplier = useGroupsForCalculation ? this.countGroupsForMonth(month, allSales) : allSales;
        //TODO: Make sure this is a right way to count tax earnings
        // const earnings = this.getEarningsForMonth(month, allSales);
        const earnings = this.getProduct().avgCheck * this.getProduct().clientsPerSalesUnit * earningsMultiplier;

        const profits = earnings - this.getNoTaxExpensesForMonth(month, allSales);
        const taxCost = TaxUtils.countTaxCostForExpenseType('any', earnings, profits);
        return taxCost;
    }
    //#endregion
    //#region count expense data
    getMonthlyExpenseCost(expense:Expense,months:number,monthlySales = this.getAllMonthlySales(months)):number[]{
        return [...Array(months)].map((_,month)=>this.countExpenseCost(expense,month,monthlySales[month]));
    }
    countGroupsForMonth(month:number, salesForMonth = this.getAllSalesForMonth(month)):number{
        return Math.ceil( salesForMonth / this.getProduct().clientsPerSalesUnit );
    }
    countExpenseCost(expense:Expense,month:number,salesForMonth = this.getAllSalesForMonth(month)):number{
        const parentExpenseIsInactive = expense.parentExpense != undefined && month < expense.parentExpense.getActivationMonth();
        if(month < expense.getActivationMonth() || parentExpenseIsInactive) return 0;
        
        if(expense.type == 2) {
            if(month != expense.getActivationMonth()) return 0;
            if(expense instanceof RefExpense) return expense.countCost(channelManager.getTotalSales());
        }
        
        let ret = expense.countCost(salesForMonth);
        if(expense.type == 0) {
            const useGroupsForCalculation = this.getProduct().clientsPerSalesUnit != 1;
            ret *= useGroupsForCalculation ? this.countGroupsForMonth(month, salesForMonth) : salesForMonth;
        }
        return ret;
    }
    //TODO: Replace with call to getMonthlyExpenseCost
    reduceExpensesCost(expenses:Expense[],month:number,salesForMonth = this.getAllSalesForMonth(month)):number{
        return expenses.reduce((acc,cur)=>acc+this.countExpenseCost(cur,month,salesForMonth),0);
    }
    //#endregion
    //#region getExpenses
    getUnitExpenses():Expense[]{
        return this.getProduct().getUnitExpenses();
    }
    getConstExpenses():Expense[]{
        return this.getProduct().getConstExpenses();
    }
    getOnetimeExpenses():Expense[]{
        return this.getProduct().getOnetimeExpenses();
    }
    //#endregion
    //#region chart data
    static chartTypes = Object.freeze( {moneyFlow:0, moneyFlowAccum:1, resourceInvestmentProfit:2} );
    getLineChartData(chartType:number,monthRange:number){
        switch(chartType){
            case Budget.chartTypes.moneyFlow: return this._getChartDataForMoneyFlow(monthRange);
            case Budget.chartTypes.moneyFlowAccum: return this._getChartDataForMoneyFlowAccum(monthRange);
            // case Budget.chartTypes.resourceInvestmentProfit: return this._getChartDataForResourceInvestmentProfit(monthRange);
            default: throw new Error("Unrecognized chartType "+chartType);
        }
    }
    static chartOptions:(x:string,y:string) => any = (x='',y='') => {
        return {
            animation: { duration: 0 },
            responsive: true,
            legend: false,
            tooltips: {
                mode: 'index',
                intersect: false,
            },
            hover: {
                mode: 'nearest',
                intersect: true
            },
            elements: {
                line: {
                    tension: 0
                }
            },
            scales: {
                xAxes: [{
                    offset: true,
                    display: true,
                    ticks: {
                        callback: function(value:string|number,index:number,values:number[]|string[]){
                            if(typeof value == 'string') return value;
                            return numberWithSpaces( value );
                        },
                    },
                    scaleLabel: {
                        display: true,
                        labelString: x
                    },
                    gridLines: {
                        color: 'rgba(0,0,0,0)'
                    },
                }],
                yAxes: [{
                    display: true,
                    ticks: {
                        callback: function(value:string|number,index:number,values:number[]|string[]){
                            if(typeof value == 'string') return value;
                            return numberWithSpaces( value );
                        },
                    },
                    scaleLabel: {
                        display: false,
                        labelString: y
                    }
                }]
            }
        } as any
    }
    basicDataObj(label:string,data:any[],labels:any[],backgroundColor:string,borderColor:string,xAxis='',yAxis=''):{options:any,data:any}{
        return {
            data: {
                datasets: [
                    {
                        label: label,
                        data,
                        fill: false,
                        backgroundColor,
                        borderColor,
                    }
                ],
                labels
            },
            options: Budget.chartOptions(xAxis,yAxis)
        }
    }
    _getChartDurationInMonths():number{
        return 12;
    }
    _getChartLongDurationInMonths():number{
        return 60;
    }
    _formChartDataArray(dataForMonth:(_:any[],i:number)=>any,months=this._getChartDurationInMonths()):any[]{
        const array:any = Array.from({length:months});
        for (let i = 0; i < array.length; i++) 
            array[i] = dataForMonth(array,i);
        return array;
    }
    _getChartDataForMoneyFlow(monthDuration=this._getChartDurationInMonths()):{options:any,data:any}{
        const profits = this.getMoneyFlow(monthDuration).map((v)=>smartToFixed(v));
        const labels = this._formChartDataArray((_,i)=>(i+1)+"",monthDuration);
        return this.basicDataObj('Денежный поток',profits,labels,'lightblue','lightblue','','Месяц');
    }
    _getChartDataForMoneyFlowAccum(monthDuration=this._getChartDurationInMonths()):{options:any,data:any} {
        const accProfits:any[] = this.getAccMoneyFlow(monthDuration).map((v)=>smartToFixed(v));
        const labels = this._formChartDataArray((_,i)=>(i+1)+"",monthDuration);
        return this.basicDataObj('Денежный поток накопительным итогом',accProfits,labels,'#90b6c3','#90b6c3','','Месяц');
    }

    //#endregion
    //#region computed data

    paybackPeriod():number{
        const accMoneyFlow = this.getAccMoneyFlow();
        if(accMoneyFlow[accMoneyFlow.length - 1] < 0) return -1;
        const period = accMoneyFlow.filter((mf)=>mf<0).length;
        return period + 1;
    }
    breakevenPoint(months=this._getChartLongDurationInMonths()):number{
        const moneyFlow = this.getMoneyFlow(months);
        const index = moneyFlow.findIndex((mf:number)=>mf > 0);
        if(index == -1) return -1;
        return index+1;
    }
    investmentVolume(months=this._getChartLongDurationInMonths()):number{
        const accMoneyFlow = this.getAccMoneyFlow(months);
        const min = Math.min(...accMoneyFlow);
        if( accMoneyFlow[accMoneyFlow.length-1] == min ) return -1;
        return Math.abs( min );
    }
    residualValue():number{
        const months = this._getChartLongDurationInMonths();
        return this.getProfitForMonth(months) / this.calcMonthlyDiscountingRate();
    }
    residualDiscountedValue():number{
        const months = this._getChartLongDurationInMonths();
        const discCoefficient = this._discountingCoefficientForMonth(months);
        return this.residualValue() * discCoefficient;
    }
    monthsTillTargetSales():number{
        return this.getProduct().monthsTillTargetSales;
    }
    getAccMoneyFlow(months = this._getChartLongDurationInMonths(),monthOffset=0):number[]{
        if(monthOffset<0) throw new Error("Month offset can't be negative");
        const monthProfits = this.getMoneyFlow(months+monthOffset).slice(monthOffset);
        const accProfits = [] as number[];
        for (let month = 0; month < months; month++) {
            const curProfit = monthProfits[month];
            const prev = accProfits[month-1]||0;
            accProfits.push(prev + curProfit);
        }
        return accProfits;
    }
    _countDaysToMonthRange(month:number):{from:number,to:number}{
        if(month<0) console.trace(`Month range can't be less than zero (${month})`);
        return {from:Math.max(0,month*30),to:(month+1)*30};
    }
    
    _convertDailyToOneMonth(data:number[]):number{
        if(data.length<30) throw new Error("Unable to convert daily to month data, array is too short");
        return data.slice(data.length-30).reduce((acc,cur)=>acc+cur,0);
    }

    getEarningsForMonth(month:number,
            allSales=this.getAllSalesForMonth(month)):number{
        return allSales * this.getProduct().avgCheck;
    }
    getMonthlyEarnings(months=61,allSales=this.getAllMonthlySales(months)):number[]{
        return [...Array(months)].map((_,month,arr)=>this.getEarningsForMonth(month,allSales[month]));
    }

    getNewSalesForMonth(month:number, retention=this.getCalcRetention(), clientReturnsPerMonth=this.getClientReturnsPerMonth(), monthlyTotalClients = this.getMonthlyTotalClients()):number{
        let monthSales = 0;
        const salesArr = [];
        for (let contriubution = 0; contriubution < month+1; contriubution++) {
            const sales = this.getNewSalesForMonthContribution(month, contriubution, retention, clientReturnsPerMonth, monthlyTotalClients);
            salesArr.push(sales);
            monthSales += sales;
        }
        return monthSales;
    }
    getReturnSaleForContribution(month:number, contribution:number, prevMonthReturns:number, retention=this.getCalcRetention(), clientReturnsPerMonth=this.getClientReturnsPerMonth(), monthlyTotalClients = this.getMonthlyTotalClients()):number{
        const salesArr = [...Array(month+1)].map((_,sMonth)=>{
            const sales = this.getNewSalesForMonthContribution(sMonth, contribution, retention, clientReturnsPerMonth, monthlyTotalClients);
            return sales;
        })
        // const sales = this.getNewSalesForMonthContribution(month, contriubution, retention, clientReturnsPerMonth, monthlyTotalClients);
        
        const salesSum = Math.max(0,salesArr.reduce((acc,cur)=>acc+cur));
        const retSales = salesSum - prevMonthReturns;
        // if(month == 8 && contribution > 6) console.log({month,contribution,prevMonthReturns,retSales,salesArr:salesArr.join(','),salesSum})

        return retSales;
    }

    //TODO: Refactor & improve performance (Fill 2D array w/ return values, check line 333)
    getMonthlyReturnSales(months:number, retention=this.getCalcRetention(), clientReturnsPerMonth=this.getClientReturnsPerMonth(), monthlyTotalClients = this.getMonthlyTotalClients()):number[]{
        
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        /*
        [
            c1
            [m1,m2],
            c2
            [m1,m2],
            c3
            [m1,m2]
        ]
        */
        const returnMonthContributions:number[][] = [...Array(months)].map(()=>[]);
        for (let contribution = 0; contribution < months; contribution++) {
            const contributionArr = returnMonthContributions[contribution];
            for (let month = 0; month < months; month++) {
                const prevMonthsSum = contributionArr.reduce((acc,cur)=>acc+cur,0);
                contributionArr.push( self.getReturnSaleForContribution(month,contribution,prevMonthsSum,retention,clientReturnsPerMonth,monthlyTotalClients) );
            }
        }
        function getReturnSalesForMonth(month:number):number{
            let retMonthSales = 0;
            // const retSalesArr:number[] = [];
            for (let contribution = 0; contribution < month+1; contribution++) {
                const sliced = returnMonthContributions[contribution].slice(undefined,month);
                const prevReturns = sliced.reduce((acc,cur)=>acc+cur,0);
                const returnSales = self.getReturnSaleForContribution(month, contribution, prevReturns, retention, clientReturnsPerMonth, monthlyTotalClients);
                retMonthSales += returnSales;
            }
            return retMonthSales;
        }
        
        return [...Array(months)].map((_,month)=>getReturnSalesForMonth(month));
    }

    getAllMonthlySales(months:number):number[]{
        const monthlyReturningClients = this.getMonthlyReturningClients(months);
        return [...Array(months)].map((_,month)=> this.getAllSalesForMonth(month,monthlyReturningClients[month]) );
    }
    getAllSalesForMonth(month:number, returningSales = this.getAdjustedReturningClientsForMonth(month)):number{
        const newSales = this.getTotalClientsForMonth(month);
        return newSales + returningSales;
    }
    getMonthlyNewSales(months=61):number[]{
        const retention = this.getCalcRetention();
        const clientReturnsPerMonth = this.getClientReturnsPerMonth(); 
        const monthlyTotalClients = this.getMonthlyTotalClients();
        return [...Array(months)].map((_,month)=>this.getNewSalesForMonth(month, retention, clientReturnsPerMonth, monthlyTotalClients));
    }
    getAdjustedReturningClientsForMonth(month:number):number{
        return this.getMonthlyReturningClients(month+1)[month];
    }
    getMonthlyReturningClients(months:number):number[]{
        const clientReturnsPerMonth=this.getClientReturnsPerMonth(), monthlyTotalClients = this.getMonthlyTotalClients()
        const returningClients = this.getMonthlyReturnSales(months,undefined,clientReturnsPerMonth,monthlyTotalClients);
        const arr = returningClients;
        return arr;
    }
    /**
     * @returns client retention, from 0 to 1
     */
    getCalcRetention():number{
        const { retention } = store.state.product;
        return retention == 0 ? 0 : retention / 100;
    }
    
    getChannelsTargetMonthlySales(channels=store.state.channels):number[] {
        return channels.map(({targetMonthlySales})=>targetMonthlySales);
    }
    //#region total clients
    /**
     * @param channelsTargetMonthlySales array with targetMonthlySales of all channels
     * @returns total target clients for one month
     */
    getTotalClientsForMonth(month:number, channels=store.state.channels as {targetMonthlySales:number, activationMonth:number}[]):number {
        const channelsTargetMonthlySales = channels.map(({targetMonthlySales, activationMonth})=>{
            if(month < activationMonth) return 0;
            let seasonalityMultiplier = 1;
            if(month > 0){
                const seasonMonth = (month - 1) % 12;
                const calcSeasonality = this.getSeasonalityCorrArr()[ seasonMonth ];
                // console.log({seasonMonth, month});
                seasonalityMultiplier = calcSeasonality / 100;
            }
            return targetMonthlySales * seasonalityMultiplier;
        });
        const monthsTillTargetSales = store.state.product.monthsTillTargetSales;
        return channelsTargetMonthlySales.reduce((acc,cur)=>acc+this.getChannelClientsForMonth(month, monthsTillTargetSales, cur),0)
    }
    /**
     * @param channelsTargetMonthlySales array with targetMonthlySales of all channels
     * @returns total target clients for several months
     */
    getMonthlyTotalClients(months=61):number[] {
        return [...Array(months)].map((_,month) => this.getTotalClientsForMonth(month) );
    }
    //#endregion
    //#region channel clients
    /**
     * @returns channel target clients for one month
     */
    getChannelClientsForMonth(month:number, monthsTillTargetSales = store.state.product.monthsTillTargetSales, channelTargetMonthlySales:number):number {
        const salesMultiplier = month >= monthsTillTargetSales ? 1 : month / monthsTillTargetSales;
        // console.log({month,totalTargetMonthlySales,monthsTillTargetSales, result: totalTargetMonthlySales * salesMultiplier})
        return channelTargetMonthlySales * salesMultiplier;
    }
    /**
     * @returns channel target clients for several months
     */
    // getMonthlyChannelClients(months=61, monthsTillTargetSales = store.state.product.monthsTillTargetSales, channelTargetMonthlySales:number):number[] {
    //     return [...Array(months)].map((_,month) => this.getChannelClientsForMonth(month, monthsTillTargetSales, channelTargetMonthlySales) );
    // }
    //#endregion
    procSeasonVal(v:number):number{
        // return smartToFixed(v, 2, Infinity);
        // return Math.round(v);
        return v;
    }
    getSeasonalityArr():number[]{
        return store.state.product.seasonality;
    }
    getSeasonalityCorrArr(seasonalityArr=this.getSeasonalityArr(), seasonalityPercentage = this.getSeasonalityPercentage()):number[]{
        return seasonalityArr
                        .map((seasonality)=> seasonality / seasonalityPercentage * 100 );
    }
    getSeasonalityPercentage(seasonalityArr=this.getSeasonalityArr()):number{
        return seasonalityArr
                        .reduce((acc,cur)=>acc+cur, 0) / 12;
    }
    getSeasonalityCorrPercentage(seasonalityCorrArr=this.getSeasonalityCorrArr()):number{
        return seasonalityCorrArr
                        .reduce((acc,cur)=>acc+cur, 0) / 12;
    }
    getClientReturnTime():number {
        return store.state.product.clientReturnTime;
    }
    getClientReturnsPerMonth():number{
        return 30 / this.getClientReturnTime();
    }
    getNewSalesForMonthContribution(
        month:number, 
        contribution:number, 
        retention = this.getCalcRetention(), 
        clientReturnsPerMonth = this.getClientReturnsPerMonth(),
        monthlyTotalClients = this.getMonthlyTotalClients()):number{
            // console.log({month,contribution,retention,clientReturnsPerMonth, monthlyTotalClients});

            if(month < contribution) return 0;
            
            //OFFSET($F$26;0;$E28)
            const monthlyClientsForContribution = monthlyTotalClients[contribution];
            
            //(30/$E$18*(H$21-$E28)))
            const clientReturnsForContribution = clientReturnsPerMonth * (month - contribution);
            //(($E$19/100)^(30/$E$18*(H$21-$E28)))
            const retentionPowClientReturnsForContribution = Math.pow( retention, clientReturnsForContribution );

            //(1-($E$19/100)^(30/$E$18))
            const oneMinRetentionPowClientMonthlyReturns = 1 - Math.pow( retention, clientReturnsPerMonth );

            const oneMinRetention = 1 - retention;

            // console.log({monthlyClientsForContribution, retentionPowClientReturnsForContribution, oneMinRetentionPowClientMonthlyReturns, oneMinRetention});

            const monthEqualContributionOffset = month == contribution ? -1 * monthlyClientsForContribution : 0;

            return monthEqualContributionOffset + monthlyClientsForContribution * retentionPowClientReturnsForContribution * oneMinRetentionPowClientMonthlyReturns / oneMinRetention;
    }

    getMoneyFlow(months = this._getChartLongDurationInMonths(), allSales = this.getAllMonthlySales(months)):number[]{
        return [...Array(months)].map((_,month)=>this.getProfitForMonth(month,allSales[month]));
    }
    discountedMoneyFlows(months=this._getChartLongDurationInMonths()):number[]{
        const moneyFlows = this.getMoneyFlow(months);
        const discountingCoefficients = this.discountingCoefficients(months);
        const discountedMoneyFlows = this._formChartDataArray((_,month)=>discountingCoefficients[month]*moneyFlows[month],months);
        return discountedMoneyFlows;
    }
    discountedAccMoneyFlows(months=this._getChartLongDurationInMonths()):number[]{
        const discMoneyFlows = this.discountedMoneyFlows(months);
        const discAccMoneyFlows = [];
        let prev=0;
        for (let month = 0; month < months; month++) {
            const cur = discMoneyFlows[month];
            const result = prev+cur;
            discAccMoneyFlows.push( result );
            prev = result;
        }
        return discAccMoneyFlows;
    }
    //TODO: Make sure this is the right way of doing this
    npv(months=this._getChartLongDurationInMonths()):number{
        const moneyFlow = this.getMoneyFlow(months+1);
        const lastMoneyFlow = moneyFlow[moneyFlow.length-1];
        return lastMoneyFlow + this.residualDiscountedValue();
    }
    // npv(months=this._getChartLongDurationInMonths()):number{
    //     const discAccMoneyFlow = this.discountedAccMoneyFlows(months);
    //     const lastDiscAccMoneyFlow = discAccMoneyFlow[discAccMoneyFlow.length-1];
    //     const residualDiscVal = this.residualDiscountedValue();
    //     return lastDiscAccMoneyFlow + residualDiscVal;
    // }
    irr(months=this._getChartLongDurationInMonths()):number{
        const moneyFlow = this.getMoneyFlow(months);
        let lastMoneyFlow = moneyFlow[moneyFlow.length - 1];
        let { discountingRate } = store.state.product;
        discountingRate = discountingRate / 100 + 1;
        const powed = Math.pow( discountingRate, 12 );
        // console.log({discountingRate,powed: powed})
        lastMoneyFlow /= powed - 1;
        moneyFlow.push( lastMoneyFlow );
        // console.log(`IRR money flow: ${moneyFlow.map((v)=>v.toFixed(4))}`);

        const countIRR = (_moneyFlow:number[],guess=0) => {
            let min = 0.0;
            let max = 1.0;
            let NPV = 0;
            if( !_moneyFlow.find((m)=>m > 0) || !_moneyFlow.find((m)=>m < 0) ){ return -1; }
            const iterMax = 200;
            let iter = 0;
            // let logCSV = 'min,max,guess,npv,iter\n';
            do {
                guess = (min + max) / 2;
                NPV = 0;
                for (let j=0; j<_moneyFlow.length; j++) {
                    NPV += _moneyFlow[j]/Math.pow((1+guess),j);
                }
                if (NPV > 0) min = guess;
                else max = guess;
                // logCSV+=`${min},${max},${guess},${NPV},${iter}\n`;
            } while(Math.abs(NPV) > 0.000001 && (iterMax > ++iter));
            // console.log(logCSV);
            if(iterMax <= iter) return -1;
            
            return guess * 100;
        }

        return countIRR(moneyFlow);
    }
    irrYear(months=this._getChartLongDurationInMonths()):number{
        let irr = this.irr(months);
        
        //To percentage
        irr /= 100;

        irr = Math.pow( irr + 1, 12 ) - 1;
        return irr * 100;
    }
    // irrYear(months=this._getChartLongDurationInMonths()):number{
    //     const irr = this.irr(months);
    //     return (Math.pow(irr/100+1,12)-1)*100;
    // }
    discountingCoefficients(months = this._getChartLongDurationInMonths()):number[]{
        const discountingRate = this.calcMonthlyDiscountingRate();
        const discountingCoefficients = this
            ._formChartDataArray((_,month)=>this._discountingCoefficientForMonth(month,discountingRate),months+1)
            .slice(1);
        return discountingCoefficients;
    }
    _discountingCoefficientForMonth(month:number,discountingRate=this.calcMonthlyDiscountingRate()):number{
        return Math.pow( 1/(1+discountingRate), month - 1 );
    }
    /**
     * @return monthlyDiscountingRate (?-100);
     */
    monthlyDiscountingRate():number{
        // ( (1+yearly/100)^(1/12)-1 ) * 100
        return ( Math.pow( (1+this.getProduct().discountingRate/100), 1/12 ) - 1) * 100;
    }
    /**
     * @return monthlyDiscountingRate (?-1);
     */
    calcMonthlyDiscountingRate():number{
        return this.monthlyDiscountingRate()/100;
    }
    //#endregion
}
export {Budget};
export default new Budget();