
import { createClickAnchor } from '@/common/misc'
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'onlyAuthenticatedMixin',
    mounted(){
        if(!this.isAuthenticated) createClickAnchor('/');
    },
    watch: {
        isAuthenticated(newVal){
            if(!newVal) createClickAnchor('/');
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated'])
    }
})
